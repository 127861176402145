<template>
    <div class="layout gutter--xl">
        <div class="row justify--center mb-4">
            <h1 class="display-1">Change password</h1>
        </div>
        <va-form ref="change_password_form" @validation="formValid = $event" @submit.prevent="submitForm" v-if="!submitted">
            <div class="row justify--center">
                <div class="flex xs12 sm4">
                    <va-input
                        label="Password"
                        v-model="passwordInput"
                        :rules="passwordRules"
                        type="password"
                        style="width: 100%"
                        @blur="$refs.change_password_form.validate()"
                        @keyup="$refs.change_password_form.validate()"
                    />
                </div>
                <div class="flex xs12 sm4">
                    <va-input
                        label="Confirm password"
                        v-model="confirmPasswordInput"
                        :rules="[confirmPasswordRule, samePasswordRule]"
                        type="password"
                        style="width: 100%"
                        @blur="$refs.change_password_form.validate()"
                        @keyup="$refs.change_password_form.validate()"
                    />
                </div>
                <div class="flex">
                    <va-button type="submit" color="primary" :disabled="!formValid" @click="submitForm">Reset password</va-button>
                </div>
            </div>
        </va-form>
        <div class="row justify--center" v-else>
            <div class="flex va-typography">
                <p>Your password has been changed, you can now login in using the new password from now on.</p>
            </div>
        </div>
        <div class="row justify--center" v-if="error">
            <div class="flex xs12 sm6">
                <va-alert color="danger" title="Your password change request could not be processed! Please contact the administrator">{{error}}</va-alert>
            </div>
        </div>
    </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  import api from '@/lib/api'

  export default {
    name: 'ResetPassword',
    props: {
        hash: { type: String, default: undefined }
    },
    data: () => {
        return {
            formValid: false,
            submitted: false,
            error: undefined,
            passwordInput: '',
            confirmPasswordInput: '',
            passwordRules: [ v => v != "" || "Please enter your new password" ],
            confirmPasswordRule: v => v != "" || "Please confirm your new password"
        }
    },
    computed: {
        ...mapGetters('auth', ['isLoggedIn']),
        samePasswordRule () {
            return () => (this.passwordInput == this.confirmPasswordInput) || 'Passwords do not match!'
        }
    },
    methods: {
        async submitForm () {
            const validForm = this.$refs.change_password_form.validate()

            if (!validForm) {
                return
            }

            api.user.changePassword({
                password: this.passwordInput,
                confirm_password: this.confirmPasswordInput,
                password_reset_hash: this.hash
            }).then(() => {
                this.submitted = true
                this.error = undefined
            }).catch((error) => {
                this.submitted = false
                
                try {
                    this.error = error.response.data.msg
                } catch {
                    this.error = "Unknown error"
                }
            })
        }
    },
    async mounted() {
        if (!this.hash && !this.isLoggedIn) {
            this.$router.replace({name: '404'})
        }
    }
  }
</script>