<template>
  <div>
    <va-dropdown
      v-for="(route, idx) in filteredRoutes"
      :key="idx"
      v-model="dropdownsValue[idx]"
      placement="right-start"
      prevent-overflow
      :offset="[1, 0]"
      style="display: flex !important"
    >
      <template #anchor>
        <va-sidebar-item :active="isEntryActive(route)" :to="route.children ? undefined : { name: route.route, params: route.params }" v-if="userHasAccess(route)">
          <va-sidebar-item-content>
              <template v-if="route.badge">
                  <va-badge overlap dot>
                  <va-icon :name="route.icon" v-if="route.icon" class="va-sidebar-item__icon" />
                  </va-badge>
              </template>
              <template v-else>
                  <va-icon :name="route.icon" class="va-sidebar-item__icon" />
              </template>
            <va-icon
              v-if="route.children"
              class="more_icon"
              :name="dropdownsValue[idx] ? 'chevron_left' : 'chevron_right'"
            />
          </va-sidebar-item-content>
        </va-sidebar-item>
      </template>
      <div class="sidebar-item__children">
        <va-sidebar-item  v-for="(child, index) in filteredChildren(route)"  :key="index" :active="isEntryActive(child)" :to="{ name: child.route, params: child.params }" color="primary">
          <va-sidebar-item-content>
            <va-sidebar-item-title  color="primary">
              {{ child.title }}
            </va-sidebar-item-title>
          </va-sidebar-item-content>
        </va-sidebar-item>
      </div>
    </va-dropdown>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'

  export default {
    name: 'MenuMinimized',
    props: {
        routes: Array
    },
    data: () => {
        return {
            dropdownsValue: []
        }
    },
    computed: {
      ...mapGetters('auth', ['isLoggedIn', 'userinfo']),

      routeName() {
        return this.$route.name;
      },
      filteredRoutes () {
        return this.routes.filter(route => this.userHasAccess(route))
      }
    },
    methods: {
      userHasAnyPermission ( permissions ) {
        if (permissions.length === 0) {
          return true
        }

        if (!this.isLoggedIn) {
          return false
        }

        if (this.userinfo.permissions.indexOf('administrator') !== -1) {
          return true
        }

        return permissions.filter(rp => this.userinfo.permissions.indexOf(rp) !== -1).length > 0
      },
      userHasAccess ( menuEntry ) {
        let userHasAccessToChildren = false
        let userHasAccessToParent = false

        if ( menuEntry.children && menuEntry.children.length ) {
          userHasAccessToChildren = menuEntry.children.filter ( child => {
            return child.permissions ? this.userHasAnyPermission(child.permissions) : true
          }).length > 0
        }

        if ( menuEntry.permissions ) {
          userHasAccessToParent = this.userHasAnyPermission ( menuEntry.permissions )
        } else if (!menuEntry.children) {
          // This entry has no children and no permissions
          userHasAccessToParent = true
        }

        return (userHasAccessToParent || userHasAccessToChildren)
      },
      isEntryActive ( menuEntry ) {
        if ( !menuEntry.children || menuEntry.children < 1 ) {
          return ( this.$route.name === menuEntry.route );
        }

        return menuEntry.children.filter ( c => c.route === this.$route.name ).length > 0
      },
      filteredChildren (route) {
        if (!route) {
          return []
        }

        if (!route.children) {
          return []
        }

        return route.children.filter(child => this.userHasAccess(child))
      }
    },
    async mounted() {
      this.accordionValue = this.routes.map ( item => {
        return this.isEntryActive ( item )
      })
    }
  }
</script>

<style lang="scss">
  .sidebar-item {
    &__children {
      max-height: 60vh;
      overflow-y: auto;
      overflow-x: visible;
      width: 16rem;
      color: var(--va-primary);
      background: var(--va-white);
      box-shadow: var(--va-box-shadow);
    }
  }
  .va-sidebar-item {
    &-content {
      position: relative;
      .more_icon {
        text-align: center;
        position: absolute;
        bottom: 0.5rem;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }
    }
  }
</style>