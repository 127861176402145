<template>
    <va-form ref="contact_form">
        <div class="layout gutter--xl">
            <div class="row justify--center">
                <div class="flex">
                    <h1 class="display-1">Contact form</h1>
                </div>
            </div>
            <div class="row justify--start">
                <div class="flex xs12 sm6">
                    <va-input
                        type="email"
                        v-model="contactMessage.email"
                        label="Your email address"
                        :rules="[rules.mandatory, rules.email]"
                        :readonly="isLoggedIn"
                        style="width: 100%"
                    />
                </div>
            </div>
            <div class="row">
                <div class="flex xs12 sm6">
                    <va-input
                        type="text"
                        v-model="contactMessage.first_name"
                        label="First Name"
                        :rules="[rules.mandatory, rules.max32Chars]"
                        :readonly="isLoggedIn"
                        style="width: 100%"
                    />
                </div>
                <div class="flex xs12 sm6">
                    <va-input
                        type="text"
                        v-model="contactMessage.last_name"
                        label="Last Name"
                        :rules="[rules.mandatory, rules.max32Chars]"
                        :readonly="isLoggedIn"
                        style="width: 100%"
                    />
                </div>
            </div>
            <div class="row">
                <div class="flex xs12 sm6">
                    <va-input
                        type="text"
                        v-model="contactMessage.subject"
                        label="Subject"
                        :rules="[rules.mandatory, rules.max32Chars]"
                        style="width: 100%"
                    />
                </div>
            </div>
            <div class="row">
                <div class="flex xs12">
                    <va-input
                        style="width: 100%"
                        type="textarea"
                        autosize
                        :min-rows="5"
                        v-model="contactMessage.message"
                        label="Message"
                        :rules="[rules.mandatory]"
                    />
                </div>
            </div>
            <div class="row">
                <div class="flex">
                    <va-button icon="send" :disabled="messageSent" @click="sendMessage">Send Message</va-button>
                </div>
            </div>
        </div>
    </va-form>
</template>

<script>
import api from '@/lib/api'
import { mapGetters } from 'vuex'

export default {
    name: 'ContactForm',
    data: () => {
        return {
            contactMessage: {
                message: '',
                first_name: '',
                last_name: '',
                email: '',
                subject: ''
            },
            rules: {
                mandatory: v => !!v || "Required field",
                max32Chars: v => v.length <= 32 || "Maximum 32 characters",
                email: v => /^[^\s@]+@[^\s@]+$/.test(v) || 'Invalid E-Mail address'
            },
            messageSent: false
        }
    },
    computed: {
        ...mapGetters('auth', ['userinfo', 'isLoggedIn'])
    },
    methods: {
        sendMessage () {
            const formValid = this.$refs.contact_form.validate()

            if (!formValid) {
                return
            }
            
            api.other.sendContactMessage ( this.contactMessage ).then(() => {
                this.messageSent = true
            })
        }
    },
    mounted () {
        if (this.isLoggedIn) {
            this.contactMessage.first_name = this.userinfo.first_name
            this.contactMessage.last_name = this.userinfo.last_name
            this.contactMessage.email = this.userinfo.email
        }
    }
}
</script>