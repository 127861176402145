<template>
    <div class="layout gutter--xl">
        <div class="row">
            <div class="flex xs12" v-if="photometer">
                <span class="display-2">{{photometer.name}}</span>
            </div>
        </div>
        <template v-if="photometer">
            <div class="row">
                <div class="flex xs12 md6">
                    <div>
                        <va-image :src="picture" v-if="picture" height="200" contain/>
                    </div>
                </div>
                <div class="flex xs12 md6">
                    <p class="mb-2"><strong>Instrument name:</strong> {{photometer.name}}</p>
                    <p class="mb-2"><strong>Latitude:</strong> {{photometer.latitude}} °N</p>
                    <p class="mb-2"><strong>Longitude:</strong> {{photometer.longitude}} °E</p>
                    <p class="mb-2"><strong>Altitude:</strong> {{photometer.altitude}} m</p>
                    <p class="mb-2"><strong>Status:</strong> {{photometer.status.name}}</p>
                    <p class="mb-2" v-if="photometer.status.code==='operational'"><strong>Operational since:</strong> {{photometer.planned_operation_date}}</p>
                    <p class="mb-2" v-else><strong>Planned operation date:</strong> {{photometer.planned_operation_date}}</p>
                    <p class="mb-2"><strong>Type:</strong> {{photometer.type.name}}</p>
                    <p class="mb-2"><strong>Manufacturer:</strong> {{photometer.manufacturer}}</p>
                    <p class="mb-2"><strong>Instrument model:</strong> {{photometer.instrument_model}}</p>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import api from '@/lib/api'


export default {
    name: 'ViewPhotometer',
    components: {
    },
    props: {
        id: { type: String, default () {''} }
    },
    data: () => ({
        photometer: undefined,
        picture: undefined
    }),
    methods: {
        functionToColor (f) {
            if ( f.code === 'auto' || f.code === 'manual' ) {
                return 'success';
            }

            if ( f.code === 'planned' ) {
                return 'warning';
            }

            return 'danger';
        },
        featureToColor (f) {
            if ( f.code === 'existing' ) {
                return 'success';
            }

            if ( f.code === 'planned' ) {
                return 'warning';
            }

            return 'danger';
        },
        async loadData () {
            api.photometer.getPhotometerSystem(this.id).then((response) => {
                this.photometer = response.data.data

                if (this.photometer.pictures.length) {
                    this.picture = api.file.fileURL ( this.photometer.pictures[0] )
                } else {
                    this.picture = undefined
                }
            }).catch (() => {
                this.photometer = undefined
                this.picture = undefined
            })
        },
    },
    mounted () {
        this.loadData ()
    }
}
</script>