async function loadChannelCategories () {
    return this.api({
        url: `/lidar/channels/categories`,
        method: 'GET'
    })
}

async function getLidarSystem (id) {
    return this.api({
        url: `/lidar/system/${id}`,
        method: 'GET'
    })
}

async function editLidarSystem ( id, nfId, options ) {
    let lidarChannels = {}

    Object.keys(options.channels).forEach(channelName => {
        lidarChannels[ channelName ] = {
            ... options.channels[ channelName ],
            operation_start_date: Math.floor(new Date(options.channels[ channelName ].operation_start_date).getTime() / 1000) // API requires UNIX  timestamp
        }
    })

    const data = {
        ...options,
        nf_id: nfId,
        planned_operation_date: Math.floor(new Date(options.planned_operation_date).getTime() / 1000),

        status: this.objId(options.status),
        type: this.objId(options.type),
        location: this.objId(options.location),

        laser_alignment: this.objId(options.laser_alignment),
        polarisation_calibration: this.objId(options.polarisation_calibration),
        telecover_measurement: this.objId(options.telecover_measurement),
        dark_signal_measurement: this.objId(options.dark_signal_measurement),

        photodetector_eye_piece: this.objId(options.photodetector_eye_piece),
        pretrigger_20us: this.objId(options.pretrigger_20us),
        t_h_control: this.objId(options.t_h_control),
        lidar_status_control: this.objId(options.lidar_status_control),
        eye_safety: this.objId(options.eye_safety),
        unattended_operation: this.objId(options.unattended_operation),

        feasibility_compliance: this.objId(options.feasibility_compliance),
        feature_compliance: this.objId(options.feature_compliance),
        channel_compliance: this.objId(options.channel_compliance),
        qaqc_compliance: this.objId(options.qaqc_compliance),
        general_compliance: this.objId(options.general_compliance),
        general_compliance_reviewer: this.objId(options.general_compliance_reviewer),

        channels: lidarChannels
    }

    return this.api({
        url: `/lidar/system/edit/${id}`,
        method: 'POST',
        data: data
    })
}

async function loadSuggestedFeasibilityCompliance (id) {
    return this.api({
        url: `/lidar/system/${id}/suggested-feasibility-compliance`,
        method: 'GET'
    })
}

async function loadSuggestedFeatureCompliance (id) {
    return this.api({
        url: `/lidar/system/${id}/suggested-feature-compliance`,
        method: 'GET'
    })
}

async function loadSuggestedChannelCompliance (id) {
    return this.api({
        url: `/lidar/system/${id}/suggested-channel-compliance`,
        method: 'GET'
    })
}
 
 export default {
     pluginName: 'lidar',
     globals: {
        FEASIBILITY_COMPLIANCE: {
            OK: 0,
            NOT_OPEN_DESIGN: 1,
            NOT_PROPERLY_ENCLOSED: 1 << 1,
            NOT_OPERATIONAL_IN_1_YEAR: 1 << 2
        },
        FEATURE_COMPLIANCE: {
            OK: 0,
            FEATURE_NOT_OPERATIONAL: 1,
            FEATURE_NOT_PLANNED: 2
        },
        CHANNEL_GROUP_COMPLIANCE: {
            OK: 0,
            NO_CHANNELS: 1,
            HEIGHT_RESOLUTION: 2,
            TIME_RESOLUTION: 4,
            MAXIMUM_RANGE: 8
        },
        WAVELENGTH_COMPLIANCE: {
            OK: 0,
            NOT_ALL_CHANNELS_FULLY_COMPLIANT: 1,
            CHANNEL_NOT_COMPLIANT: 2,
            NO_POLARISATION: 4
        },
        CHANNEL_COMPLIANCE: {
            OK: 0,
            NO_COMPLIANT_WAVELENGTH: 1,
            NO_FULLY_COMPLIANT_WAVELENGTH: 2
        }
     },
     methods: {
        getLidarSystem: getLidarSystem,
        loadChannelCategories: loadChannelCategories,
        editLidarSystem: editLidarSystem,
        loadSuggestedFeasibilityCompliance: loadSuggestedFeasibilityCompliance,
        loadSuggestedFeatureCompliance: loadSuggestedFeatureCompliance,
        loadSuggestedChannelCompliance: loadSuggestedChannelCompliance
     },
     apiMessages: {
         0: 'Operation successful!',
         1: 'Information loaded successfully!',
         2: 'Information saved successfully!',
         1000: 'Unknown error!',
         1001: 'Permission denied!',
         1002: 'Could not load information!',
         1003: 'Could not save information!',
         1004: 'Could not register National Facility!'
     }
 }