<template>
    <va-inner-loading>
    <div class="layout gutter--xl pl-0 pr-0 va-typography-block">
        <div class="row">
            <div class="flex xs12">
                <va-card v-if="feasibilityCompliance" outlined class="pb-1">
                <va-alert :color="cardStripeColor(feasibilityCompliance.compliance.code)">
                    <span class="complianceTypeTitle">Feasibility compliance</span>
                    <span v-if="feasibilityCompliance.compliance.code === 'yes'">Fully compliant!</span>
                    <span v-else-if="feasibilityCompliance.compliance.code === 'partially'"> Partially compliant!</span>
                    <span v-else>Required criteria not met!</span>
                </va-alert>
                <va-card-content>
                    <ul class="va-unordered" v-if="feasibilityCompliance.code > 0">
                        <li :class="complianceListItemClass(feasibilityCompliance.compliance.code)" v-if="feasibilityCompliance.code & feasibilityCodes.NOT_OPEN_DESIGN">Lidar system might not have an open design</li>
                        <li :class="complianceListItemClass(feasibilityCompliance.compliance.code)" v-if="feasibilityCompliance.code & feasibilityCodes.NOT_PROPERLY_ENCLOSED">Lidar system might not have a properly enclosed design</li>
                        <li :class="complianceListItemClass(feasibilityCompliance.compliance.code)" v-if="feasibilityCompliance.code & feasibilityCodes.NOT_OPERATIONAL_IN_1_YEAR">Lidar system will not be operational in the next year</li>
                    </ul>
                </va-card-content>
                </va-card>
            </div>
            <div class="flex xs12">
                <va-card v-if="featureCompliance" outlined class="pb-1">
                    <va-alert :color="cardStripeColor(featureCompliance.compliance.code)">
                        <span class="complianceTypeTitle">Feature compliance</span>
                        <span v-if="featureCompliance.compliance.code === 'yes'">Fully compliant!</span>
                        <span v-else-if="featureCompliance.compliance.code === 'partially'">Partially compliant!</span>
                        <span v-else>Required criteria not met!</span>
                    </va-alert>
                    <va-card-content>
                        <ul class="va-unordered" v-if="featureCompliance.code > 0">
                            <li :class="complianceListItemClass(featureCompliance.compliance.code)" v-if="featureCompliance.code & featureCodes.FEATURE_NOT_OPERATIONAL">One or more required features are not operational</li>
                            <li :class="complianceListItemClass(featureCompliance.compliance.code)" v-if="featureCompliance.code & featureCodes.FEATURE_NOT_PLANNED">One or more required features will not be operational in the next year</li>
                        </ul>
                    </va-card-content>
                </va-card>
            </div>
            <div class="flex xs12">
                <va-card v-if="channelCompliance" outlined class="pb-1">
                    <va-alert :color="cardStripeColor(channelCompliance.compliance.code)">
                        <span class="complianceTypeTitle">Channel compliance</span>
                        <span v-if="channelCompliance.compliance.code === 'yes'">Fully compliant!</span>
                        <span v-else-if="channelCompliance.compliance.code === 'partially'">Partially compliant!</span>
                        <span v-else>Required criteria not met!</span>
                    </va-alert>
                    <va-card-content>
                        <p class="strong" v-if="channelCompliance.code & channelCodes.overall.NO_COMPLIANT_WAVELENGTH > 0">No wavelength fulfills the required channels criteria</p>
                        <p class="strong" v-if="channelCompliance.code & channelCodes.overall.NO_FULLY_COMPLIANT_WAVELENGTH">No wavelength has fully compliant channels</p>
                        <va-card v-for="wavelength in channelCompliance.wavelengths" outlined class="mb-2" stripe :stripe-color=cardStripeColor(wavelength.compliance.code) :key="wavelength.name" >
                            <va-card-title>
                                <span v-if="wavelength.compliance.code === 'yes'">{{wavelength.name}}nm channels are fully compliant</span>
                                <span v-else-if="wavelength.compliance.code === 'partially'">{{wavelength.name}}nm channels are partially compliant</span>
                                <span v-else>{{wavelength.name}}nm channels do not fulfill the required criteria</span>
                            </va-card-title>
                            <va-card-content>
                                <p v-if="wavelength.code & channelCodes.wavelength.NOT_ALL_CHANNELS_FULLY_COMPLIANT">One or more {{wavelength.name}}nm channels are not fully compliant:</p>
                                <p v-if="wavelength.code & channelCodes.wavelength.CHANNEL_NOT_COMPLIANT">One or more {{wavelength.name}}nm channels do not fulfill the required criteria:</p>
                                <p v-if="wavelength.code & channelCodes.wavelength.NO_POLARISATION">No depolarisation measurement capability at {{wavelength.name}}nm:</p>
                                <ul class="va-unordered" v-for="channel_group in wavelength.channel_groups" :key="channel_group.name">
                                    <li :class="complianceListItemClass(channel_group.compliance.code)">
                                        <span v-if="channel_group.compliance.code === 'yes'">{{channel_group.name}} channels are fully compliant</span>
                                        <span v-else-if="channel_group.compliance.code === 'partially'">{{channel_group.name}} channels are partially compliant:</span>
                                        <span v-else>{{channel_group.name}} channels do not fulfill the required criteria:</span>
                                        <ul class="va-unordered">
                                            <li :class="complianceListItemClass(channel_group.compliance.code)" v-if="channel_group.code & channelCodes.group.NO_CHANNELS">No {{channel_group.name}} channels present</li>
                                            <li :class="complianceListItemClass(channel_group.compliance.code)" v-if="channel_group.code & channelCodes.group.HEIGHT_RESOLUTION">Height resolution criteria is not satisfied for {{channel_group.name}} channels</li>
                                            <li :class="complianceListItemClass(channel_group.compliance.code)" v-if="channel_group.code & channelCodes.group.TIME_RESOLUTION">Time resolution criteria is not satisfied for {{channel_group.name}} channels</li>
                                            <li :class="complianceListItemClass(channel_group.compliance.code)" v-if="channel_group.code & channelCodes.group.MAXIMUM_RANGE">Minimum altitude range is too low for {{channel_group.name}} channels</li>
                                        </ul>
                                    </li>
                                </ul>
                            </va-card-content>
                        </va-card>
                    </va-card-content>
                </va-card>
            </div>
        </div>
    </div>
    </va-inner-loading>
</template>

<script>
import api from '@/lib/api'

export default {
    name: 'LidarSystemCompliancy',
    props: {
        lidarId: {
            type: String,
            default: ''
        }
    },
    data: () => ({
        feasibilityCompliance: undefined,
        feasibilityCodes: api.lidar.FEASIBILITY_COMPLIANCE,
        featureCompliance: undefined,
        featureCodes: api.lidar.FEATURE_COMPLIANCE,
        channelCompliance: undefined,
        channelCodes: {
            group: api.lidar.CHANNEL_GROUP_COMPLIANCE,
            wavelength: api.lidar.WAVELENGTH_COMPLIANCE,
            overall: api.lidar.CHANNEL_COMPLIANCE
        }
    }),
    methods: {
        async getCompliances() {
            Promise.all([
                api.lidar.loadSuggestedFeasibilityCompliance(this.lidarId),
                api.lidar.loadSuggestedFeatureCompliance(this.lidarId),
                api.lidar.loadSuggestedChannelCompliance(this.lidarId)
            ]).then((responses) => {
                // responses[0] = feasibilityCompliance
                // responses[1] = featureCompliance
                // responses[2] = channelCompliance

                this.feasibilityCompliance = responses[0].data.data
                this.featureCompliance = responses[1].data.data
                this.channelCompliance = responses[2].data.data
            })  
        },
        cardStripeColor(code) {
            if ( code === 'yes' ) {
                return 'success'
            }

            if ( code === 'partially' ) {
                return 'warning'
            }

            return 'danger'
        },
        complianceListItemClass(code) {
            if ( code === 'yes' ) {
                return 'compliance_list_item__green'
            }

            if ( code === 'partially' ) {
                return 'compliance_list_item__yellow'
            }

            return 'compliance_list_item__red'
        }
    },
    async mounted() {
        if (this.lidarId) {
            this.getCompliances()
        }
    }
}
</script>

<style>
.va-alert {
    margin-top: 0 !important;
}

.complianceTypeTitle {
    text-transform: uppercase;
    font-weight: bold;
    padding-right: 1.5rem;
}

.strong {
    font-weight: bold;
}

ul.va-unordered > li.compliance_list_item__green:before {
    background-color: var(--va-success);
}

ul.va-unordered > li.compliance_list_item__yellow:before {
    background-color: var(--va-warning);
}

ul.va-unordered > li.compliance_list_item__red:before {
    background-color: var(--va-danger);
}
</style>