<template>
    <div class="layout gutter--xl" v-if="nationalFacilities.length">
        <div class="row justify--center mb-4">
            <h1 class="display-1">Please select National Facility</h1>
        </div>
        <div class="row">
            <div class="flex xs12">
                <va-data-table
                    :items="nationalFacilities"
                    :columns="nationalFacilitiesTableColumns"
                    striped
                >
                    <template #cell(actions)="{rowData}">
                        <va-button icon="edit" @click="goEdit(rowData)">Edit</va-button>
                    </template>
                </va-data-table>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/lib/api'

export default {
    name: 'SelectPersonalNf',
    data: () => ({
        nationalFacilities: [],
        nationalFacilitiesTableColumns: [
            {key: 'actions', label: 'Actions', width: '10%'},
            {key: 'acronym', label: 'National Facility'}
        ]
    }),
    methods: {
        goEdit (nationalFacility) {
            this.$router.push({
                name: 'EditNF',
                params: {
                    personalNf: true,
                    id: nationalFacility.id
                }
            })
        }
    },
    mounted () {
        api.nf.loadUserNfs().then(response => {
            console.log (response.data.data)

            if (!response.data.data.exists) {
                console.log ("No national facility!")
                // The EditNF view will handle the missing facility
                this.$router.push({
                    name: 'EditNF',
                    params: {
                        personalNf: true
                    }
                })
            } else if (response.data.data.nfs.length === 1) {
                console.log ("One national facility!")
                // There is only one NFS this user owns
                // so we might as well just redirect him
                // to the required page.
                this.$router.push({
                    name: 'EditNF',
                    params: {
                        personalNf: true,
                        id: response.data.data.nfs[0].id
                    }
                })
            } else {
                console.log ("Multiple national facilities!")
                // This user owns multiple NFs.
                // We'll populate the national facility list
                // and let him pick the National Facility he
                // wants to edit
                this.nationalFacilities = response.data.data.nfs
            }
        }).catch(() => {
                // The EditNF view will handle the error.
                // ... I hope.
                this.$router.push({
                    name: 'EditNF',
                    params: {
                        personalNf: true
                    }
                })
        })
    }
}
</script>