<template>
    <div class="layout gutter--xl">
        <va-form ref="edit_lidar_system_form" v-if="lidar">
            <div class="row">
                <span class="display-6 xs12 flex">Lidar unique ID/Name</span>
                <div class="flex xs12">
                    <va-input
                        v-model="lidar.name"
                        label="Lidar unique ID/Name"
                        :disabled="disabled"
                        :readonly="readonly"
                        style="width: 100%"
                        :rules="[rules.mandatory]"
                    />
                </div>
            </div>
            <div class="row">
                <span class="display-6 xs12 flex">Instrument pictures</span>
                <CPFileUpload
                    v-model="lidarPictures"
                    file-types="jpg,jpeg,png"
                    type="gallery"
                    maxFiles=1
                    dropzone
                    @file-added="uploadPicture"
                    @file-removed="removePicture"
                    class="flex xs12 fileUploadGallery"
                    uploadButtonText = "Select files from your computer"
                    :dropZoneText="galleryDropzoneText"
                    :disabled="readonly"
                    requireConfirmation
                    confirmationMessage="Are you sure you want to delete this picture? This operation is irreversible!"
                    okText="Yes"
                    cancelText="Cancel"
                >
                </CPFileUpload>
            </div>
            <div class="row">
                <span class="display-6 xs12 flex">Instrument location</span>
                <div class="flex xs12 sm4">
                    <va-input
                        type="number"
                        :step="0.0001"
                        manual-input
                        v-model="lidar.latitude"
                        label="Latitude"
                        :rules="[rules.mandatory]"
                        :disabled="disabled"
                        :readonly="readonly"
                    >
                        <template #appendInner>
                            °N
                        </template>
                    </va-input>
                </div>
                <div class="flex xs12 sm4">
                    <va-input
                        type="number"
                        :step="0.0001"
                        manual-input
                        v-model="lidar.longitude"
                        label="Longitude"
                        :rules="[rules.mandatory]"
                        :disabled="disabled"
                        :readonly="readonly"
                    >
                        <template #appendInner>
                            °E
                        </template>
                    </va-input>
                </div>
                <div class="flex xs12 sm4">
                    <va-input
                        type="number"
                        :step="0.0001"
                        manual-input
                        v-model="lidar.altitude"
                        label="Altitude"
                        :rules="[rules.mandatory]"
                        :disabled="disabled"
                        :readonly="readonly"
                    >
                        <template #appendInner>
                            m
                        </template>
                    </va-input>
                </div>
            </div>
            <div class="row">
                <span class="display-6 xs12 flex">Instrument details</span>
                <div class="flex xs12 sm4">
                    <va-select
                        :options="types.instrument_status"
                        label="Instrument Status"
                        v-model="lidar.status"
                        track-by="_id"
                        text-by="name"
                        :rules="[rules.mandatory]"
                        :disabled="disabled"
                        :readonly="readonly"
                    />
                </div>
                <div class="flex xs12 sm4">
                    <va-date-input
                        v-model="lidar.planned_operation_date"
                        label="Planned operation date"
                        manual-input
                        :disabled="disabled"
                        :readonly="readonly"
                    >
                        <template #appendInner>
                            <va-popover prevent-overflow message="Initial acceptance can be granted at max. 1 year before installation">
                                <va-icon name="info" />
                            </va-popover>
                        </template>
                    </va-date-input>
                </div>
                <div class="flex xs12 sm4">
                    <va-select
                        :options="types.instrument"
                        label="Instrument Type"
                        v-model="lidar.type"
                        track-by="_id"
                        text-by="name"
                        :rules="[rules.mandatory]"
                        :disabled="disabled"
                        :readonly="readonly"
                    >
                        <template #appendInner>
                            <va-popover prevent-overflow message="If commercial, please contact CARS for details">
                                <va-icon name="info" />
                            </va-popover>
                        </template>
                    </va-select>
                </div>
            </div>
            <div class="row">
                <div class="flex xs12 sm8">
                    <va-input
                        type="text"
                        label="Instrument Manufacturer"
                        v-model="lidar.manufacturer"
                        style="width: 100%"
                        :rules="[rules.mandatory]"
                        :disabled="disabled"
                        :readonly="readonly"
                    />
                </div>
                <div class="flex xs12 sm4">
                    <va-select
                        :options="types.instrument_location"
                        label="Instrument Location"
                        v-model="lidar.location"
                        track-by="_id"
                        text-by="name"
                        :rules="[rules.mandatory]"
                        :disabled="disabled"
                        :readonly="readonly"
                    >
                        <template #appendInner>
                            <va-popover prevent-overflow message="If enclosure or container, please contact CARS for details">
                                <va-icon name="info" />
                            </va-popover>
                        </template>
                    </va-select>
                </div>
            </div>
            <div class="row">
                <div class="flex xs12">
                <va-input
                    type="textarea"
                    label="Additional Information"
                    v-model="lidar.additional_info"
                    style="width: 100%"
                    :disabled="disabled"
                    :readonly="readonly"
                />
                </div>
            </div>
            <div class="row">
                <span class="display-6 xs12 flex">Instrument additional functions</span>
                <div class="flex xs12 sm4">
                    <va-select
                        :options="types.instrument_additional_function"
                        label="Laser Alignment + Camera"
                        v-model="lidar.laser_alignment"
                        track-by="_id"
                        text-by="name"
                        :rules="[rules.mandatory]"
                        :disabled="disabled"
                        :readonly="readonly"
                    >
                        <template #appendInner>
                            <va-popover prevent-overflow message="Criteria: at least manual">
                                <va-icon name="info" />
                            </va-popover>
                        </template>
                    </va-select>
                </div>
                <div class="flex xs12 sm4">
                    <va-select
                        :options="types.instrument_additional_function"
                        label="Polarisation Calibration"
                        v-model="lidar.polarisation_calibration"
                        track-by="_id"
                        text-by="name"
                        :rules="[rules.mandatory]"
                        :disabled="disabled"
                        :readonly="readonly"
                    >
                        <template #appendInner>
                            <va-popover prevent-overflow message="Criteria: at least manual">
                                <va-icon name="info" />
                            </va-popover>
                        </template>
                    </va-select>
                </div>
                <div class="flex xs12 sm4">
                    <va-select
                        :options="types.instrument_additional_function"
                        label="Telecover Measurement"
                        v-model="lidar.telecover_measurement"
                        track-by="_id"
                        text-by="name"
                        :rules="[rules.mandatory]"
                        :disabled="disabled"
                        :readonly="readonly"
                    >
                        <template #appendInner>
                            <va-popover prevent-overflow message="Criteria: at least manual">
                                <va-icon name="info" />
                            </va-popover>
                        </template>
                    </va-select>
                </div>
                <div class="flex xs12 sm4">
                    <va-select
                        :options="types.instrument_additional_function"
                        label="Dark Signal Measurement"
                        v-model="lidar.dark_signal_measurement"
                        track-by="_id"
                        text-by="name"
                        :rules="[rules.mandatory]"
                        :disabled="disabled"
                        :readonly="readonly"
                    >
                        <template #appendInner>
                            <va-popover prevent-overflow message="Criteria: at least manual">
                                <va-icon name="info" />
                            </va-popover>
                        </template>
                    </va-select>
                </div>
            </div>
            <div class="row justify--space-between"  v-for="category_name in Object.keys(types.lidar_channel_categories)" :key="category_name">
                <span class="display-6 xs12 flex">{{category_name}} Channels</span>
                <div class="flex xs12">
                    <div class="row justify--space-between">
                        <div class="flex xs12 sm4">
                            <va-select
                                :options="types.lidar_channel_availability"
                                label="Availability"
                                v-model="lidar.channels[category_name].availability"
                                track-by="_id"
                                text-by="name"
                                :rules="[rules.mandatory]"
                                :disabled="disabled"
                                :readonly="readonly"
                            />
                        </div>
                    </div>
                    <div class="row justify--space-between mt-3 mb-3" v-if="channelAvailable(category_name)">
                        <div class="flex xs12 sm4">
                            <va-date-input
                                v-model="lidar.channels[category_name].operation_start_date"
                                label="Operation start date"
                                manual-input
                                :rules="[mandatoryChannelRule(lidar.channels[category_name].operation_start_date, category_name)]"
                                :disabled="disabled"
                                :readonly="readonly"
                            />
                        </div>
                        <div class="flex xs12 sm4">
                            <va-input
                                type="number"
                                :step="0.01"
                                manual-input
                                v-model="lidar.channels[category_name].height_resolution"
                                label="Height Resolution"
                                :rules="[mandatoryChannelRule(lidar.channels[category_name].height_resolution, category_name)]"
                                :disabled="disabled"
                                :readonly="readonly"
                            >
                                <template #appendInner>
                                    m
                                    <va-popover prevent-overflow message="Criteria: ≤15m">
                                        <va-icon name="info" />
                                    </va-popover>
                                </template>
                            </va-input>
                        </div>
                        <div class="flex xs12 sm4">
                            <va-input
                                type="number"
                                :step="1"
                                manual-input
                                v-model="lidar.channels[category_name].time_resolution"
                                label="Time Resolution"
                                :rules="[mandatoryChannelRule(lidar.channels[category_name].time_resolution, category_name)]"
                                :disabled="disabled"
                                :readonly="readonly"
                            >
                                <template #appendInner>
                                    s
                                    <va-popover prevent-overflow message="Criteria: ≤60s">
                                        <va-icon name="info" />
                                    </va-popover>
                                </template>
                            </va-input>
                        </div>
                        <div class="flex xs12 sm4">
                            <va-input
                                type="number"
                                :step="1"
                                manual-input
                                v-model="lidar.channels[category_name].distance_full_overlap"
                                label="Full Overlap Distance"
                                :rules="[mandatoryChannelRule(lidar.channels[category_name].distance_full_overlap, category_name)]"
                                :disabled="disabled"
                                :readonly="readonly"
                            >
                                <template #appendInner>
                                    m
                                    <va-popover prevent-overflow message="Criteria: ≤300m in max. 5 years">
                                        <va-icon name="info" />
                                    </va-popover>
                                </template>
                            </va-input>
                        </div>
                        <div class="flex xs12 sm4">
                            <va-input
                                type="number"
                                :step="0.01"
                                manual-input
                                v-model="lidar.channels[category_name].minimum_altitude_range"
                                label="Minimum Altitude Range"
                                :rules="[mandatoryChannelRule(lidar.channels[category_name].minimum_altitude_range, category_name)]"
                                :disabled="disabled"
                                :readonly="readonly"
                            >
                                <template #appendInner>
                                    m
                                    <template v-if="minimumAltitudeRangeCriteria(category_name)">
                                        <va-popover prevent-overflow :message="`Criteria: ${minimumAltitudeRangeCriteria(category_name)}`">
                                            <va-icon name="info" />
                                        </va-popover>
                                    </template>
                                </template>
                            </va-input>
                        </div>
                        <div class="flex xs12">
                            <va-select
                                :options="types.lidar_channel_categories[category_name]"
                                label="Channels"
                                v-model="lidar.channels[category_name].channels"
                                track-by="_id"
                                text-by="description"
                                multiple
                                style="width: 100%"
                                :rules="[mandatoryChannelRule(lidar.channels[category_name].channels.length, category_name)]"
                                :disabled="disabled"
                                :readonly="readonly"
                            >
                                <template #content="{value}">
                                    {{value.map(channel => channel.short_name).join(', ')}}
                                </template>
                            </va-select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <span class="display-6 xs12 flex">Instrument features</span>
                <div class="flex xs12 sm4">
                    <va-select
                        :options="types.instrument_feature"
                        label="Photodetector Eye Piece"
                        v-model="lidar.photodetector_eye_piece"
                        track-by="_id"
                        text-by="name"
                        :rules="[rules.mandatory]"
                        :disabled="disabled"
                        :readonly="readonly"
                    >
                        <template #appendInner>
                            <va-popover prevent-overflow message="Criteria: existing or planned in 6 months">
                                <va-icon name="info" />
                            </va-popover>
                        </template>
                    </va-select>
                </div>
                <div class="flex xs12 sm4">
                    <va-select
                        :options="types.instrument_feature"
                        label="Pretrigger ≥20 µs"
                        v-model="lidar.pretrigger_20us"
                        track-by="_id"
                        text-by="name"
                        :rules="[rules.mandatory]"
                        :disabled="disabled"
                        :readonly="readonly"
                    >
                        <template #appendInner>
                            <va-popover prevent-overflow message="Criteria: existing or planned in 6 months">
                                <va-icon name="info" />
                            </va-popover>
                        </template>
                    </va-select>
                </div>
                <div class="flex xs12 sm4" v-if="false">
                    <va-select
                        :options="types.instrument_feature"
                        label="Temperature and Humidity control"
                        v-model="lidar.t_h_control"
                        track-by="_id"
                        text-by="name"
                        :rules="[rules.mandatory]"
                        :disabled="disabled"
                        :readonly="readonly"
                    />
                </div>
                <div class="flex xs12 sm4" v-if="false">
                    <va-select
                        :options="types.instrument_feature"
                        label="Lidar Status Control"
                        v-model="lidar.lidar_status_control"
                        track-by="_id"
                        text-by="name"
                        :rules="[rules.mandatory]"
                        :disabled="disabled"
                        :readonly="readonly"
                    />
                </div>
                <div class="flex xs12 sm4">
                    <va-select
                        :options="types.instrument_feature"
                        label="Eye Safety Labels"
                        v-model="lidar.eye_safety"
                        track-by="_id"
                        text-by="name"
                        :rules="[rules.mandatory]"
                        :disabled="disabled"
                        :readonly="readonly"
                    />
                </div>
                <div class="flex xs12 sm4">
                    <va-select
                        :options="types.instrument_feature"
                        label="Unattended Operation"
                        v-model="lidar.unattended_operation"
                        track-by="_id"
                        text-by="name"
                        :rules="[rules.mandatory]"
                        :disabled="disabled"
                        :readonly="readonly"
                    />
                </div>
                <div class="flex xs12 sm4">
                    <va-select
                        :options="types.instrument_feature"
                        label="Continuous Operation"
                        v-model="lidar.continuous_operation"
                        track-by="_id"
                        text-by="name"
                        :rules="[rules.mandatory]"
                        :disabled="disabled"
                        :readonly="readonly"
                    />
                </div>
                <div class="flex xs12">
                    <va-input
                        type="textarea"
                        label="Additional Features"
                        v-model="lidar.additional_features"
                        style="width: 100%"
                        :disabled="disabled"
                        :readonly="readonly"
                    />
                </div>
            </div>
            <va-divider class="mt-4 mb-4" />
            <div class="row justify--center" v-if="!disabled && !readonly">
                <div class="flex">
                    <va-button type="submit" @click="submitAddLidarSystem()">Save lidar system to draft</va-button>
                </div>
            </div>
            <div class="row justify--center" v-else>
                <div class="flex">
                    <va-button type="submit" @click="$emit('hide')">Close</va-button>
                </div>
            </div>
        </va-form>
    </div>
</template>

<script>
import api from '@/lib/api'

import CPFileUpload from '@/components/ui/file_upload/CPFileUpload.vue'

export default {
    name: 'EditLidarSystemForm',
    components: {
        CPFileUpload
    },
    props: {
        readonly: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        lidarId: { type: String, default: undefined },
        data: {
            type: Object,
            default () {
                return {
                    latitude: .0,
                    longitude: .0,
                    altitude: .0,
                    status: undefined,
                    planned_operation_date: new Date(),
                    type: undefined,
                    manufacturer: '',
                    location: undefined,
                    additional_info: '',

                    pictures: [],
                    
                    laser_alignment: undefined,
                    polarisation_calibration: undefined,
                    telecover_measurement: undefined,
                    dark_signal_measurement: undefined,

                    photodetector_eye_piece: undefined,
                    pretrigger_20us: undefined,
                    t_h_control: undefined,
                    lidar_status_control: undefined,
                    eye_safety: undefined,
                    unattended_operation: undefined,
                    continuous_operation: undefined,

                    channels: {}
                }
            }
        }
    },
    data: () => ({
        lidar: undefined,
        types: {
            instrument: [],
            instrument_status: [],
            instrument_location: [],
            instrument_additional_function: [],
            instrument_feature: [],
            lidar_channel_categories: [],
            lidar_channel_availability: []
        },
        rules: {
            mandatory: (v) => !!v || "Required"
        },
        lidarPictures: [],
        dbPictures: {}
    }),
    watch: {
        data(newData) {
            if (newData) {
                this.lidar = newData
            }
        }
    },
    computed: {
        galleryDropzoneText () {
            if ( this.readonly ) {
                return "Instrument pictures"
            }

            return "Here you can upload instrument pictures. You can drag and drop files or "
        }
    },
    methods: {
        mandatoryChannelRule ( value, channelName ) {
            if ( !this.channelAvailable(channelName) ) {
                return true
            }

            return !!value || "Required"
        },
        channelAvailable (channelName) {
            try {
                return this.lidar.channels [ channelName ] .availability.code !== 'no';
            } catch {
                return false
            }
        },
        minimumAltitudeRangeCriteria (category_name) {
            switch (category_name) {
                case 'Elastic 1064': {
                    return 'SNR ≥9 at 10km in typical conditions (see guidelines)'
                }
                case 'Elastic 532': {
                    return 'SNR ≥11 (cross) and ≥55 (parallel) at 15km in typical conditions (see guidelines)'
                }
                case 'Elastic 355': {
                    return 'SNR ≥17 (cross) and ≥65 (parallel) at 15km in typical conditions (see guidelines)'
                }
                default: {
                    return undefined
                }
            }
        },
        async submitAddLidarSystem () {
            const formValid = await this.$refs.edit_lidar_system_form.validate()
            if ( !formValid ) {
                return;
            }

            this.$emit('save', this.lidar)
        },
        async loadDataTypes() {
            api.types.loadInstrumentTypes().then((response) => {
                this.types.instrument = response.data.data;
            })

            api.types.loadInstrumentStatusTypes().then((response) => {
                this.types.instrument_status = response.data.data;
            })

            api.types.loadInstrumentLocationTypes().then((response) => {
                this.types.instrument_location = response.data.data;
            })

            api.types.loadInstrumentAdditionalFunctionTypes().then((response) => {
                this.types.instrument_additional_function = response.data.data;
            })

            api.types.loadInstrumentFeatureTypes().then((response) => {
                this.types.instrument_feature = response.data.data;
            })

            api.types.loadInstrumentChannelAvailabilityTypes().then((response) => {
                this.types.lidar_channel_availability = response.data.data;
            })

            const response = await api.lidar.loadChannelCategories()
            this.types.lidar_channel_categories = []

            response.data.data.forEach ( category => {
                this.types.lidar_channel_categories[category.name] = category.channels;


                if ( !this.lidar.channels[ category.name ] ) {
                    this.lidar.channels[category.name] = {
                        channels: []
                    };
                }
            })
        },
        async loadPicturesData () {
            let pictures = await Promise.all (this.lidar.pictures.map (async picture => {
                return {
                    id: picture,
                    file: await api.file.getFile ( picture )
                }
            }))

            this.dbPictures = {}

            pictures.forEach (picture => {
                const file_obj = new File (
                    [picture.file.data],
                    picture.file.headers['content-disposition'].split('filename=')[1].split(';')[0],
                    { type: picture.file.data.type }
                )

                // Keep track of database IDs:
                this.dbPictures[ picture.id ] = file_obj
            })

            this.lidarPictures = Object.keys(this.dbPictures).map( id => this.dbPictures[id] )
        },
        async savePictures () {
            if ( !this.lidarId ) {
                return
            }

            await api.lidar.editLidarSystem (this.lidarId, {
                pictures: this.lidar.pictures
            })
        },
        async uploadPicture ( files ) {
            let dbObjects = await Promise.all (files.map( async file => {
                let fileUploadResponse = await api.file.uploadFile (file)

                return {
                    id: fileUploadResponse.data.data.id,
                    file: file
                }
            }))

            dbObjects.forEach (picture => {
                this.lidar.pictures.push ( picture.id )
                this.dbPictures[ picture.id ] = picture.file
            })

            // await this.savePictures ()
            // this.loadPicturesData ()
        },
        async removePicture ( file ) {
            const dbId = Object.keys(this.dbPictures).find(id => this.dbPictures[id] === file)

            await api.file.deleteFile ( dbId )

            delete ( this.dbPictures[dbId] )

            const idx = this.lidar.pictures.indexOf ( dbId )

            if (idx > -1) {
                this.lidar.pictures.splice ( idx, 1 )
                // this.savePictures ()
            }
            
        }
    },
    async mounted() {
        this.lidar = this.data

        await this.loadDataTypes()
        await this.loadPicturesData()
    }
}
</script>