async function uploadForm ( url, options ) {
    let formData = new FormData()

    Object.keys(options).forEach ( field => {
        formData.append(field, options[field])
    })

    return this.api({
        url: url,
        data: options,
        method: 'POST',
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

async function uploadFile ( file ) {
    return uploadForm.bind (this) ( '/files', { file: file } )
}

async function getFile ( fileId ) {
    return this.api({
        url: `/files/${fileId}`,
        method: 'GET',
        responseType: 'blob'
    })
}

async function downloadFile ( fileId ) {
    const file = await this.api({
        url: `/download/${fileId}`,
        method: 'GET',
        responseType: 'blob'
    })

    let filename = file.headers['content-disposition'].split('filename=')[1].split(';')[0]
    if (["'", '"'].indexOf(filename[0]) != -1) {
        filename = filename.slice(1,-1)
    }

    let link = document.createElement('a')
    link.href = window.URL.createObjectURL (file.data)
    link.download = filename

    link.click()
}

async function deleteFile ( fileId ) {
    return this.api({
        url: `/files/${fileId}`,
        method: 'DELETE'
    })
}

async function base64ImageData ( fileId ) {
    const bytes = await this.api({
        url: `/files/${fileId}`,
        method: 'GET'
    })

    const allowedContentTypes = [ "image/gif", "image/jpg", "image/jpeg", "image/png" ]

    if (!allowedContentTypes.includes (bytes.headers['content-type'])) {
        return ''
    }

    const base64Data = `data:${bytes.headers['content-type']};base64,${btoa(bytes)}`

    return base64Data
}

async function getImageAsDataURL ( fileId ) {
    const reader = new FileReader()

    let file = await getFile.bind(this) ( fileId )

    return new Promise(resolve => {
        reader.onload = ev => {
            resolve(ev.target.result)
        }

        reader.readAsDataURL(file.data)
    })
}

function fileURL ( fileId ) {
    return this.axiosInstance.getUri({url: `/files/${fileId}`})
}

export default {
    pluginName: 'file',
    methods: {
        uploadForm,
        uploadFile,
        getFile,
        downloadFile,
        deleteFile,
        base64ImageData,
        getImageAsDataURL,
        fileURL: fileURL
    }
}