import apiAxiosInstance from '@/plugins/axios';
import NationalFacilityApi from './apiPlugins/national_facility'
import TypesApi from './apiPlugins/types'
import LidarApi from './apiPlugins/lidar'
import PhotometerApi from './apiPlugins/photometer'
import UserApi from './apiPlugins/user'
import OtherApi from './apiPlugins/other'
import FileApi from './apiPlugins/file'
import EmailApi from './apiPlugins/email'

const getObjectId = function (obj) {
    if ( !obj ) {
        return undefined;
    }

    if ( !obj._id ) {
        if (typeof obj === 'string' || obj instanceof String) {
            return obj;
        }
        
        return undefined;
    }

    return obj._id;
}

class Api {
    constructor (axiosInstance, config) {
        this._axiosInstance = axiosInstance;

        if (config.overwriteBackendMessages) {
            this._overwriteBackendMessages = !!config.overwriteBackendMessages
        }

        this._messages = {}
    }

    use (apiPlugin) {
        if ( Object.prototype.hasOwnProperty.call(this, apiPlugin.pluginName) ) {
            return;
        }

        // First register any messages this plugin might provide:

        this._messages[apiPlugin.pluginName] = {}

        if (apiPlugin.apiMessages !== undefined) {
            for ( const [code, message] of Object.entries(apiPlugin.apiMessages)) {
                const codeNumber = parseInt(code);
                if ( Number.isInteger(codeNumber) ) {
                    this._messages[apiPlugin.pluginName][codeNumber] = message;
                }
            }
        }

        // Then register the plugin's API calls
        // by using a custom transformResponse for each plugin
        // in order to populate data.message:

        const pluginTransformResponse = ( data ) => {
            const codeNumber = parseInt(data.code);

            if ( data.msg ) {
                data.message = data.msg
            }

            if ( Number.isInteger(codeNumber) ) {
                if ( !data.message || this._overwriteBackendMessages ) {
                    data.message = this._messages[apiPlugin.pluginName][codeNumber] || "";
                }
            }
            
            return data;
        }

        const pluginApiCall  = (config) => {
            return this._axiosInstance({
                ...config,
                transformResponse: this._axiosInstance.defaults.transformResponse.concat([pluginTransformResponse])
            })
        }
        

        this[apiPlugin.pluginName] = {}

        for ( const[name, callable] of Object.entries(apiPlugin.methods) ) {
            this[apiPlugin.pluginName][name] = callable.bind({api: pluginApiCall, objId: getObjectId, axiosInstance: this._axiosInstance});
        }

        if ( apiPlugin.globals !== undefined ) {
            for ( const[name, object] of Object.entries(apiPlugin.globals) ) {
                this[apiPlugin.pluginName][name] = object;
            }
        }

        return this;
    }
}

const api = new Api(apiAxiosInstance, {
    overwriteBackendMessages: false
    })
    .use(NationalFacilityApi)
    .use(TypesApi)
    .use(LidarApi)
    .use(PhotometerApi)
    .use(UserApi)
    .use(OtherApi)
    .use(FileApi)
    .use(EmailApi)

export default api;