export default {
    root: {
        route: 'IndexView', params: {},
        title: 'Home',
        icon: 'home'
    }, 
    routes: [
        {
            route: 'IndexView', params: {},
            title: 'Home',
            icon: 'home'
        },
        {
          route: '', params: {},
          title: 'NF Area',
          icon: 'apartment',
          children: [
            { route: 'SelectPersonalNf', permissions: ['edit-own-nf'], title: 'Update NF Information', icon: 'edit' },
            { route: 'UnderConstruction', params: {}, permissions: ['submit-qa-qc-test'], title: 'Request lidar QA/QC support', icon: 'support' },
            { route: 'NFViewReports', params: {}, permissions: ['edit-own-nf'], title: 'View reports', icon: 'assignment' }
          ]
        },
        {
          route: '',
          title: 'CARS-ARES Area',
          icon: 'biotech',
          children: [
            { route: 'CarsEditNFInitialAcceptance', title: 'Evaluate NF', icon: 'search', permissions: ['evaluate-application', 'schedule-qa-qc-test', 'evaluate-qa-qc-test', 'issue-nf-report'] },
            { route: 'UnderConstruction', params: {}, title: 'Schedule Support', icon: 'support', permissions: ['schedule-additional-support'] },
            { route: 'CarsViewNFHistory', params: {}, title: 'View NF History', icon: 'history', permissions: ['view-all-nf'] }
          ]
        },
        {
          route: '', params: {},
          title: 'Admin',
          icon: 'admin_panel_settings',
          children: [
            { route: 'AdminEditUsers', params: {}, permissions: ['edit-users'], title: 'Users', icon: 'group' },
            { route: 'AdminEditNFs', params: {}, permissions: ['edit-all-nf'], title: 'National Facilities', icon: 'apartment' },
            { route: 'AdminEmails', params: {}, permissions: ['view-emails'], title: 'Email System', icon: 'email' }
          ]
        },
        {
          route: 'Contact',
          title: 'Contact CARPORT',
          icon: 'mail'
        }
    ]
}