import axios from '@/plugins/axios'

const state = {
    profile: {
        firstName: '',
        lastName: '',
        email: ''
    },
    users: [],
    roles: []
}

const getters = {
    profile(state) {
        return state.profile
    },
    fullUserList(state) {
        return state.users
    },
    fullRolesList(state) {
        return state.roles
    }
}

const actions = {
    async getFullUserList({
        commit
    }) {
        let usersResponse = await axios({
            url:'/user/getall',
            method: 'GET'
        })
        
        commit('GET_USERS', usersResponse.data.data)
    },
    async getFullRolesList({ commit }) {
        let rolesResponse = await axios({
            url: '/user/getAllRoles',
            method: 'GET'
        })

        commit('GET_ROLES', rolesResponse.data.data)
    },
    async getProfile({
        commit
    }) {
        let profileResponse = await axios({
            url:'/user/profile',
            method: 'GET'
        })

        commit('GET_PROFILE', profileResponse.data.data)
    },
    async setProfile({dispatch}, profile) {
        await axios({
            url: '/user/profile',
            method: 'POST',
            data: profile
        })
        dispatch('getProfile')
    },
    resetPassword({commit}, resetPassword) {
        commit('RESET_PASSWORD', resetPassword)
    },
    forgotPassword({commit}, forgotPassword) {
        commit('FORGOT_PASSWORD', forgotPassword)
    },
}

// mutations
const mutations = {
    GET_PROFILE(state, payload) {
        state.profile = payload
    },
    SET_PROFILE(state, payload) {
        state.profile = payload
    },
    GET_USERS(state, payload) {
        state.users = payload
    },
    GET_ROLES(state, payload) {
        state.roles = payload
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}