<template>
    <div class="layout">
        <template v-if="nfIds.length > 0">
            <div class="row mb-4 justify--center text--center">
                <div class="flex">
                    <span class="mr-2 display-5">National Facility Reports</span>
                </div>
            </div>
            <NFReportList :nfIds = "nfIds" />
        </template>
        <template v-else>
            <div class="no_nf__wrapper row align--center text--center justify--center">
                <div class="va-typography-block flex">
                    <h1 class="no_nf__title">Not found</h1>
                    <p class="no_nf__description">Seems like there is no National Facility registered yet.</p>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import api from '@/lib/api'

import NFReportList from '@/components/national_facility/NFReportList.vue'

export default {
    name: 'CarsViewNFHistory',
    components: {
        NFReportList
    },
    data: () => {
        return {
            nfIds: []
        }
    },
    methods: {
        async getNfIds () {
            const response = await api.nf.loadAllNFs()

            this.nfIds = response.data.data.map(nf => nf._id)
        }
    },
    mounted () {
        this.getNfIds()
    }
}
</script>