<template>
    <div class="layout gutter--xs">
        <div class="row">
            <va-data-table
                :columns="synergyTableColumns"
                :items="synergyMatrix.pairs"
                striped
            >
                <template #cell(lidar)="{rowData}">
                    {{ synergyMatrix.lidars[rowData.lidar].name }}
                </template>
                <template #cell(lidar_compliance)="{rowData}">
                    <va-chip
                        square
                        :color="complianceColor(synergyMatrix.lidars[rowData.lidar].general_compliance)"
                        size="small"
                    >
                        {{complianceName(synergyMatrix.lidars[rowData.lidar].general_compliance)}}
                    </va-chip>
                </template>
                <template #cell(photometer)="{rowData}">
                    {{ synergyMatrix.photometers[rowData.photometer].name }}
                </template>
                <template #cell(photometer_compliance)="{rowData}">
                    <va-chip
                        square
                        :color="complianceColor(synergyMatrix.photometers[rowData.photometer].general_compliance)"
                        size="small"
                    >
                        {{complianceName(synergyMatrix.photometers[rowData.photometer].general_compliance)}}
                    </va-chip>
                </template>
                <template #cell(distance)="{rowData}">
                    {{ rowData.distance.toFixed(2) }}km
                </template>
                <template #cell(synergy)="{rowData}">
                    <va-chip
                        square
                        :color="complianceColor(rowData.synergy)"
                        size="small"
                    >
                        {{complianceName(rowData.synergy)}}
                    </va-chip>
                </template>
            </va-data-table>
        </div>
        <va-divider class="pt-5 pb-2" />
        <div class="row align--center">
            <va-card-title>Evaluation</va-card-title>
            <span v-if="submitted">(Submitted)</span>
        </div>
        <va-form ref="synergyComplianceForm" class="layout gutter--xs mb-4" v-if="reviewer">
            <div class="row justify--center align--start">
                <div class="flex">
                    <va-select
                        label="Synergy Compliance"
                        :options="complianceTypes"
                        track-by="_id"
                        text-by="name"
                        v-model="synergyCompliance.compliance"
                        :rules="[rules.mandatory]"
                        :disabled="editDisabled"
                    >
                        <template #appendInner>
                            <va-popover prevent-overflow message="Criteria: Lidar and photometer within 1 Km horizontal distance">
                                <va-icon name="info" />
                            </va-popover>
                        </template>
                    </va-select>
                </div>
            </div>
            <div class="row justify--center align--center">
                <div class="flex xs12 sm6">
                    <va-input
                        label="Synergy compliance comments"
                        type="textarea"
                        v-model="synergyCompliance.comments"
                        :rules="[rules.mandatory]"
                        style="width: 100%"
                        messages="Required field"
                        :disabled="editDisabled"
                    />
                </div>
                <div class="flex xs12 sm6">
                    <va-input
                        label="Synergy compliance recommendations"
                        type="textarea"
                        v-model="synergyCompliance.recommendations"
                        :rules="[rules.mandatory]"
                        style="width: 100%"
                        messages="Required field"
                        :disabled="editDisabled"
                    />
                </div>
            </div>
            <div class="row justify--center align--center">
                <div class="flex" v-if="reviewer">
                    <va-button :disabled="editDisabled" @click="saveSynergyCompliance">Save draft</va-button>
                </div>
                <div class="flex" v-if="reviewer">
                    <va-button :disabled="editDisabled" icon="done" @click="confirmSynergyCompliance" :color="synergyComplianceColor">Submit</va-button>
                </div>
            </div>
        </va-form>
        <va-modal
            ref="confirmSynergyComplianceModal"
            stateful
            title="Confirm synergy compliance change"
            @ok="submitSynergyCompliance"
        >
            Are you sure you want to change the synergy compliance to <strong>{{synergyCompliance.name}}</strong> for this National Facility?
        </va-modal>
    </div>
</template>

<script>
import api from '@/lib/api'

import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'NFGeneralInfo',
    props: {
        id: {type: String, default: ''},
        disabled: {type: Boolean, default: false},
        reviewer: {type: Boolean, default: false}
    },
    components: {
    },
    data () {
        return {
            synergyCompliance: {
                compliance: undefined,
                comments: '',
                recommendations: ''
            },
            rules: {
                mandatory: (v) => !!v || "Required"
            },
            synergyMatrix: {
                pairs: [],
                lidars: undefined,
                photometers: undefined
            },
            synergyTableColumns: [
                { key: 'lidar', label: 'Lidar' },
                { key: 'photometer', label: 'Photometer' },
                { key: 'lidar_compliance', label: 'Lidar Compliance' },
                { key: 'photometer_compliance', label: 'Photometer Compliance' },
                { key: 'distance', label: 'Horizontal Distance' },
                { key: 'synergy', label: 'Synergy' }
            ]
        }
    },
    computed: {
        ...mapGetters ('types', ['acceptanceTypes', 'complianceTypes']),
        submitted () {
            return !!this.synergyCompliance.evaluation_date
        },
        editDisabled () {
            return this.disabled || this.submitted
        },
        synergyComplianceColor () {
            if ( !this.synergyCompliance.compliance ) {
                return 'primary'
            }

            switch ( this.synergyCompliance.compliance.code ) {
                case 'yes':
                    return 'success';
                case 'partially':
                    return 'warning';
                case 'no':
                default:
                    return 'danger';
            }
        }
    },
    watch: {
        id () {
            this.loadSynergyCompliance()
            this.loadSynergyMatrix()
        }
    },
    methods: {
        ...mapActions ('types', ['loadAcceptanceTypes', 'loadComplianceTypes']),
        complianceName (compliance) {
            try {
                return compliance.name
            }
            catch {
                return 'N/A'
            }
        },
        complianceColor (compliance) {
            if ( !compliance || !compliance.code ) {
                return 'gray'
            }

            switch ( compliance.code ) {
                case 'yes':
                    return 'success';
                case 'partially':
                    return 'warning';
                case 'no':
                default:
                    return 'danger';
            }
        },
        confirmSynergyCompliance () {
            const formValid = this.$refs.synergyComplianceForm.validate()
            if ( !formValid ) {
                return;
            }

            this.$refs.confirmSynergyComplianceModal.show()
        },
        loadSynergyCompliance () {
            if (!this.id) {
                return;
            }

            api.nf.loadSynergyCompliance(this.id).then(response => {
                if ( response.data.data.compliance )
                    this.synergyCompliance = response.data.data
            })
        },
        saveSynergyCompliance () {
            if (!this.id) {
                return
            }

            api.nf.setSynergyCompliance ( this.id, this.synergyCompliance )
        },
        async submitSynergyCompliance () {
            if (!this.id) {
                return
            }

            await api.nf.submitSynergyCompliance ( this.id, this.synergyCompliance )

            this.loadSynergyCompliance()
            this.$router.go()
        },
        loadSynergyMatrix () {
            if (!this.id) {
                return
            }

            api.nf.loadSuggestedSynergyCompliance ( this.id )

            api.nf.loadSynergyMatrix ( this.id ).then(response => {
                this.synergyMatrix = response.data.data
            })
        }
    },
    async mounted () {
        if ( !this.complianceTypes.length ) {
            this.loadComplianceTypes()
        }

        if ( !this.acceptanceTypes.length ) {
            this.loadAcceptanceTypes()
        }

        this.loadSynergyCompliance ()
        this.loadSynergyMatrix ()
    }
}
</script>