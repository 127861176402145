<template>
  <div class="layout">
    <div class="row">
      <div class="flex xs12 va-typography-block" v-if="!isLoggedIn">
        <span class="mb-5 display-3 text--center">Welcome to the CARS workflow management portal!</span>
        <p>This portal is managing the workflows between ACTRIS National Facilities (NF), ACTRIS Centre for Aerosol Remote Sensing (CARS) and the Aerosol remote Sensing unit of the ACTRIS Data Centre (ARES).</p>
        <p>Through this portal:</p>
        <ul class="mb-3 va-unordered">
          <li>ACTRIS aerosol remote sensing NFs submit and update detailed information about their instruments, submit lidar QA/QC information and data, and request for additional support from CARS</li>
          <li>CARS schedules the specific support for each NF, evaluates the lidar QA/QC information and issues reports</li>
          <li>CARS approves the operational configuration which are used by ARES to process lidar data</li>
        </ul>
        <p>The portal can be accessed only by registered users. The registration of a new user is done by CARPORT administrator at the request of the ACTRIS HO to start the labelling process.</p>
        <p>If you experience problems in logging in, please contact CARPORT from the <strong><router-link :to="{name: 'Contact'}">contact page</router-link></strong></p>
      </div>
      <div class="flex xs12 va-typography-block" v-else>
        <span class="mb-5 display-3 text--center">Hello! You have successfully logged in to CARPORT.</span>
        <p>This portal is managing the workflows between ACTRIS National Facilities (NF), ACTRIS Centre for Aerosol Remote Sensing (CARS) and the Aerosol remote Sensing unit of the ACTRIS Data Centre (ARES) throughout the labelling process:</p>
        <ul class="mb-3 va-unordered">
          <li>The initial acceptance of the NFs (Step 1a)</li>
          <li>The management of the QA/QCs actions (Step 1b)</li>
          <li>The approval of the label (Step 1c)</li>
          <li>The regular evaluation (Step 2)</li>
        </ul>
        <va-collapse header="More information about the workflow" icon="info" solid color="secondary">
          <div class="pa-4">
            <p><strong>If you are a NF PI, this is what you should do in CARPORT:</strong></p>
            <ul class="mb-3 va-unordered">
              <li>Fill in and update detailed information about the aerosol remote sensing instrumentation >>> necessary for labelling, all steps</li>
              <li>Submit lidar QA/QC tests for CARS analysis >>> necessary to check and (re)approve the operational configuration of your lidar(s)</li>
              <li>Request additional support from CARS (direct comparison, laboratory characterisation of components, expert visit for debugging the lidar, hands-on training, etc.) >>> necessary when you encounter a problem with the lidar that you cannot solve by yourself, or when CARS recommends</li>
              <li>View reports issued by CARS.</li>
            </ul>
            <p><strong>If you are a member of CARS, this is what you should do in CARPORT:</strong></p>
            <ul class="mb-3 va-unordered">
              <li>Evaluate the applications of the registered NFs, and make recommendations </li>
              <li>Schedule operation support (QA/QC tests or other) to be provided to each registered NF</li>
              <li>Input reports for each operation support provided to the NFs</li>
              <li>Pack and send to ARES the parameters for the operational configuration of the lidar(s) </li>
              <li>Create custom reports to track back the activities performed with each NF</li>
            </ul>
            <p>The workflow diagram is shown below.</p>
            <va-image :ratio="1779/761" contain :src="require('@/public/img/carport-workflow.png')" />
            <p>Note that CARPORT sends notifications via email to all actors involved (the PI of the concerned NF, CARS and ARES) whenever an important action takes place. When notified, please check if a certain action is expected from you and proceed via CARPORT.</p>
          </div>
        </va-collapse>
      </div>
      <div class="flex xs12 mb-4">
        <p class="display-3">Aerosol Remote Sensing facilities</p>
        <div style="height: 600px">
          <l-map
            :zoom="mapZoom"
            :center="[52.7688857, 16.7427272]"
            :options="mapOptions"
            ref="map"
            :use-global-leaflet="false"
          >
            <l-control :options="{position: 'bottomleft'}">
              <div class="nf_map_legend">
                <h4>Facilities types</h4>
                <i style="background: #2A81CB"></i><span>ACTRIS Facility</span><br />
                <i style="background: #CAC428"></i><span>EARLINET Facility</span><br />
              </div>
            </l-control>
            <l-tile-layer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              layer-type="base"
              :attribution="attribution"
            ></l-tile-layer>
            <l-marker v-for="nf in nationalFacilities" :key="nf.id" :lat-lng="getNfMapPosition(nf)" :icon="markerIcon(nf)">
              <l-tooltip :options="{permanent: true}">
                {{nf.acronym}}
              </l-tooltip>
              <l-popup>
                <h2>{{nf.acronym}}</h2>
                <p>Status: <strong>{{nf.displayed_status.name}}</strong></p>
                <p>Contact: <strong><a :href="`mailto:${nf.lidar_pi_contact}`">{{nf.lidar_pi_name}}</a></strong></p>
                <p>
                  <strong>Lidar systems:</strong>
                  <ul>
                    <li v-for="lidar in nf.lidars" :key="lidar._id">
                      <router-link target="_blank" :to="{name: 'ViewLidar', params: { id: lidar._id }}">{{lidar.name}}</router-link>
                    </li>
                  </ul>
                </p>
                <p>
                  <strong>Photometer systems:</strong>
                  <ul>
                    <li v-for="photometer in nf.photometers" :key="photometer._id">
                      <router-link target="_blank" :to="{name: 'ViewPhotometer', params: { id: photometer._id }}">{{photometer.name}}</router-link>
                    </li>
                  </ul>
                </p>
              </l-popup>
            </l-marker>
          </l-map>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'

  import api from '@/lib/api'

  import "leaflet/dist/leaflet.css"
  import { LMap, LTileLayer, LMarker, LPopup, LTooltip, LControl } from "@vue-leaflet/vue-leaflet"
  import { icon } from 'leaflet'

  export default {
    name: 'IndexView',
    components: {
      LMap,
      LTileLayer,
      LMarker,
      LPopup,
      LTooltip,
      LControl
    },
    data: () => ({
      nationalFacilities: [],
      mapZoom: 3.5,
      mapOptions: {
        zoomSnap: 0.5
      },
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      blueMarkerIcon: icon({
        iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
      }),
      yellowMarkerIcon: icon({
        iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-yellow.png',
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
      }),
    }),
    computed: {
      ...mapGetters('auth', ['isLoggedIn', 'userinfo'])
    },
    methods: {
      async getCertifiedNationalFacilities () {
        api.nf.getCertifiedNFs().then(response => {
          this.nationalFacilities = response.data.data
        })
      },
      getNfMapPosition (nf) {
        return [ nf?.lidars[0]?.latitude, nf?.lidars[0]?.longitude ]
      },
      markerIcon (nf) {
        if (nf.type?.code === 'actris') {
          return this.blueMarkerIcon
        }

        return this.yellowMarkerIcon
      }
    },
    async mounted() {
      this.getCertifiedNationalFacilities()
    }
  }
</script>

<style>
.nf_map_legend {
  padding: 6px 8px;
  font-size: 12px;
  background: white;
  background: rgba(255,255,255,0.8);
  line-height: 24px;
  color: #555;
}

.nf_map_legend h4 {
  font-size: 16px;
  margin: 2px 0 8px;
  color: #555;
}

.nf_map_legend span {
  position: relative;
  bottom: 3px;
}

.nf_map_legend i {
  width: 18px;
  height: 18px;
  float: left;
  margin: 0 8px 0 0;
}
</style>