async function sendContactMessage ( options ) {
    return this.api({
        url: `/contact`,
        data: {
            first_name: options.first_name,
            last_name: options.last_name,
            email: options.email,
            subject: options.subject,
            message: options.message
        },
        method: 'POST'
    })
}

export default {
    pluginName: 'other',
    methods: {
       sendContactMessage
    }
}