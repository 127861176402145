<template>
    <va-inner-loading :loading="!loaded">
        <va-form ref="nf_edit_info_form">
            <div class="row justify--end">
                <div class="flex">
                    
                </div>
            </div>
            <div class="row justify--center" v-if="!disabled && !readonly">
                <div class="flex xs12">
                    <va-collapse
                        header="Add new photometer system"
                        icon="add"
                        color="secondary"
                        solid
                    >
                        <EditPhotometerSystemForm
                            @save="(photometerData) => onAddNewPhotometer(photometerData)"
                            :disabled="disabled"
                            :readonly="readonly"
                        />
                    </va-collapse>
                </div>
            </div>
            <div class="row">
                <va-data-table
                    :items="photometers"
                    :columns="photometerTableColumns"
                    v-if="photometers.length"
                >
                    <template #cell(name)={rowData}>
                        <va-button icon="edit" class="mr-3" @click="showEditPhotometerSystemModal(rowData)" v-if="!disabled && !readonly" />
                        <va-button icon="visibility" class="mr-3" @click="showEditPhotometerSystemModal(rowData)" v-else />
                        {{rowData.name}}
                    </template>
                    <template #cell(status)="{rowData}">
                        {{rowData.status.name}}
                    </template>
                    <template #cell(compliance)="{rowData}">
                        <template v-if="rowData.general_compliance">{{rowData.general_compliance.name}}</template>
                        <template v-else>Not assessed</template>
                    </template>
                </va-data-table>
                <div class="flex xs12 va-typography mt-4" v-else>
                    <p v-if="disabled || readonly">No photometer registered</p>
                    <p v-else>You can register a photometer from the dropdown above. To do so, click <strong>Add new photometer</strong> and fill in the required fields.</p>
                </div>
            </div>
        </va-form>
        <va-modal
            stateful
            hide-default-actions
            ref="editPhotometerSystemModal"
            no-padding
        >
            <EditPhotometerSystemForm
                :data="photometerSystemToEdit"
                @save="submitEditPhotometerSystem(photometerSystemToEdit._id)"
                @hide="$refs.editPhotometerSystemModal.hide()"
                :disabled="disabled"
                :readonly="readonly"
            />
        </va-modal>
    </va-inner-loading>
</template>

<script>
const lengthMax64Rule = (v) => v.length <= 64 || "Maximum 64 characters";
const lengthMax8Rule = (v) => v.length <= 8 || "Maximum 8 characters";
const alphanumericOnlyRule = (v) => /^[a-zA-Z0-9_]+$/.test(v) || "Alphanumeric values only";
const emailRule = (v) => /^[^\s@]+@[^\s@]+$/.test(v) || 'Invalid E-Mail address';

import api from '@/lib/api'

import EditPhotometerSystemForm from '@/components/photometer/EditPhotometerSystemForm.vue'

export default {
    name: 'NFEditPhotometer',
    props: {
        id: { type: String, default: '' },
        readonly: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false }
    },
    components: {
        EditPhotometerSystemForm
    },
    data: () => ({
        photometers: [],
        photometerSystemToEdit: undefined,
        photometerTableColumns: [
            {key: 'name', label: 'Name'},
            {key: 'status', label: 'Operational Status'},
            {key: 'compliance', label: 'Compliance'}
        ],
        facilityNameRules: [lengthMax64Rule],
        facilityAcronymRules: [alphanumericOnlyRule, lengthMax8Rule],
        emailRules: [emailRule],
        loaded: false
    }),
    computed: {

    },
    watch: {
        id () {
            this.loadNFPhotometers()
        }
    },
    methods: {
        async submitEditPhotometerSystem (id) {
            await api.photometer.editPhotometerSystem (id, this.id, this.photometerSystemToEdit)
            this.loadNFPhotometers()

            this.$refs.editPhotometerSystemModal.hide()
        },
        openAddPhotometerDialog () {
            this.$refs.addPhotometerSystemDialog.show()
        },
        showEditPhotometerSystemModal (photometerData) {
            this.photometerSystemToEdit = photometerData
            this.$refs.editPhotometerSystemModal.show()
        },
        async onAddNewPhotometer ( photometerData ) {
            await api.nf.addPhotometerSystem ( this.id, photometerData )
            this.loadNFPhotometers()
        },
        async loadNFPhotometers () {
            this.loaded = false

            if (!this.id) {
                return;
            }

            const response = await api.nf.loadPhotometers(this.id)
            const data = response.data.data;
            this.photometers = data.photometers

            this.loaded = true
        }
    },
    async mounted () {
        this.loadNFPhotometers()
    }
}
</script>