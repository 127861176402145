<template>
    <div class="flex">
        <div class="row">
            <va-input v-model="tableFilter" placeholder="Search..." class="flex xs12 md3" label="Search table" />
        </div>
        <va-data-table
            :items="users"
            :columns="userTableColumns"
            :loading="loading"
            :filter="tableFilter"
            striped
        >
            <template #cell(email)="{rowData}">
                <va-button icon="edit" class="mr-2" @click="showEditUserDialog(rowData)" />
                <va-button icon="lock_reset" class="mr-2" @click="confirmResetUserPassword (rowData.email)" />

                <va-icon v-if="rowData.activated" name="check_circle" color="success"></va-icon>
                <va-icon v-else name="unpublished" color="danger"></va-icon>

                {{rowData.email}}
            </template>
            <template #cell(user_roles)="{rowData}">
                <span v-for="role in rowData.roles" :key="role.id">
                    {{role.name}} <br />
                </span>
            </template>
            <template #cell(name)="{rowData}">
                {{rowData.first_name}} {{rowData.last_name}}
            </template>
            <template #cell(status)="{rowData}">
                {{rowData.activated ? 'Activated' : 'Not activated' }}
                <va-button size="small" class="ml-2" @click="confirmResetUserPassword (rowData.email)">Reset password</va-button>
            </template>
        </va-data-table>
        <va-modal stateful ref="editUserDialogModal" no-padding @ok="submitEditUser" class="user_edit_modal">
            <template #content="{ok, cancel}">
                <va-card-title>Edit user</va-card-title>
                <va-card-content>
                    <va-form ref="edit_user_form" class="layout gutter--xl" @submit.prevent>
                        <div class="row">
                            <div class="flex xs12">
                                <va-input type="email" v-model="editUser.email" label="E-Mail" :rules="emailRules" style="width: 100%"/>
                            </div>
                            <div class="flex xs12">
                                <va-input type="text" v-model="editUser.first_name" label="First name" :rules="nameRules" style="width: 100%"/>
                            </div>
                            <div class="flex xs12">
                                <va-input type="text" v-model="editUser.last_name" label="Last name" :rules="nameRules" style="width: 100%"/>
                            </div>
                            <div class="flex xs12">
                                <va-select
                                    multiple
                                    :options=fullRolesList
                                    v-model="editUser.roles"
                                    track-by="_id"
                                    text-by="name"
                                    label="User roles"
                                    style="width: 100%"
                                />
                            </div>
                        </div>
                    </va-form>
                </va-card-content>
                <va-card-actions>
                    <div class="row justify--center">
                        <va-button @click="ok" icon="check">Save</va-button>
                        <va-button @click="cancel" flat>Cancel</va-button>
                    </div>
                </va-card-actions>
            </template>
        </va-modal>
        <va-modal
            ref="confirmPasswordResetModal"
            stateful
            title="Confirm password reset"
            @ok="resetUserPassword ( resetPasswordEmail )"
        >
            Are you sure you want to reset the password for <strong>{{resetPasswordEmail}}</strong>?
        </va-modal>
    </div>
</template>

<script>
  import api from '@/lib/api'

  export default {
    name: 'AdminUserTable',
    props: {
        users: Object,
        loading: Boolean,
        fullRolesList: Object
    },
    data: () => {
        return {
            tableFilter: '',
            userTableColumns: [
                {
                    key: 'email',
                    label: 'EMail',
                }, {
                    key: 'name',
                    label: 'Name'
                }, {
                    key: 'user_roles',
                    label: 'User Roles'
                }

            ],
            editUser: {email: '', first_name: '', last_name: '', _id: ''},
            resetPasswordEmail: undefined,
            emailRules: [ v => /^[^\s@]+@[^\s@]+$/.test(v) || 'Invalid E-Mail address' ],
            nameRules: [ v => v != "" || "Please enter a name" ]
        }
    },
    computed: {
    },
    methods: {
        showEditUserDialog (data) {
            this.editUser = {...data}

            this.$refs.editUserDialogModal.show()
        },
        async submitEditUser () {
            let formValid = this.$refs.edit_user_form.validate()

            if (!formValid) {
                return
            }

            api.user.editUser (this.editUser._id, {
                email: this.editUser.email,
                first_name: this.editUser.first_name,
                last_name: this.editUser.last_name,
                roles: this.editUser.roles.map(r => r._id)   
            }).then( () => {
                this.$emit('userEdit')
            })
        },
        confirmResetUserPassword ( email ) {
            this.resetPasswordEmail = email
            this.$refs.confirmPasswordResetModal.show()
        },
        async resetUserPassword ( email ) {
            api.user.requestPasswordReset ( email ).then( () => {
                this.$emit('userEdit')
            })
        }
    },
    async mounted() {
    }
  }
</script>

<style lang="scss">
.user_edit_modal {
    & .va-modal__container .va-modal__inner {
        min-width: 400px;
    }
}
</style>