const state = {
    isMobile: false,
    isTablet: false,
    sidebarMinimized: false
}

const getters = {
    isMobile(state) {
        return state.isMobile
    },
    isTablet(state) {
        return state.isTablet
    },
    isSidebarMinimized(state) {
        return state.sidebarMinimized
    }
}

const actions = {
    setIsMobile ( {commit}, isMobile ) {
        commit('SET_ISMOBILE', isMobile)
    },
    setIsTablet ( {commit}, isTablet ) {
        commit('SET_ISTABLET', isTablet)
    },
    setSidebarMinimized ( {commit}, sidebarMinimized ) {
        commit('SET_SIDEBARMINIMIZED', sidebarMinimized)
    }
}

// mutations
const mutations = {
    SET_ISMOBILE(state, payload) {
        state.isMobile = payload
    },
    SET_ISTABLET(state, payload) {
        state.isTablet = payload
    },
    SET_SIDEBARMINIMIZED(state, payload) {
        state.sidebarMinimized = payload
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}