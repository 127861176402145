async function editPhotometerSystem ( id, nfId, options ) {
    const data = {
        ...options,
        nf_id: nfId,
        planned_operation_date: Math.floor(new Date(options.planned_operation_date).getTime() / 1000),

        status: this.objId(options.status),
        type: this.objId(options.type),

        data_compliance: this.objId(options.data_compliance),
        data_compliance_reviewer: this.objId(options.data_compliance_reviewer),

        general_compliance: this.objId(options.general_compliance),
        general_compliance_reviewer: this.objId(options.general_compliance_reviewer)
    }

    return this.api({
        url: `/photometer/system/edit/${id}`,
        method: 'POST',
        data: data
    })
}

async function getPhotometerSystem (id) {
    return this.api({
        url: `/photometer/system/${id}`,
        method: 'GET'
    })
}
 
 export default {
     pluginName: 'photometer',
     methods: {
        getPhotometerSystem: getPhotometerSystem,
        editPhotometerSystem: editPhotometerSystem
     },
     apiMessages: {
         0: 'Operation successful!',
         1: 'Information loaded successfully!',
         2: 'Information saved successfully!',
         1000: 'Unknown error!',
         1001: 'Permission denied!',
         1002: 'Could not load information!',
         1003: 'Could not save information!',
         1004: 'Could not register National Facility!'
     }
 }