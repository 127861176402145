<template>
  <div class="app-layout">
    <NavigationBar />
    <div class="app-layout__content">
      <div class="app-layout__sidebar-wrapper" :class="{ minimized: isSidebarMinimized }">
        <SidebarMenu :minimized="isSidebarMinimized" :minimizedWidth="sidebarMinimizedWidth" :width="sidebarWidth" />
      </div>
      <div class="app-layout__page">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
    import NavigationBar from './components/navbar/NavigationBar.vue'
    import SidebarMenu from './components/sidebar/SidebarMenu.vue'

    import {mapGetters, mapActions} from 'vuex'

    const mobileBreakPointPX = 640;
    const tabletBreakPointPX = 768;
    
    export default {
        name: 'App',
        data: () => ({}),
        components: {
          NavigationBar,
          SidebarMenu
        },
        computed: {
          ...mapGetters('auth', [ 'isLoggedIn' ]),
          ...mapGetters('ui', [ 'isMobile', 'isTablet', 'isSidebarMinimized' ]),

          sidebarMinimizedWidth () {
            return this.isMobile ? '0' : '4.5rem'
          },
          sidebarWidth () {
            return this.isTablet ? '100%' : '20rem'
          },
          isFullScreenSidebar () {
            return this.isTablet && !this.isSidebarMinimized;
          }
        },
        methods: {
          ...mapActions('ui', ['setIsMobile', 'setIsTablet', 'setSidebarMinimized']),
          checkIsMobile () {
            return window.innerWidth < mobileBreakPointPX;
          },
          checkIsTablet () {
            return window.innerWidth < tabletBreakPointPX;
          },
          onResize() {
            this.setIsMobile ( this.checkIsMobile() );
            this.setIsTablet ( this.checkIsTablet() );

            if ( this.isTablet ) {
              this.setSidebarMinimized ( true );
            }
          },
          onCloseSidebarButtonClick() {
            this.setSidebarMinimized(true);
          }
        },
        mounted() {
          window.addEventListener('resize', this.onResize);

          this.onResize()
        },
        unmounted() {
          window.removeEventListener('resize', this.onResize);
        },
        watch: {
          $route() {
            if ( this.checkIsTablet() ) {
              this.setSidebarMinimized (true)
            }
          }
        }
    }
</script>

<style lang="scss">
.fileUploadGallery .va-file-upload-gallery-item {
    flex-basis: calc(50% - .5rem);
    max-width: calc(50% - .5rem);
}

.fileUploadGallery .va-list-item--disabled {
    opacity: 1;
}

.fileUploadGallery .va-file-upload__field__button.va-button--disabled {
    display: none;
}

  $mobileBreakPointPX: 640px;
  $tabletBreakPointPX: 768px;

  .app-layout {
    height: 100vh;
    display: flex;
    flex-direction: column;
    &__navbar {
      min-height: 4rem;
    }
    &__content {
      display: flex;
      height: calc(100vh - 4rem);
      flex: 1;
      @media screen and (max-width: $tabletBreakPointPX) {
        height: calc(100vh - 6.5rem);
      }
      .app-layout__sidebar-wrapper {
        position: relative;
        flex-shrink: 0;
        float: left;
        height: 100%;
        z-index: 1;
        background: var(--va-white);

        @media screen and (max-width: $tabletBreakPointPX) {
          &:not(.minimized) {
            width: 100%;
            height: 100%;
            top: 0;
            z-index: 999;
          }
          .va-sidebar:not(.va-sidebar--minimized) {
            // Z-index fix for preventing overflow for close button
            z-index: -1;
            .va-sidebar__menu {
              padding: 0;
            }
          }
        }
      }
    }
    &__page {
      flex-grow: 2 !important;
      overflow-y: scroll;
    }
  }
</style>