<template>
    <va-inner-loading :loading="!loaded">
        <va-form ref="nf_edit_info_form" class="layout fluid gutter--xl">
            <div class="row justify--space-between align--center" v-if="!disabled && !readonly">
                <div class="flex">
                    <va-button type="submit" @click="submitEditNFGeneralInfo()">Save draft</va-button>
                    <va-button color="danger" flat @click="loadNFInfo()">Revert changes</va-button>
                </div>
                <div class="flex">
                    
                </div>
            </div>
            <div class="row">
                <div class="row">
                    <span class="flex xs12 display-6">Facility Information</span>
                    <va-input type="text" v-model="nfInfo.name" label="Facility Name" :rules="facilityNameRules" class="flex xs12 md6 xl4" :disabled="disabled" :readonly="readonly" />
                    <va-input type="text" v-model="nfInfo.acronym" message="Can only be changed by CARS" label="Facility Acronym" :rules="facilityAcronymRules" class="flex xs12 md6 xl4" :disabled="disabled || !cars" :readonly="readonly" />
                    <va-input type="text" v-model="nfInfo.hostingInstitute" label="Hosting Institute(s)"  class="flex xs12 md6 xl4" :disabled="disabled" :readonly="readonly" />
                    <va-input type="text" v-model="nfInfo.piName" label="PI Name" class="flex xs12 md6 xl4" :disabled="disabled" :readonly="readonly" />
                    <va-input type="text" v-model="nfInfo.piContact" label="PI Contact (E-Mail)" class="flex xs12 md6 xl4" :disabled="disabled || !cars" :readonly="readonly" />
                    <div class="flex xs12">
                        <div class="row justify--center">
                            <CPFileUpload
                                v-model="nfPictures"
                                file-types="jpg,jpeg,png"
                                type="gallery"
                                maxFiles=1
                                dropzone
                                @file-added="uploadPicture"
                                @file-removed="removePicture"
                                class="flex xs12 fileUploadGallery"
                                uploadButtonText = "Select files from your computer"
                                :dropZoneText="galleryDropzoneText"
                                :disabled="readonly"
                                requireConfirmation
                                confirmationMessage="Are you sure you want to delete this picture? This operation is irreversible!"
                                okText="Yes"
                                cancelText="Cancel"
                            >
                            </CPFileUpload>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <span class="flex xs12 display-6">Lidar PI Information</span>
                    <va-input type="text" v-model="nfInfo.lidarPiName" label="Lidar PI Name" class="flex xs12 md6 xl4" :disabled="disabled" :readonly="readonly" />
                    <va-input type="text" v-model="nfInfo.lidarPiContact" label="Lidar PI Contact (E-Mail)" :rules="emailRules" class="flex xs12 md6 xl4" :disabled="disabled" :readonly="readonly" />
                </div>
                <div class="row">
                    <span class="flex xs12 display-6">Photometer PI Information</span>
                    <va-input type="text" v-model="nfInfo.photometerPiName" label="Photometer PI Name" class="flex xs12 md6 xl4" :disabled="disabled" :readonly="readonly" />            
                    <va-input type="text" v-model="nfInfo.photometerPiContact" label="Photometer PI Contact (E-Mail)" :rules="emailRules" class="flex xs12 md6 xl4" :disabled="disabled" :readonly="readonly" />
                </div>
                <div class="row">
                    <span class="flex xs12 display-6">Ceilometer PI Information</span>
                    <va-input type="text" v-model="nfInfo.ceilometerPiName" label="Ceilometer PI Name" class="flex xs12 md6 xl4" :disabled="disabled" :readonly="readonly" />
                    <va-input type="text" v-model="nfInfo.ceilometerPiContact" label="Ceilometer PI Contact (E-Mail)" :rules="emailRules" class="flex xs12 md6 xl4" :disabled="disabled" :readonly="readonly" />
                </div>
            </div>
        </va-form>
    </va-inner-loading>
</template>

<script>
const lengthMax64Rule = (v) => v.length <= 64 || "Maximum 64 characters";
const lengthMax24Rule = (v) => v.length <= 24 || "Maximum 24 characters";
const alphanumericOnlyRule = (v) => /^[a-zA-Z0-9-_]+$/.test(v) || "Alphanumeric values only";
const emailRule = (v) => /^[^\s@]+@[^\s@]+$/.test(v) || 'Invalid E-Mail address';

import api from '@/lib/api'

import CPFileUpload from '@/components/ui/file_upload/CPFileUpload.vue'

export default {
    name: 'NFEditInfo',
    props: {
        id: { type: String, default: '' },
        readonly: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        cars: { type: Boolean, default: false }
    },
    components: {
        CPFileUpload
    },
    data: () => ({
        loaded: false,
        nfInfo: {
            name: '',
            acronym: '',
            hostingInstitute: '',
            piName: '',
            piContact: '',
            lidarPiName: '',
            lidarPiContact: '',
            photometerPiName: '',
            photometerPiContact: '',
            ceilometerPiName: '',
            ceilometerPiContact: '',
            picture: undefined,
            pictures: []
        },
        facilityNameRules: [lengthMax64Rule],
        facilityAcronymRules: [alphanumericOnlyRule, lengthMax24Rule],
        emailRules: [emailRule],
        nfPictures: [],
        realNfPictures: [],
        dbPictures: {}
    }),
    computed: {
        galleryDropzoneText () {
            if ( this.readonly ) {
                return "National Facility pictures"
            }

            return "Here you can upload National Facility pictures. You can drag and drop files or "
        }
    },
    watch: {
        id () {
            this.loadNFInfo()
        }
    },
    methods: {
        async submitEditNFGeneralInfo () {
            let response = api.nf.editGeneralInfo(this.id, this.nfInfo)
            response.catch((error) => {
                console.log(error)
            })
        },
        async loadNFInfo () {
            this.loaded = false;

            if ( !this.id ) {
                return;
            }

            const response = await api.nf.loadGeneralInfo(this.id)

            const data = response.data.data

            this.nfInfo.name = data.name
            this.nfInfo.acronym = data.acronym
            this.nfInfo.hostingInstitute = data.hosting_institute
            this.nfInfo.piName = data.pi_name
            this.nfInfo.piContact = data.pi_contact
            this.nfInfo.lidarPiName = data.lidar_pi_name
            this.nfInfo.lidarPiContact = data.lidar_pi_contact
            this.nfInfo.photometerPiName = data.photometer_pi_name
            this.nfInfo.photometerPiContact = data.photometer_pi_contact
            this.nfInfo.ceilometerPiName = data.ceilometer_pi_name
            this.nfInfo.ceilometerPiContact = data.ceilometer_pi_contact
            this.nfInfo.pictures = data.pictures

            this.loadPicturesData ()

            this.loaded = true;
        },
        async loadPicturesData () {
            let pictures = await Promise.all (this.nfInfo.pictures.map (async picture => {
                return {
                    id: picture,
                    file: await api.file.getFile ( picture )
                }
            }))

            this.dbPictures = {}

            pictures.forEach (picture => {
                const file_obj = new File (
                    [picture.file.data],
                    picture.file.headers['content-disposition'].split('filename=')[1].split(';')[0],
                    { type: picture.file.data.type }
                )

                // Keep track of database IDs:
                this.dbPictures[ picture.id ] = file_obj
            })

            this.nfPictures = Object.keys(this.dbPictures).map( id => this.dbPictures[id] )
        },
        async savePictures () {
            await api.nf.editGeneralInfo (this.id, {
                pictures: this.nfInfo.pictures
            })
        },
        async uploadPicture ( files ) {
            let dbObjects = await Promise.all (files.map( async file => {
                let fileUploadResponse = await api.file.uploadFile (file)

                return {
                    id: fileUploadResponse.data.data.id,
                    file: file
                }
            }))

            dbObjects.forEach (picture => {
                this.nfInfo.pictures.push ( picture.id )
                this.dbPictures[ picture.id ] = picture.file
            })

            await this.savePictures ()
            this.loadPicturesData ()
        },
        async removePicture ( file ) {
            const dbId = Object.keys(this.dbPictures).find(id => this.dbPictures[id] === file)
            delete ( this.dbPictures[dbId] )

            const idx = this.nfInfo.pictures.indexOf ( dbId )

            if (idx > -1) {
                this.nfInfo.pictures.splice ( idx, 1 )
                this.savePictures ()
            }
            
        }
    },
    async mounted () {
        this.loadNFInfo()
    }
}
</script>