<template>
    <div class="flex">
        <div class="row">
            <va-input v-model="tableFilter" placeholder="Search..." class="flex xs12 md3" label="Search table" />
        </div>
        <va-data-table
            :items = "submissions"
            :columns = "nfReportsColumns"
            :loading = "loading"
            :filter = "tableFilter"
        >
            <template #cell(name)="{rowData}">
                {{rowData.name}} ({{rowData.acronym}})
            </template>
            <template #cell(initial_acceptance)="{rowData}">
                <va-button
                    :disabled = "!initialAcceptanceSubmitted ( rowData.submission )"
                    @click = "downloadInitialAcceptanceSubmission ( rowData._id )"
                    icon = "download"
                >
                    Submission
                </va-button>
                <va-button
                    :disabled = "!upgradePlanUploaded ( rowData.submission )"
                    @click = "downloadUpgradePlan ( rowData.submission?.user_file )"
                    icon = "download"
                    class="ml-2"
                >
                    Upgrade Plan
                </va-button>
                <va-button
                    :disabled = "!initialAcceptanceEvaluated ( rowData.submission )"
                    @click = "downloadInitialAcceptanceEvaluation ( rowData._id )"
                    icon = "download"
                    class="ml-2"
                >
                    Evaluation
                </va-button>
            </template>
        </va-data-table>
    </div>
</template>

<script>
import api from '@/lib/api'

export default {
    name: 'NFReportList',
    props: {
        nfIds: {
            type: Array,
            default() {
                return []
            }
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data: () => {
        return {
            submissions: [],
            nfReportsColumns: [
                { key: 'name', label: 'National Facility' },
                { key: 'initial_acceptance', label: 'Initial Acceptance' }
            ],
            tableFilter: ''
        }
    },
    computed: {
    },
    methods: {
        initialAcceptanceSubmitted ( submission ) {
            return !!submission?.date
        },
        upgradePlanUploaded ( submission ) {
            return !!submission?.user_file
        },
        initialAcceptanceEvaluated ( submission ) {
            return !!submission?.evaluation_date
        },
        async loadSubmissions () {
            this.submissions = await Promise.all(
                this.nfIds.map ( async nfId => {
                    const infoResponse = await api.nf.loadGeneralInfo (nfId)
                    const statusResponse = await api.nf.loadInitialAcceptanceStatus (nfId)

                    return {
                        ...infoResponse.data.data,
                        submission: statusResponse.data.data
                    }
                })
            )

            console.log(this.submissions)
        },
        downloadInitialAcceptanceSubmission (nfId) {
            api.nf.downloadInitialAcceptanceSubmissionPdf ( nfId ).then(response => {
                const blob = new Blob([response.data], { type: response.headers['content-type'] })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = response.headers['content-disposition'].split('filename=')[1].split(';')[0];
                link.click()
                URL.revokeObjectURL(link.href)
            }).catch(console.error)
        },
        downloadUpgradePlan (fileId) {
            api.file.downloadFile ( fileId )
        },
        downloadInitialAcceptanceEvaluation (nfId) {
            api.nf.downloadInitialAcceptanceEvaluationPdf ( nfId ).then(response => {
                const blob = new Blob([response.data], { type: response.headers['content-type'] })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = response.headers['content-disposition'].split('filename=')[1].split(';')[0];
                link.click()
                URL.revokeObjectURL(link.href)
            }).catch(console.error)
        }
    },
    async mounted() {
        await this.loadSubmissions ()
    },
    watch: {
        nfIds () {
            this.loadSubmissions ()
        }
    }
}
</script>