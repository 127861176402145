<template>
    <div class="row">
        <NFLidarsList :id="id" :disabled="disabled" :reviewer="reviewer" :dataReviewer="dataReviewer" />
    </div>
</template>

<script>
import api from '@/lib/api'

import NFLidarsList from '@/components/national_facility/cars/initial_acceptance/lidar/NFLidarsList.vue'

export default {
    name: 'NFLidars',
    props: {
        id: {type: String, default: ''},
        disabled: {type: Boolean, default: false},
        reviewer: {type: Boolean, default: false},
        dataReviewer: { type: Boolean, default: false }
    },
    components: {
        NFLidarsList
    },
    data () {
        return {
            complianceTypes: [],
            lidarCompliance: {
                compliance: undefined,
                comments: '',
                recommendations: ''
            },
            rules: {
                mandatory: (v) => !!v || "Required"
            }
        }
    },
    computed: {
        submitted () {
            return !!this.lidarCompliance.evaluation_date
        },
        editDisabled () {
            return this.disabled || this.submitted
        },
        lidarComplianceColor () {
            if ( !this.lidarCompliance.compliance ) {
                return 'primary'
            }

            switch ( this.lidarCompliance.compliance.code ) {
                case 'yes':
                    return 'success';
                case 'partially':
                    return 'warning';
                case 'no':
                default:
                    return 'danger';
            }
        }
    },
    watch: {
        id () {
            this.loadLidarCompliance ()
        }
    },
    methods: {
        loadComplianceTypes () {
            api.types.loadComplianceTypes().then((response) => { this.complianceTypes = response.data.data })
        },
        confirmLidarCompliance () {
            const formValid = this.$refs.lidarGeneralComplianceForm.validate()
            if ( !formValid ) {
                return;
            }

            this.$refs.confirmLidarComplianceModal.show()
        },
        loadLidarCompliance () {
            if (!this.id) {
                return
            }

            api.nf.loadLidarCompliance ( this.id ).then(response => {
                if ( response.data.data.compliance )
                    this.lidarCompliance = response.data.data
            })
        },
        saveLidarCompliance () {
            if (!this.id) {
                return
            }

            api.nf.setLidarCompliance ( this.id, this.lidarCompliance )
        },
        submitLidarCompliance () {
            if (!this.id) {
                return
            }

            api.nf.submitLidarCompliance ( this.id )
        }
    },
    async mounted () {
        this.loadComplianceTypes()
        this.loadLidarCompliance()
    }
}
</script>