<template>
    <va-form ref="register_nf_form" class="layout gutter--xl" @submit.prevent>
      <div class="row justify--center">
        <span class="display-1">Register new National Facility</span>
      </div>
      <div class="row justify--center">
        <div class="flex">
            <va-input type="text" v-model="nfInfo.acronym" label="Acronym" :rules="NFAcronymRules"/>
        </div>
        <div class="flex">
            <va-select
              :options="nfLeaders"
              :text-by="nameFromUserObject"
              v-model="nfInfo.owner"
              :rules="[rules.mandatory]"
              track-by="email"
              label="NF Leader"
              searchable
              no-options-text="No suitable users found"
            />
        </div>
      </div>
      <div class="row justify--center">
        <div class="flex">
            <va-button type="submit" @click="submitRegisterNF()">Register new NF</va-button>
        </div>
      </div>
    </va-form>
</template>

<script>
  import {mapGetters} from 'vuex'

  import api from '@/lib/api'

  export default {
    name: 'RegisterNF',
    data: () => ({
      nfInfo: { acronym: '', owner: undefined },
      nfLeaders: [],
      NFAcronymRules: [ v => /^[a-zA-Z0-9_-]+$/.test(v) || "Acronym must be alphanumeric", v => v.length <= 8 || "Acronym must be maximum 8 characters long" ],
      emailRules: [ v => /^[^\s@]+@[^\s@]+$/.test(v) || 'Invalid E-Mail address' ],
      rules: {
        mandatory: v => !!v
      }
    }),
    computed: {
      ...mapGetters('auth', ['isLoggedIn', 'userinfo'])
    },
    methods: {
        nameFromUserObject (user) {
          try {
            return `${user.first_name} ${user.last_name}`
          } catch {
            return "Unknown user"
          }
        },
        loadNfLeaders () {
            api.user.loadUsersWithoutNf().then(response => {
                this.nfLeaders = response.data.data
            })
        },
        async submitRegisterNF () {
            let formValid = this.$refs.register_nf_form.validate()

            if (!formValid) {
                return
            }

            api.nf.register ( this.nfInfo )
        }
    },
    async mounted() {
      this.loadNfLeaders()
    }
  }
</script>