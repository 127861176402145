<template>
    <div class="layout gutter--xl">
        <div class="row justify--center mb-4">
            <h1 class="display-1">Reset password</h1>
        </div>
        <va-form ref="reset_password_form" @validation="formValid = $event" @submit.prevent="submitForm" v-if="!submitted">
            <div class="row justify--center">
                <div class="flex xs12 sm6">
                    <va-input
                        label="Email"
                        v-model="emailInput"
                        :rules="emailRules"
                        type="email"
                        style="width: 100%"
                        @blur="$refs.reset_password_form.validate()"
                    />
                </div>
                <div class="flex">
                    <va-button type="submit" color="primary" :disabled="!formValid" @click="submitForm">Reset password</va-button>
                </div>
            </div>
        </va-form>
        <div class="row" v-else>
            <div class="flex va-typography">
                <va-alert v-if="error" color="danger" title="Your password request could not be processed! Please contact the administrator">{{error}}</va-alert>
                <p v-else>Your password request has been registered, you will not be able to login before setting up a new password.
                You should receive an email with furter instructions shortly. If you don't receive any email, please contact the CARPORT administrator.</p>
            </div>
        </div>
        <div class="row" v-if="error">
            <div class="flex xs12 sm6">
                <va-alert color="danger" title="Your password request could not be processed! Please contact the administrator">{{error}}</va-alert>
            </div>
        </div>
    </div>
</template>

<script>
  import api from '@/lib/api'

  export default {
    name: 'ResetPassword',
    data: () => {
        return {
            formValid: false,
            submitted: false,
            error: undefined,
            emailInput: '',
            passwordInput: '',
            confirmPasswordInput: '',
            passwordRules: [ v => v != "" || "Please enter your new password" ],
            emailRules: [ v => /^[^\s@]+@[^\s@]+$/.test(v) || 'Invalid E-Mail address' ]
        }
    },
    computed: {
        samePasswordRule () {
            return () => (this.passwordInput == this.confirmPasswordInput) || 'Passwords do not match!'
        }
    },
    methods: {
        async submitForm () {
            const validForm = this.$refs.reset_password_form.validate()

            if (!validForm) {
                return
            }

            api.user.requestPasswordReset(this.emailInput).then(() => {
                this.submitted = true
                this.error = undefined
            }).catch((error) => {
                this.submitted = false
                
                try {
                    this.error = error.response.data.msg
                } catch {
                    this.error = "Unknown error"
                }
            })
        }
    },
    async mounted() {
    }
  }
</script>