<template>
    <div class="layout">
        <template v-if="nfExists">
            <div class="row mb-4 justify--center text--center" v-if="submissionStatus">
                <div class="flex">
                    <span class="mr-4 display-5">National Facility</span>
                    <va-badge class="mr-4" :text="submissionStatus.name" :color="badgeColorFromStatusCode(submissionStatus.code)" />
                    <va-select
                        v-if="isAdmin() && nfExists"
                        :options="nfDisplayedStatusTypes"
                        text-by="name"
                        track-by="code"
                        v-model="nfDisplayedStatus"
                        @update:model-value="confirmNewDisplayedStatus"
                        label="Displayed status"
                    ></va-select>
                </div>
            </div>
            <div class="row mb-4 justify--center text--center">
                <div class="flex xs12">
                    <va-button v-if="personalNf" :disabled="!userCanSubmitApplication" @click="onSubmitApplicationClick()">Submit application</va-button>
                    <va-button :disabled="!submitted" icon="description" flat @click="downloadPdf()">Download Application (PDF)</va-button>
                    <va-button v-if="shouldUploadUpgradePlan" @click="openUpgradePlanModal()" :disabled="!canUploadUpgradePlan">Upload upgrade plan</va-button>
                    <va-button v-if="upgradePlanUploaded" icon="description"  flat @click="downloadUpgradePlan">Download upgrade plan (PDF)</va-button>
                    <va-button v-if="evaluated" icon="description" flat @click="downloadReport">Download evaluation report (PDF)</va-button>
                </div>
                <div class="flex xs12" v-if="shouldUploadUpgradePlan">
                    <div class="row justify--center">
                        <div class="flex">
                        </div>
                    </div>
                </div>
            </div>
        
            <div class="row justify--center">
                <div class="flex xs12">
                    <va-tabs v-model="selectedTab" grow>
                        <template #tabs>
                        <va-tab
                            v-for="tab in tabs"
                            :key="tab.id"
                            :name="tab.id"
                        >
                            {{tab.title}}
                        </va-tab>
                        </template>
                    </va-tabs>
                </div>
            </div>
            <div class="row justify--center">
                <va-card square outlined class="flex xs12">
                    <va-card-content>
                        <KeepAlive>
                            <component :is="activeTab.component" v-bind="activeTabComponentProps"></component>
                        </KeepAlive>
                    </va-card-content>
                </va-card>
            </div>
            <va-modal
                ref="displayedStatusModal"
                stateful
                title="Change displayed status"
                @ok="submitDisplayedStatus"
                @cancel="getNfDisplayedStatus"
            >
                Are you sure you want to change this National Facility displayed status to <strong>{{nfDisplayedStatus.name}}</strong>?
            </va-modal>
            <va-modal
                ref="upgradePlanModal"
                stateful
                title="Upload upgrade plan"
                @ok="uploadUpgradePlan"
            >
                <div class="row">
                    <div class="va-typography-block flex xs12">
                        <p><strong>Once you submit an upgrade plan for this National Facility you will not be able to change it.</strong></p>
                    </div>
                    <va-file-upload
                        v-model="upgradePlanFile"
                        file-types="pdf"
                        type="single"
                        uploadButtonText = "Select PDF file"
                        :disabled="!canUploadUpgradePlan"
                        class="flex xs12"
                    />
                </div>
            </va-modal>
        </template>
        <template v-else>
            <div class="no_nf__wrapper row align--center text--center justify--center">
                <div class="va-typography-block flex">
                    <h1 class="no_nf__title">Not found</h1>
                    <p class="no_nf__description">Seems like there is no National Facility registered under your account.</p>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
  import {shallowRef} from 'vue'

  import {mapActions} from 'vuex'

  import NFEditInfo from '@/components/national_facility/NFEditInfo.vue'
  import NFEditLidar from '@/components/national_facility/NFEditLidar.vue'
  import NFEditPhotometer from '@/components/national_facility/NFEditPhotometer.vue'

  import CPFileUpload from '@/components/ui/file_upload/CPFileUpload.vue'

  import api from '@/lib/api'

    const TABS = [
        { id: 'general', title: 'General info', component: shallowRef(NFEditInfo) },
        { id: 'lidar', title: 'Lidars', component: shallowRef(NFEditLidar) },
        { id: 'photometer', title: 'Photometers', component: shallowRef(NFEditPhotometer) }
    ]

  export default {
    name: 'EditNF',
    components: {
        CPFileUpload
    },
    props: {
        id: { type: String, default: '' },
        personalNf: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        readonly: { type: Boolean, default: false },
    },
    data () {
        return {
            nfId: this.id,
            nfExists: true,
            tabs: TABS,
            selectedTab: TABS[0].id,
            submissionStatus: undefined,
            upgradePlanFileId: undefined,
            upgradePlanFile: undefined,
            nfDisplayedStatusTypes: [],
            nfDisplayedStatus: undefined
        }
    },
    computed: {
        activeTab () {
            return this.tabs.find(({id}) => id == this.selectedTab) || TABS[0];
        },
        activeTabComponentProps () {
            return { id: this.nfId, disabled: this.disabled, readonly: !this.userCanEdit }

            /*
            // Can be done like this as well if different components would required
            // different props:
            if ( this.activeTab.component === NFEditInfo ) {
                return { id: this.nfId }
            } else if ( this.activeTab.component === NFEditLidar ) {
                return { id: this.nfId }
            } else if ( this.activeTab.component === NFEditPhotometer ) {
                return { id: this.nfId }
            }

            return {}
            */
        },
        userCanEdit () {
            return !this.disabled && !this.readonly && !this.submitted
        },
        userCanSubmitApplication () {
            return this.userCanEdit && this.personalNf
        },
        submitted () {
            try {
                switch ( this.submissionStatus.code ) {
                    case 'submitted':
                    case 'preliminary_report_issued':
                    case 'waiting_final_report':
                    case 'evaluated': {
                        return true
                    }
                    default: {
                        return false
                    }
                    
                }
            } catch {
                return false
            }
        },
        evaluated () {
            try {
                switch ( this.submissionStatus.code ) {
                    case 'preliminary_report_issued':
                    case 'waiting_final_report':
                    case 'evaluated': {
                        return true
                    }
                    default: {
                        return false
                    }
                    
                }
            } catch {
                return false
            }
        },
        shouldUploadUpgradePlan () {
            try {
                return this.submissionStatus.code == 'preliminary_report_issued'
            } catch {
                return false
            }
        },
        canUploadUpgradePlan () {
            try {
                switch ( this.submissionStatus.code ) {
                    case 'preliminary_report_issued':
                    case 'waiting_final_report':
                    case 'evaluated': {
                        return true
                    }
                    default: {
                        return false
                    }
                    
                }
            } catch {
                return false
            }
        },
        upgradePlanUploaded () {
            try {
                return !!this.upgradePlanFileId
            } catch {
                return false
            }
        }
    },
    methods: {
        ...mapActions('auth', ['isAdmin']),

        async getTypes () {
            api.types.loadDisplayedStatusTypes().then(response => {
                this.nfDisplayedStatusTypes = response.data.data
            })
        },
        async getNfDisplayedStatus () {
            if (!this.nfId) {
                return;
            }

            api.nf.getDisplayedStatus ( this.nfId ).then(response => {
                this.nfDisplayedStatus = response.data.data
            })
        },
        confirmNewDisplayedStatus () {
            console.log ("I am here!")
            this.$refs.displayedStatusModal.show()
        },
        submitDisplayedStatus () {
            api.nf.editGeneralInfo (this.nfId, {displayed_status: this.nfDisplayedStatus._id})
        },
        badgeColorFromStatusCode (code) {
            if (code === 'draft') {
                return 'gray'
            }

            if (code === 'submitted' || code === 'preliminary_report_issued' || code === 'waiting_final_report') {
                return 'warning'
            }

            if (code === 'evaluated') {
                return 'success'
            }

            return 'gray'
        },
        async getSubmissionStatus () {
            if ( this.nfId ) {
                api.nf.loadInitialAcceptanceStatus (this.nfId).then(async (response) => {
                    this.submissionStatus = response.data.data.status
                    
                    try {
                        this.upgradePlanFileId = response.data.data.user_file
                    } catch {
                        this.upgradePlanFileId = undefined
                    }
                })
            }
        },
        async onSubmitApplicationClick () {
            if ( this.nfId ) {
                await api.nf.submitInitialAcceptanceApplication ( this.nfId )
                this.getSubmissionStatus ()
            }
        },
        async checkNfId () {
            if (!this.id) {
                const response = await api.nf.loadNfIdForUser()

                if (!response.data.data.exists) {
                    this.nfExists = false
                    this.nfId = undefined
                    return
                }

                if (this.personalNf) {
                    this.nfId = response.data.data.id
                    this.nfExists = true
                }
            } else {
                this.nfId = this.id
            }
        },
        downloadPdf () {
            api.nf.downloadInitialAcceptanceSubmissionPdf ( this.nfId ).then(response => {
                const blob = new Blob([response.data], { type: response.headers['content-type'] })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = response.headers['content-disposition'].split('filename=')[1].split(';')[0];
                link.click()
                URL.revokeObjectURL(link.href)
            }).catch(console.error)
        },
        async uploadUpgradePlan () {
            try {
                let fileUploadResponse = await api.file.uploadFile (this.upgradePlanFile)

                try {
                    await api.nf.editInitialAcceptance (this.nfId, { user_file: fileUploadResponse.data.data.id })
                } catch {
                    await api.file.deleteFile ( fileUploadResponse.data.data.id )
                }


            } catch {
                console.log ("Error uploading upgrade plan!")
            }
        },
        async downloadUpgradePlan () {
            await api.file.downloadFile ( this.upgradePlanFileId )
        },
        downloadReport () {
            api.nf.downloadInitialAcceptanceEvaluationPdf ( this.nfId ).then(response => {
                const blob = new Blob([response.data], { type: response.headers['content-type'] })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = response.headers['content-disposition'].split('filename=')[1].split(';')[0];
                link.click()
                URL.revokeObjectURL(link.href)
            }).catch(console.error)
        },
        openUpgradePlanModal () {
            this.$refs.upgradePlanModal.show()
        }
    },
    async mounted() {
        await this.checkNfId()
        this.getSubmissionStatus()
        this.getTypes()
        this.getNfDisplayedStatus()
    },
    watch: {
        id () {
            this.checkNfId()
        }
    }
  }
</script>

<style lang="scss">
.va-badge .va-badge__text-wrapper .va-badge__text {
    font-size: 1rem;
}

$mobileBreakPointPX: 640px;
$tabletBreakPointPX: 768px;

.no_nf__wrapper {
    height: 100%;
}

.va-typography-block h1.no_nf__title {
    text-transform: uppercase;
    font-size: 6rem;
    line-height: 6.5rem;
    font-weight: 800;

    @media screen and (max-width: $mobileBreakPointPX) {
        font-size: 2.5rem;
        line-height: 3rem;
    }
}

.va-typography-block p.no_nf__description {
    font-size: 1.5rem;

    @media screen and (max-width: $mobileBreakPointPX) {
        font-size: 1.15rem;
    }
}
</style>