<template>
    <div class="layout fluid gutter--xl">
        <va-form ref="edit_photometer_system_form" @submit.prevent v-if="photometer">
            <div class="row">
                <span class="display-6">Photometer unique ID/Name</span>
            </div>
            <div class="row">
                <div class="flex xs12">
                    <va-input
                        v-model="photometer.name"
                        label="Photometer unique ID/Name"
                        :disabled="disabled"
                        :readonly="readonly"
                        style="width: 100%"
                        :rules="[rules.mandatory]"
                    />
                </div>
            </div>
            <div class="row">
                <span class="display-6 xs12 flex">Instrument pictures</span>
                <CPFileUpload
                    v-model="photometerPictures"
                    file-types="jpg,jpeg,png"
                    type="gallery"
                    maxFiles=1
                    dropzone
                    @file-added="uploadPicture"
                    @file-removed="removePicture"
                    class="flex xs12 fileUploadGallery"
                    uploadButtonText = "Select files from your computer"
                    :dropZoneText="galleryDropzoneText"
                    :disabled="readonly"
                    requireConfirmation
                    confirmationMessage="Are you sure you want to delete this picture? This operation is irreversible!"
                    okText="Yes"
                    cancelText="Cancel"
                >
                </CPFileUpload>
            </div>
            <div class="row">
                <span class="display-6">Instrument location</span>
            </div>
            <div class="row">
                <div class="flex xs12 sm4">
                    <va-input
                        type="number"
                        :step="0.0001"
                        manual-input
                        v-model="photometer.latitude"
                        label="Latitude"
                        :rules="[rules.mandatory]"
                        :disabled="disabled"
                        :readonly="readonly"
                    >
                        <template #appendInner>
                            °N
                        </template>
                    </va-input>
                </div>
                <div class="flex xs12 sm4">
                    <va-input
                        type="number"
                        :step="0.0001"
                        manual-input
                        v-model="photometer.longitude"
                        label="Longitude"
                        :rules="[rules.mandatory]"
                        :disabled="disabled"
                        :readonly="readonly"
                    >
                        <template #appendInner>
                            °E
                        </template>
                    </va-input>
                </div>
                <div class="flex xs12 sm4">
                    <va-input
                        type="number"
                        :step="0.0001"
                        manual-input
                        v-model="photometer.altitude"
                        label="Altitude"
                        :rules="[rules.mandatory]"
                        :disabled="disabled"
                        :readonly="readonly"
                    >
                        <template #appendInner>
                            m
                        </template>
                    </va-input>
                </div>
            </div>
            <div class="row">
                <span class="display-6">Instrument details</span>
            </div>
            <div class="row">
                <div class="flex xs12 sm4">
                    <va-select
                        :options="types.instrument_status"
                        label="Instrument Status"
                        v-model="photometer.status"
                        track-by="_id"
                        text-by="name"
                        :rules="[rules.mandatory]"
                        :disabled="disabled"
                        :readonly="readonly"
                    />
                </div>
                <div class="flex xs12 sm4">
                    <va-date-input
                        v-model="photometer.planned_operation_date"
                        label="Planned operation date"
                        manual-input
                        :disabled="disabled"
                        :readonly="readonly"
                    >
                        <template #appendInner>
                            <va-popover prevent-overflow message="Initial acceptance can be granted at max. 1 year before installation">
                                <va-icon name="info" />
                            </va-popover>
                        </template>
                    </va-date-input>
                </div>
                <div class="flex xs12 sm4">
                    <va-select
                        :options="types.instrument"
                        label="Instrument Type"
                        v-model="photometer.type"
                        track-by="_id"
                        text-by="name"
                        :rules="[rules.mandatory]"
                        :disabled="disabled"
                        :readonly="readonly"
                    />
                </div>
            </div>
            <div class="row">
                <div class="flex xs12 sm8">
                    <va-input
                        type="text"
                        label="Instrument Manufacturer"
                        v-model="photometer.manufacturer"
                        style="width: 100%"
                        :rules="[rules.mandatory]"
                        :disabled="disabled"
                        :readonly="readonly"
                    />
                </div>
                <div class="flex xs12 sm4">
                    <va-input
                        type="text"
                        label="Instrument Model"
                        v-model="photometer.instrument_model"
                        style="width: 100%"
                        :rules="[rules.mandatory]"
                        :disabled="disabled"
                        :readonly="readonly"
                    />
                </div>
            </div>
            <div class="row">
                <div class="flex xs12">
                <va-input
                    type="textarea"
                    label="Additional Information"
                    v-model="photometer.additional_info"
                    style="width: 100%"
                        :disabled="disabled"
                        :readonly="readonly"
                />
                </div>
            </div>
            <va-divider class="mt-4 mb-4" />
            <div class="row justify--center" v-if="!disabled && !readonly">
                <div class="flex">
                    <va-button type="submit" @click="submitAddPhotometerSystem()">Save photometer system to draft</va-button>
                </div>
            </div>
            <div class="row justify--center" v-else>
                <div class="flex">
                    <va-button type="submit" @click="$emit('hide')">Close</va-button>
                </div>
            </div>
        </va-form>
    </div>
</template>

<script>
import api from '@/lib/api'

import CPFileUpload from '@/components/ui/file_upload/CPFileUpload.vue'

export default {
    name: 'EditPhotometerSystemForm',
    components: {
        CPFileUpload
    },
    props: {
        readonly: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        photometerId: { type: String, default: undefined },
        data: {
            type: Object,
            default () {
                return {
                    latitude: .0,
                    longitude: .0,
                    altitude: .0,
                    status: undefined,
                    planned_operation_date: new Date(),
                    type: undefined,
                    manufacturer: '',
                    instrument_model: '',
                    additional_info: '',

                    pictures: [],
                    
                    general_compliance: undefined,
                    general_compliance_reviewer: undefined,

                    data_compliance: undefined,
                    data_compliance_comments: '',
                    data_compliance_recommendations: '',
                    data_compliance_reviewer: undefined,
                }
            }
        }
    },
    data: () => ({
        photometer: undefined,
        types: {
            instrument: [],
            instrument_status: [],
            instrument_location: [],
            instrument_additional_function: [],
            instrument_feature: []
        },
        rules: {
            mandatory: (v) => !!v || "Required",
        },
        photometerPictures: [],
        dbPictures: {}
    }),
    watch: {
        data(newData) {
            if (newData) {
                this.photometer = newData
            }
        }
    },
    computed: {
        galleryDropzoneText () {
            if ( this.readonly ) {
                return "Instrument pictures"
            }

            return "Here you can upload instrument pictures. You can drag and drop files or "
        }
    },
    methods: {
        async submitAddPhotometerSystem () {
            const formValid = await this.$refs.edit_photometer_system_form.validate()
            if ( !formValid ) {
                return;
            }

            this.$emit('save', this.photometer)
        },
        async loadDataTypes() {
            api.types.loadInstrumentTypes().then((response) => {
                this.types.instrument = response.data.data;
            })

            api.types.loadInstrumentStatusTypes().then((response) => {
                this.types.instrument_status = response.data.data;
            })

            api.types.loadInstrumentLocationTypes().then((response) => {
                this.types.instrument_location = response.data.data;
            })

            api.types.loadInstrumentAdditionalFunctionTypes().then((response) => {
                this.types.instrument_additional_function = response.data.data;
            })

            api.types.loadInstrumentFeatureTypes().then((response) => {
                this.types.instrument_feature = response.data.data;
            })
        },
        async loadPicturesData () {
            let pictures = await Promise.all (this.photometer.pictures.map (async picture => {
                return {
                    id: picture,
                    file: await api.file.getFile ( picture )
                }
            }))

            this.dbPictures = {}

            pictures.forEach (picture => {
                const file_obj = new File (
                    [picture.file.data],
                    picture.file.headers['content-disposition'].split('filename=')[1].split(';')[0],
                    { type: picture.file.data.type }
                )

                // Keep track of database IDs:
                this.dbPictures[ picture.id ] = file_obj
            })

            this.photometerPictures = Object.keys(this.dbPictures).map( id => this.dbPictures[id] )
        },
        async savePictures () {
            if ( !this.photometerId ) {
                return
            }

            await api.photometer.editLidarSystem (this.photometerId, {
                pictures: this.photometer.pictures
            })
        },
        async uploadPicture ( files ) {
            let dbObjects = await Promise.all (files.map( async file => {
                let fileUploadResponse = await api.file.uploadFile (file)

                return {
                    id: fileUploadResponse.data.data.id,
                    file: file
                }
            }))

            dbObjects.forEach (picture => {
                this.photometer.pictures.push ( picture.id )
                this.dbPictures[ picture.id ] = picture.file
            })

            // await this.savePictures ()
            // this.loadPicturesData ()
        },
        async removePicture ( file ) {
            const dbId = Object.keys(this.dbPictures).find(id => this.dbPictures[id] === file)

            await api.file.deleteFile ( dbId )

            delete ( this.dbPictures[dbId] )

            const idx = this.photometer.pictures.indexOf ( dbId )

            if (idx > -1) {
                this.photometer.pictures.splice ( idx, 1 )
                // this.savePictures ()
            }
            
        }
    },
    async mounted() {
        this.photometer = this.data
        await this.loadDataTypes()
        await this.loadPicturesData()
    }
}
</script>