import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
import axios from './plugins/axios'
import refreshAxiosInstance from './plugins/axios'

// import axios from 'axios'
import VueAxios from 'vue-axios'

import 'vuestic-ui/css'
import { createVuestic } from 'vuestic-ui'

import './public/css/overrides.scss'

router.beforeEach((to, from, next) => {
  // console.log(store)
  if (store.getters['auth/isLoggedIn'] && (to.path === '/login' || to.path === '/register')) {
    next({path: '/'})
  } else if (to.matched.some(record => record.meta.auth === true) && !store.getters['auth/isLoggedIn'] && to.path !== '/logout') {
    store.dispatch ('auth/logout')
    next({
      path: '/login',
      query: {
        from: to.path
      }
      // Redirect to original path if specified
    })
  } else {
    next()
  }
})

refreshAxiosInstance.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response.status == 401) {
    // Refresh token expired, make sure we log out the user and
    // present the login view
    store.dispatch ('auth/logout')
    router.push ({
      path: '/login',
      query: {
        from: router.currentRoute.path
      }
    })
  }

  return Promise.reject(error)
})

/*
axios.interceptors.response.use(function (response) {
  return response
}, function (error) {

  if (error.response.status === 401) {
    if (router.app._route.path !== '/login') {
      store.dispatch('auth/logout', {}, {root: true})
      router.push({path: '/login'})
    }
  }

  if ( error.response.status === 403 ) {
    router.push({path: '/404'})
  }
  
  if (error.response.status === 404) {
    router.push({path: '/404'})
  }
  return Promise.reject(error)
})

*/

const app = createApp ( App );

app
  .use (createVuestic({
    config: {
      colors: { primary: '#1F3864', secondary: '#DDDDDD', success: '#92D050' }
    }
  }))
  .use (router)
  .use (store)
  .use (VueAxios, axios)
  .mount('#app');

axios.interceptors.response.use(function (response) {
  if ( response.data?.message ) {
    if ( Number.isInteger(response.data.code) ) {
      const toastColor = response.data.code < 1000 ? 'success' : 'danger';

      app.config.globalProperties.$vaToast.init({
        message: response.data.message,
        color: toastColor,
        position: 'bottom-right'
      })
    }
  }

  return response
}, function (error) {
  if ( error.response.data.message ) {
    app.config.globalProperties.$vaToast.init({
      message: error.response.data.message,
      color: 'danger',
      position: 'bottom-right'
    })
  } else if ( error.response.status == 500 ) {
    app.config.globalProperties.$vaToast.init({
      message: "API Server Error!",
      color: 'danger',
      position: 'bottom-right'
    })
  } else if ( error.response.status == 403 ) {
    app.config.globalProperties.$vaToast.init({
      message: "Permission denied!",
      color: 'danger',
      position: 'bottom-right'
    })
  } else if ( error.response.status == 404 ) {
    app.config.globalProperties.$vaToast.init({
      message: "Resource not found!",
      color: 'danger',
      position: 'bottom-right'
    })
  }

  return Promise.reject(error)
})