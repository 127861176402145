<template>
    <div class="layout gutter--xs">
        <div class="row">
            <va-card-title>Channel compliance</va-card-title>
        </div>
        <div class="layout gutter--xl">
            <div class="row" v-if="suggestedCompliance">
                <div class="flex xs12">
                    <va-collapse stateful header="Channel compliance suggestions" :color="cardStripeColor(suggestedCompliance.compliance.code)">
                        <va-card outlined class="pb-1 va-typography-block">
                            <va-card-content>
                                <p v-if="suggestedCompliance.compliance.code === 'yes'">Fully compliant!</p>
                                <p v-else-if="suggestedCompliance.compliance.code === 'partially'">Partially compliant!</p>
                                <p v-else>Required criteria not met!</p>

                                <p class="strong" v-if="suggestedCompliance.code & complianceCodes.overall.NO_COMPLIANT_WAVELENGTH > 0">No wavelength fulfills the required channels criteria</p>
                                <p class="strong" v-if="suggestedCompliance.code & complianceCodes.overall.NO_FULLY_COMPLIANT_WAVELENGTH">No wavelength has fully compliant channels</p>
                                <va-card v-for="wavelength in suggestedCompliance.wavelengths" outlined class="mb-2" stripe :stripe-color=cardStripeColor(wavelength.compliance.code) :key="wavelength.name">
                                    <va-card-title>
                                        <span v-if="wavelength.compliance.code === 'yes'">{{wavelength.name}}nm channels are fully compliant</span>
                                        <span v-else-if="wavelength.compliance.code === 'partially'">{{wavelength.name}}nm channels are partially compliant</span>
                                        <span v-else>{{wavelength.name}}nm channels do not fulfill the required criteria</span>
                                    </va-card-title>
                                    <va-card-content>
                                        <p v-if="wavelength.code & complianceCodes.wavelength.NOT_ALL_CHANNELS_FULLY_COMPLIANT">One or more {{wavelength.name}}nm channels are not fully compliant:</p>
                                        <p v-if="wavelength.code & complianceCodes.wavelength.CHANNEL_NOT_COMPLIANT">One or more {{wavelength.name}}nm channels do not fulfill the required criteria:</p>
                                        <p v-if="wavelength.code & complianceCodes.wavelength.NO_POLARISATION">No depolarisation measurement capability at {{wavelength.name}}nm:</p>
                                        <ul class="va-unordered" v-for="channel_group in wavelength.channel_groups" :key="channel_group.name">
                                            <li :class="complianceListItemClass(channel_group.compliance.code)">
                                                <span v-if="channel_group.compliance.code === 'yes'">{{channel_group.name}} channels are fully compliant</span>
                                                <span v-else-if="channel_group.compliance.code === 'partially'">{{channel_group.name}} channels are partially compliant:</span>
                                                <span v-else>{{channel_group.name}} channels do not fulfill the required criteria:</span>
                                                <ul class="va-unordered">
                                                    <li :class="complianceListItemClass(channel_group.compliance.code)" v-if="channel_group.code & complianceCodes.group.NO_CHANNELS">No {{channel_group.name}} channels present</li>
                                                    <li :class="complianceListItemClass(channel_group.compliance.code)" v-if="channel_group.code & complianceCodes.group.HEIGHT_RESOLUTION">Height resolution criteria is not satisfied for {{channel_group.name}} channels</li>
                                                    <li :class="complianceListItemClass(channel_group.compliance.code)" v-if="channel_group.code & complianceCodes.group.TIME_RESOLUTION">Time resolution criteria is not satisfied for {{channel_group.name}} channels</li>
                                                    <li :class="complianceListItemClass(channel_group.compliance.code)" v-if="channel_group.code & complianceCodes.group.MAXIMUM_RANGE">Minimum altitude range is too low for {{channel_group.name}} channels</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </va-card-content>
                                </va-card>
                            </va-card-content>
                        </va-card>
                    </va-collapse>
                </div>
            </div>
            <div class="row" v-if="lidar">
                <div class="flex xs12">
                    <va-collapse stateful header="Channel information" color="primary">
                        <va-card outlined>
                            <va-card-content>
                                <div class="row justify--space-between"  v-for="category_name in Object.keys(types.lidar_channel_categories)" :key="category_name">
                                    <span class="display-6 xs12 flex">{{category_name}} Channels</span>
                                    <div class="flex xs12">
                                        <div class="row justify--space-between">
                                            <div class="flex xs12 sm4">
                                                <va-select
                                                    :options="instrumentChannelAvailabilityTypes"
                                                    label="Availability"
                                                    v-model="lidar.channels[category_name].availability"
                                                    track-by="_id"
                                                    text-by="name"
                                                    :disabled="disabled"
                                                    :readonly="true"
                                                />
                                            </div>
                                        </div>
                                        <div class="row justify--space-between mt-3 mb-3" v-if="channelAvailable(category_name)">
                                            <div class="flex xs12 sm4">
                                                <va-date-input
                                                    v-model="lidar.channels[category_name].operation_start_date"
                                                    label="Operation start date"
                                                    manual-input
                                                    :disabled="disabled"
                                                    :readonly="true"
                                                />
                                            </div>
                                            <div class="flex xs12 sm4">
                                                <va-input
                                                    type="number"
                                                    :step="0.01"
                                                    manual-input
                                                    v-model="lidar.channels[category_name].height_resolution"
                                                    label="Height Resolution"
                                                    :disabled="disabled"
                                                    :readonly="true"
                                                    :ref="registerForceUpdateElement"
                                                >
                                                    <template #appendInner>
                                                        m
                                                    </template>
                                                </va-input>
                                            </div>
                                            <div class="flex xs12 sm4">
                                                <va-input
                                                    type="number"
                                                    :step="1"
                                                    manual-input
                                                    v-model="lidar.channels[category_name].time_resolution"
                                                    label="Time Resolution"
                                                    :disabled="disabled"
                                                    :readonly="true"
                                                    :ref="registerForceUpdateElement"
                                                >
                                                    <template #appendInner>
                                                        s
                                                    </template>
                                                </va-input>
                                            </div>
                                            <div class="flex xs12 sm4">
                                                <va-input
                                                    type="number"
                                                    :step="0.01"
                                                    manual-input
                                                    v-model="lidar.channels[category_name].distance_full_overlap"
                                                    label="Full Overlap Distance"
                                                    :disabled="disabled"
                                                    :readonly="true"
                                                    :ref="registerForceUpdateElement"
                                                >
                                                    <template #appendInner>
                                                        m
                                                    </template>
                                                </va-input>
                                            </div>
                                            <div class="flex xs12 sm4">
                                                <va-input
                                                    type="number"
                                                    :step="0.01"
                                                    manual-input
                                                    v-model="lidar.channels[category_name].minimum_altitude_range"
                                                    label="Minimum Altitude Range"
                                                    :disabled="disabled"
                                                    :readonly="true"
                                                    :ref="registerForceUpdateElement"
                                                >
                                                    <template #appendInner>
                                                        m
                                                    </template>
                                                </va-input>
                                            </div>
                                            <div class="flex xs12">
                                                <va-select
                                                    :options="types.lidar_channel_categories[category_name]"
                                                    label="Channels"
                                                    v-model="lidar.channels[category_name].channels"
                                                    track-by="_id"
                                                    text-by="description"
                                                    multiple
                                                    style="width: 100%"
                                                    :disabled="disabled"
                                                    :readonly="true"
                                                >
                                                    <template #content="{value}">
                                                        {{value.map(channel => channel.short_name).join(', ')}}
                                                    </template>
                                                </va-select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </va-card-content>
                        </va-card>
                    </va-collapse>
                </div>
            </div>
        </div>
        <va-form ref="complianceForm" class="layout gutter--xl">
            <div class="row justify--center align--start">
                <div class="flex">
                    <va-select
                        label="Channel Compliance"
                        :options="complianceTypes"
                        track-by="_id"
                        text-by="name"
                        v-model="lidar.channel_compliance"
                        :rules="[rules.mandatory]"
                        :disabled="disabled"
                        :readonly="!reviewer"
                    >
                        <template #appendInner>
                            <va-popover prevent-overflow message="Criteria: at least 1 complete setup of channels (elastic + Raman + polarisation)  compliant or partially compliant">
                                <va-icon name="info" />
                            </va-popover>
                        </template>
                    </va-select>
                </div>
            </div>
            <div class="row justify--center align--center">
                <div class="flex xs12 sm6">
                    <va-input
                        label="Channel compliance comments"
                        type="textarea"
                        v-model="lidar.channel_compliance_comments"
                        :rules="[rules.mandatory]"
                        style="width: 100%"
                        messages="Required field"
                        :disabled="disabled"
                        :readonly="!reviewer"
                    />
                </div>
                <div class="flex xs12 sm6">
                    <va-input
                        label="Channel compliance recommendations"
                        type="textarea"
                        v-model="lidar.channel_compliance_recommendations"
                        :rules="[rules.mandatory]"
                        style="width: 100%"
                        messages="Required field"
                        :disabled="disabled"
                        :readonly="!reviewer"
                    />
                </div>
            </div>
            <div class="row justify--center align--center">
            </div>
        </va-form>
    </div>
</template>

<script>
import api from '@/lib/api'
import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'ChannelComplianceForm',
    props: {
        modelValue: {
            type: Object,
            default () {
                return {
                    channel_compliance: {
                        id: '',
                        name: '',
                        code: ''
                    },
                    channel_compliance_comments: '',
                    channel_compliance_recommendations: ''
                }
            }
        },
        disabled: {type: Boolean, default: false},
        reviewer: {type: Boolean, default: false}
    },
    data () {
        return {
            suggestedCompliance: undefined,
            types: {
                lidar_channel_categories: []
            },
            complianceCodes: {
                group: api.lidar.CHANNEL_GROUP_COMPLIANCE,
                wavelength: api.lidar.WAVELENGTH_COMPLIANCE,
                overall: api.lidar.CHANNEL_COMPLIANCE
            },
            rules: {
                mandatory: (v) => !!v || "Required"
            },
            forceUpdateElements: []
        }
    },
    computed: {
        ...mapGetters ( 'types', [ 'complianceTypes', 'instrumentChannelAvailabilityTypes' ]),
        lidar: {
            get () { return this.modelValue },
            set (value) {this.$emit('update:modelValue', value)}
        },
        lidarComplianceColor () {
            if ( !this.lidar.channel_compliance ) {
                return 'primary'
            }

            switch ( this.lidar.channel_compliance.code ) {
                case 'yes':
                    return 'success';
                case 'partially':
                    return 'warning';
                case 'no':
                default:
                    return 'danger';
            }
        }
    },
    watch: {
    },
    methods: {
        ...mapActions ( 'types', [ 'loadComplianceTypes', 'loadInstrumentChannelAvailabilityTypes' ]),
        validate () {
            return this.$refs.complianceForm.validate()
        },
        async loadTypes () {
            if ( !this.complianceTypes.length ) {
                this.loadComplianceTypes()
            }

            if ( !this.instrumentChannelAvailabilityTypes.length ) {
                this.loadInstrumentChannelAvailabilityTypes()
            }

            const response = await api.lidar.loadChannelCategories()
            this.types.lidar_channel_categories = []

            response.data.data.forEach ( category => {
                this.types.lidar_channel_categories[category.name] = category.channels;


                if ( !this.lidar.channels[ category.name ] ) {
                    this.lidar.channels[category.name] = {
                        channels: []
                    };
                }
            })
        },
        loadSuggestedCompliance () {
            api.lidar.loadSuggestedChannelCompliance(this.lidar._id).then(response => {
                this.suggestedCompliance = response.data.data
            })
        },
        cardStripeColor(code) {
            if ( code === 'yes' ) {
                return 'success'
            }

            if ( code === 'partially' ) {
                return 'warning'
            }

            return 'danger'
        },
        complianceListItemClass(code) {
            if ( code === 'yes' ) {
                return 'compliance_list_item__green'
            }

            if ( code === 'partially' ) {
                return 'compliance_list_item__yellow'
            }

            return 'compliance_list_item__red'
        },
        channelAvailable (channelName) {
            try {
                return this.lidar.channels [ channelName ] .availability.code !== 'no';
            } catch {
                return false
            }
        },
        registerForceUpdateElement (el) {
            this.forceUpdateElements.push(el)
        }
    },
    async mounted () {
        this.loadComplianceTypes ()
        this.loadTypes ()
        this.loadSuggestedCompliance ()
    },
    beforeUpdate () {
        // Force refresh of text input fields
        this.forceUpdateElements.forEach(ref => {
            ref.$forceUpdate()
        })
    }
}
</script>