import api from '@/lib/api'

const state = {
    instrument: [],
    instrument_status: [],
    instrument_location: [],
    instrument_additional_function: [],
    instrument_feature: [],
    instrument_channel_availability: [],
    compliance: [],
    acceptance: []
}

const getters = {
    instrumentTypes(state) {
        return state.instrument
    },
    instrumentStatusTypes(state) {
        return state.instrument_status
    },
    instrumentLocationTypes(state) {
        return state.instrument_location
    },
    instrumentAdditionalFunctionTypes(state) {
        return state.instrument_additional_function
    },
    instrumentFeatureTypes(state) {
        return state.instrument_feature
    },
    instrumentChannelAvailabilityTypes(state) {
        return state.instrument_channel_availability
    },
    complianceTypes(state) {
        return state.compliance
    },
    acceptanceTypes(state) {
        return state.acceptance
    }
}

const actions = {
    loadInstrumentTypes({commit}) {
        api.types.loadInstrumentTypes ().then(response => {
            commit('SET_INSTRUMENT', response.data.data)
        })
    },
    loadInstrumentStatusTypes({commit}) {
        api.types.loadInstrumentStatusTypes ().then(response => {
            commit('SET_INSTRUMENT_STATUS', response.data.data)
        })
    },
    loadInstrumentLocationTypes({commit}) {
        api.types.loadInstrumentLocationTypes ().then(response => {
            commit('SET_INSTRUMENT_LOCATION', response.data.data)
        })
    },
    loadInstrumentAdditionalFunctionTypes({commit}) {
        api.types.loadInstrumentAdditionalFunctionTypes ().then(response => {
            commit('SET_INSTRUMENT_ADDITIONAL_FUNCTION', response.data.data)
        })
    },
    loadInstrumentFeatureTypes({commit}) {
        api.types.loadInstrumentFeatureTypes ().then(response => {
            commit('SET_INSTRUMENT_FEATURE', response.data.data)
        })
    },
    loadInstrumentChannelAvailabilityTypes({commit}) {
        api.types.loadInstrumentTypes ().then(response => {
            commit('SET_INSTRUMENT_CHANNEL_AVAILABILITY', response.data.data)
        })
    },
    loadComplianceTypes({commit}) {
        api.types.loadComplianceTypes ().then(response => {
            commit('SET_COMPLIANCE', response.data.data)
        })
    },
    loadAcceptanceTypes({commit}) {
        api.types.loadAcceptanceTypes ().then(response => {
            commit('SET_ACCEPTANCE', response.data.data)
        })
    }
}

// mutations
const mutations = {
    SET_INSTRUMENT(state, payload) {
        state.instrument = payload
    },
    SET_INSTRUMENT_STATUS(state, payload) {
        state.instrument_status = payload
    },
    SET_INSTRUMENT_LOCATION(state, payload) {
        state.instrument_location = payload
    },
    SET_INSTRUMENT_ADDITIONAL_FUNCTION(state, payload) {
        state.instrument_additional_function = payload
    },
    SET_INSTRUMENT_FEATURE(state, payload) {
        state.instrument_feature = payload
    },
    SET_INSTRUMENT_CHANNEL_AVAILABILITY(state, payload) {
        state.instrument_channel_availability = payload
    },
    SET_COMPLIANCE(state, payload) {
        state.compliance = payload
    },
    SET_ACCEPTANCE(state, payload) {
        state.acceptance = payload
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}