<template>
    <span class="reviewers-form">
        <va-button
            :label="label"
            @click="showModal"
            :size="size"
            :color="color"
            icon-right="arrow_drop_down"
        >{{label}}</va-button>
        <va-modal v-model="reviewersModal" hide-default-actions no-padding size="small">
            <div class="layout fluid gutter--xs">
                <div class="row">
                    <va-card-title>Choose reviewers</va-card-title>
                </div>
                <va-inner-loading :loading="loading">
                    <va-form ref="reviewerForm" class="layout fluid gutter--xl">
                        <div class="row justify--center align--start">
                            <div class="flex">
                                <va-select
                                    prevent-overflow
                                    label="Lidar reviewer"
                                    :options="lidarReviewers"
                                    :text-by="username"
                                    track-by="_id"
                                    v-model="reviewers.lidar"
                                    searchable
                                    no-options-text="No suitable users found"
                                />
                            </div>
                            <div class="flex">
                                <va-select
                                    prevent-overflow
                                    label="Lidar data reviewer"
                                    :options="lidarDataReviewers"
                                    :text-by="username"
                                    track-by="_id"
                                    v-model="reviewers.lidar_data"
                                    searchable
                                    no-options-text="No suitable users found"
                                />
                            </div>
                            <div class="flex">
                                <va-select
                                    prevent-overflow
                                    label="Photometer reviewer"
                                    :options="photometerReviewers"
                                    :text-by="username"
                                    track-by="_id"
                                    v-model="reviewers.photometer"
                                    searchable
                                    no-options-text="No suitable users found"
                                />
                            </div>
                            <div class="flex">
                                <va-select
                                    prevent-overflow
                                    label="Photometer data reviewer"
                                    :options="photometerDataReviewers"
                                    :text-by="username"
                                    track-by="_id"
                                    v-model="reviewers.photometer_data"
                                    searchable
                                    no-options-text="No suitable users found"
                                />
                            </div>
                        </div>
                        <div class="row justify--center">
                            <div class="flex">
                                <va-button flat @click="hideModal">Cancel</va-button>
                                <va-button icon="check" @click="saveReviewers">Save reviewers</va-button>
                            </div>
                        </div>
                    </va-form>
                </va-inner-loading>
            </div>
        </va-modal>
    </span>
</template>

<script>
import api from '@/lib/api'

const ROLE_LIDAR_REVIEWER = "Lidar Reviewer"
const ROLE_LIDAR_DATA_REVIEWER = "Lidar Data Reviewer"
const ROLE_PHOTOMETER_REVIEWER = "Photometer Reviewer"
const ROLE_PHOTOMETER_DATA_REVIEWER = "Photometer Data Reviewer"

export default {
    name: 'ReviewersPicker',
    props: {
        id: { type: String, default: '' },
        label: { type: String, default: '' },
        size: {type: String, default: 'medium'},
        color: {type: String, default: 'primary'},
        availableLidarReviewers: {type: Array, default: undefined},
        availableLidarDataReviewers: {type: Array, default: undefined},
        availablePhotometerReviewers: {type: Array, default: undefined},
        availablePhotometerDataReviewers: {type: Array, default: undefined},
    },
    data () {
        return {
            lidarReviewers: [],
            lidarDataReviewers: [],
            photometerReviewers: [],
            photometerDataReviewers: [],
            reviewers: {
                lidar: undefined,
                lidar_data: undefined,
                photometer: undefined,
                photometer_data: undefined
            },
            reviewersModal: false,
            loading: false
        }
    },
    watch: {
        id () {
            this.loadReviewers()
        }
    },
    methods: {
        async showModal() {
            this.loading = true;
            this.reviewersModal = true;
            await this.loadUsers();
            await this.loadReviewers();
            this.loading = false;
        },
        hideModal() {
            this.reviewersModal = false;
        },
        async loadUsers () {
            let apiCalls = []

            if (this.availableLidarReviewers === undefined) {
                apiCalls.push(
                    api.user.loadUsersWithRoleName (ROLE_LIDAR_REVIEWER).then(response => {
                        this.lidarReviewers = response.data.data
                    })
                )
            } else { this.lidarReviewers = this.availableLidarReviewers }

            if (this.availableLidarDataReviewers === undefined) {
                apiCalls.push(
                    api.user.loadUsersWithRoleName (ROLE_LIDAR_DATA_REVIEWER).then(response => {
                        this.lidarDataReviewers = response.data.data
                    })
                )
            } else { this.lidarDataReviewers = this.availableLidarDataReviewers }

            if (this.availablePhotometerReviewers === undefined) {
                apiCalls.push(
                    api.user.loadUsersWithRoleName (ROLE_PHOTOMETER_REVIEWER).then(response => {
                        this.photometerReviewers = response.data.data
                    })
                )
            } else { this.photometerReviewers = this.availablePhotometerReviewers }

            if (this.availablePhotometerDataReviewers === undefined) {
                apiCalls.push(
                    api.user.loadUsersWithRoleName (ROLE_PHOTOMETER_DATA_REVIEWER).then(response => {
                        this.photometerDataReviewers = response.data.data
                    })
                )
            } else { this.photometerDataReviewers = this.availablePhotometerDataReviewers }

            console.log(apiCalls.length)

            if (apiCalls.length) {
                await Promise.all (apiCalls)
            }
        },
        async loadReviewers () {
            if ( !this.id ) {
                this.reviewers = {
                    lidar: undefined,
                    lidar_data: undefined,
                    photometer: undefined,
                    photometer_data: undefined
                }

                return
            }

            await api.nf.getInitialAcceptanceReviewers ( this.id ).then(response => {
                this.reviewers = response.data.data
            })
        },
        async saveReviewers () {
            await api.nf.setInitialAcceptanceReviewers (this.id, this.reviewers)

            this.hideModal();
        },
        username (user) {
            try {
                return user.first_name + ' ' + user.last_name
            } catch {
                return "UNKNOWN USER"
            }
        }
    },
    async mounted () {
    }
}
</script>