<template>
    <div class="layout gutter--xl">
        <div class="row">
            <div class="flex xs12" v-if="lidar">
                <span class="display-2">{{lidar.name}}</span>
            </div>
        </div>
        <template v-if="lidar">
            <div class="row">
                <div class="flex xs12 md6">
                    <div>
                        <va-image :src="picture" v-if="picture" height="200" contain/>
                    </div>
                </div>
                <div class="flex xs12 md6">
                    <p class="mb-2"><strong>Instrument name:</strong> {{lidar.name}}</p>
                    <p class="mb-2"><strong>Latitude:</strong> {{lidar.latitude}} °N</p>
                    <p class="mb-2"><strong>Longitude:</strong> {{lidar.longitude}} °E</p>
                    <p class="mb-2"><strong>Altitude:</strong> {{lidar.altitude}} m</p>
                    <p class="mb-2"><strong>Status:</strong> {{lidar.status.name}}</p>
                    <p class="mb-2" v-if="lidar.status.code==='operational'"><strong>Operational since:</strong> {{lidar.planned_operation_date}}</p>
                    <p class="mb-2" v-else><strong>Planned operation date:</strong> {{lidar.planned_operation_date}}</p>
                    <p class="mb-2"><strong>Type:</strong> {{lidar.type.name}}</p>
                    <p class="mb-2"><strong>Manufacturer:</strong> {{lidar.manufacturer}}</p>
                    <p class="mb-2"><strong>Location:</strong> {{lidar.location.name}}</p>
                    <p class="mb-2"><strong>Additional information:</strong> {{lidar.additional_info}}</p>
                </div>
            </div>
            <div class="row">
                <div class="flex xs12">
                    <va-card>
                        <va-card-title>Channels</va-card-title>
                        <va-card-content>
                            <va-accordion stateful>
                                <va-collapse
                                    v-for="(channel_type, channel_type_name, index) in lidar.channels"
                                    :header="channel_type_name"
                                    :key="index"
                                >
                                    <div>
                                        <div class="row mt-2 mb-4">
                                            <div class="flex xs12 md6">
                                                <p class="mb-2 display-6">Information:</p>
                                                <p class="mb-2"><strong>Availability:</strong> {{channel_type.availability.name}}</p>
                                                <p class="mb-2" v-if="channel_type.availability.code === 'existing'"><strong>Operation start date:</strong> {{channel_type.operation_start_date}}</p>
                                                <p class="mb-2" v-else-if="channel_type.availability.code === 'planned'"><strong>Operation start date:</strong> {{channel_type.operation_start_date}}</p>
                                                <p class="mb-2"><strong>Height resolution (m):</strong> {{channel_type.height_resolution}}</p>
                                                <p class="mb-2"><strong>Time resolution (s):</strong> {{channel_type.time_resolution}}</p>
                                                <p class="mb-2"><strong>Full overlap distance (m):</strong> {{channel_type.distance_full_overlap}}</p>
                                                <p class="mb-2"><strong>Minimum altitude range (m):</strong> {{channel_type.minimum_altitude_range}}</p>
                                            </div>
                                            <div class="flex xs12 md6">
                                                <p class="mb-2 display-6">Channel types:</p>
                                                <va-chip class="ma-2" v-for="(channel, index) in channel_type.channels" :key="index">{{channel.description}}</va-chip>

                                                <va-accordion stateful v-if="false">
                                                    <va-collapse
                                                        v-for="(channel, index) in channel_type.channels"
                                                        :header="channel.description"
                                                        :key="index"
                                                    >
                                                        <p class="mb2"><strong>Emission wavelength (nm):</strong> {{channel.emission_wavelength}}</p>
                                                        <p class="mb2"><strong>Detection wavelength (nm):</strong> {{channel.detection_wavelength}}</p>
                                                        <p class="mb2"><strong>Channel type:</strong> {{channel.channel_type}}</p>
                                                        <p class="mb2"><strong>Polarisation type:</strong> {{channel.polarisation_type}}</p>
                                                        <p class="mb2"><strong>Detection type:</strong> {{channel.detection_type}}</p>
                                                    </va-collapse>
                                                </va-accordion>
                                            </div>
                                        </div>
                                    </div>
                                </va-collapse>
                            </va-accordion>
                        </va-card-content>
                    </va-card>
                </div>
            </div>
            <div class="row">
                <div class="flex xs12 md6">
                    <va-card>
                        <va-card-title>Additional functions</va-card-title>
                        <va-card-content>
                            <va-chip class="ma-2" :color="functionToColor(lidar.laser_alignment)">Laser alignment: {{lidar.laser_alignment.name}}</va-chip>
                            <va-chip class="ma-2" :color="functionToColor(lidar.polarisation_calibration)">Polarisation calibration: {{lidar.polarisation_calibration.name}}</va-chip>
                            <va-chip class="ma-2" :color="functionToColor(lidar.telecover_measurement)">Telecover measurement: {{lidar.telecover_measurement.name}}</va-chip>
                            <va-chip class="ma-2" :color="functionToColor(lidar.dark_signal_measurement)">Dark signal measurement: {{lidar.dark_signal_measurement.name}}</va-chip>
                        </va-card-content>
                    </va-card>
                </div>
                <div class="flex xs12 md6">
                    <va-card>
                        <va-card-title>Features</va-card-title>
                        <va-card-content>
                            <va-chip class="ma-2" :color="featureToColor(lidar.photodetector_eye_piece)">Photodetector eye piece: {{lidar.photodetector_eye_piece.name}}</va-chip>
                            <va-chip class="ma-2" :color="featureToColor(lidar.pretrigger_20us)">Pretrigger ≥20 µs: {{lidar.pretrigger_20us.name}}</va-chip>
                            <va-chip class="ma-2" :color="featureToColor(lidar.t_h_control)">Temperature and Humidity control: {{lidar.t_h_control.name}}</va-chip>
                            <va-chip class="ma-2" :color="featureToColor(lidar.lidar_status_control)">Lidar status control: {{lidar.lidar_status_control.name}}</va-chip>
                        </va-card-content>
                    </va-card>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import api from '@/lib/api'


export default {
    name: 'ViewLidar',
    components: {
    },
    props: {
        id: { type: String, default () {''} }
    },
    data: () => ({
        lidar: undefined,
        picture: undefined
    }),
    methods: {
        functionToColor (f) {
            if ( f.code === 'auto' || f.code === 'manual' ) {
                return 'success';
            }

            if ( f.code === 'planned' ) {
                return 'warning';
            }

            return 'danger';
        },
        featureToColor (f) {
            if ( f.code === 'existing' ) {
                return 'success';
            }

            if ( f.code === 'planned' ) {
                return 'warning';
            }

            return 'danger';
        },
        async loadData () {
            api.lidar.getLidarSystem(this.id).then((response) => {
                this.lidar = response.data.data

                if (this.lidar.pictures.length) {
                    console.log (this.lidar.pictures[0])

                    this.picture = api.file.fileURL ( this.lidar.pictures[0] )

                    console.log (this.picture)
                } else {
                    this.picture = undefined
                }

                console.log (this.lidar)
            }).catch (() => {
                this.lidar = undefined
                this.picture = undefined
            })
        },
    },
    mounted () {
        this.loadData ()
    }
}
</script>