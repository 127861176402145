<template>
    <div class="layout gutter--xl" v-if="isAdministrator">
        <div class="row justify--center mb-4">
            <h1 class="display-1">Email System</h1>
        </div>
        <div class="row justify--center">
        </div>
        <div class="row">
            <va-input v-model="tableFilter" placeholder="Search..." class="flex xs12 md3" label="Search emails" @update:modelValue="updateTableDelayed" />
        </div>
        <div class="row">
            <div class="flex xs12">
                <va-data-table
                    :items="tableRows"
                    :loading="tableLoading"
                    :columns="tableColumns"
                >
                    <template #cell(actions)="{rowData}">
                        <va-button icon="visibility" class="mr-2" @click="showEmail(rowData)" />
                    </template>
                    <template #cell(recipients)="{rowData}">
                        {{ rowData.recipients.join(', ') }}
                    </template>
                    <template #cell(sent)="{rowData}">
                        <span v-if="rowData.sent">Sent</span>
                        <span v-else color="danger">Not sent</span>
                    </template>
                    <template #bodyAppend>
                        <tr>
                            <td :colspan="tableColumns.length">
                                <va-pagination
                                    class="flex"
                                    v-model="tablePage"
                                    :pages="tablePageCount"
                                    @update:modelValue="updateTable"
                                    :visible-pages="5"
                                />
                            </td>
                        </tr>
                    </template>
                </va-data-table>
            </div>
            <va-modal
                stateful
                ref="emailModal"
                hideDefaultActions
                closeButton
                title="View email details"
                fixed-layout
            >
                <template #header>
                    <p class="mb-1">
                        <span class="text--secondary">Subject: </span><strong>{{email.subject}}</strong>
                    </p>
                    <p class="mb-1">
                        <span class="text--secondary">Recipients: </span><strong>{{ email.recipients.join(', ')}}</strong>
                    </p>
                    <p class="mb-4">
                        <span class="text--secondary">Status: </span><strong v-if="email.sent">Sent</strong><strong v-else>Not sent</strong>
                    </p>
                </template>
                <va-card-content v-html=email.html>
                </va-card-content>
            </va-modal>
        </div>
    </div>
</template>

<script>
import api from '@/lib/api'
import {mapActions} from 'vuex'

  export default {
    name: 'AdminEditNFs',
    components: {},
    data: () => {
        return {
            isAdministrator: false,
            email: {},
            tableUpdateTimer: '',
            tableRefreshTimer: '',
            tableLoading: true,
            tableFilter: '',
            tableRows: [],
            tableRowsPerPage: 10,
            tablePage: 1,
            availableRowsCount: 0,
            tableColumns: [
                {
                    key: 'actions',
                    label: 'Actions'
                },
                {
                    key: 'date',
                    label: 'Date',
                }, {
                    key: 'subject',
                    label: 'Subject'
                }, {
                    key: 'recipients',
                    label: 'Recipients'
                }, {
                    key: 'sent',
                    label: 'Sent'
                }
            ]
        }
    },
    computed: {
        tablePageCount() {
            return this.tableRowsPerPage ? Math.ceil(this.availableRowsCount / this.tableRowsPerPage) : 1
        }
    },
    methods: {
        ...mapActions('auth', ['isAdmin']),

        updateTableDelayed () {
            clearTimeout(this.tableUpdateTimer)

            const delay = 0.5*1000
            this.tableUpdateTimer = setTimeout(this.updateTable, delay)
        },
        async updateTable () {
            this.tableLoading = true

            const response = await api.email.loadEmails ({
                start: (this.tablePage-1)*this.tableRowsPerPage,
                count: this.tableRowsPerPage,
                search: this.tableFilter
            })
            
            this.tableRows = response.data?.data?.emails
            this.availableRowsCount = response.data?.data?.total_count

            this.tableLoading = false
        },
        showEmail (email) {
            this.email = email

            this.$refs.emailModal.show()
        }
    },
    async mounted() {
        this.isAdministrator = await this.isAdmin()

        if (!this.isAdministrator) {
            this.$router.push({name: '404'})
        }

        await this.updateTable()
        this.tableRefreshTimer = setInterval(this.updateTable, 60*1000)
    },
    beforeUnmount() {
        clearInterval(this.tableRefreshTimer)
    }
  }
</script>