async function loadInstrumentTypes () {
    return this.api({
        url: `/types/instrument`,
        method: 'GET'
    })
}

async function loadInstrumentStatusTypes () {
    return this.api({
        url: `/types/instrument/status`,
        method: 'GET'
    })
}

async function loadInstrumentLocationTypes () {
    return this.api({
        url: `/types/instrument/location`,
        method: 'GET'
    })
}

async function loadInstrumentAdditionalFunctionTypes () {
    return this.api({
        url: `/types/instrument/additional-function`,
        method: 'GET'
    })
}

async function loadInstrumentFeatureTypes () {
    return this.api({
        url: `/types/instrument/feature`,
        method: 'GET'
    })
}

async function loadInstrumentChannelAvailabilityTypes () {
    return this.api({
        url: `/types/instrument/channel-availability`,
        method: 'GET'
    })
}

async function loadComplianceTypes () {
    return this.api({
        url: `/types/compliance`,
        method: 'GET'
    })
}

async function loadAcceptanceTypes () {
    return this.api({
        url: `/types/acceptance`,
        method: 'GET'
    })
}

async function loadDisplayedStatusTypes () {
    return this.api({
        url: `/types/displayed-status`,
        method: 'GET'
    })
}

async function loadNfTypes () {
    return this.api({
        url: `/types/nf-types`,
        method: 'GET'
    })
}
 
 export default {
     pluginName: 'types',
     methods: {
        loadInstrumentTypes: loadInstrumentTypes,
        loadInstrumentStatusTypes: loadInstrumentStatusTypes,
        loadInstrumentLocationTypes: loadInstrumentLocationTypes,
        loadInstrumentAdditionalFunctionTypes: loadInstrumentAdditionalFunctionTypes,
        loadInstrumentFeatureTypes: loadInstrumentFeatureTypes,
        loadInstrumentChannelAvailabilityTypes: loadInstrumentChannelAvailabilityTypes,
        loadComplianceTypes: loadComplianceTypes,
        loadAcceptanceTypes: loadAcceptanceTypes,
        loadDisplayedStatusTypes: loadDisplayedStatusTypes,
        loadNfTypes: loadNfTypes
     },
     apiMessages: {
         0: 'Operation successful!',
         1: 'Information loaded successfully!',
         2: 'Information saved successfully!',
         1000: 'Unknown error!',
         1001: 'Permission denied!',
         1002: 'Could not load information!',
         1003: 'Could not save information!',
         1004: 'Could not register National Facility!'
     }
 }