<template>
    <va-accordion v-model="accordionValue" multiply>
      <va-collapse
        :class="{ expanded: accordionValue[i] && item.children }"
          v-for="(item, i) in filteredRoutes"
          :key="i"
      >
        <template #header>
          <va-sidebar-item
            :active="isEntryActive(item)"
            :to="item.children && item.children.length ? undefined : {name: item.route, params: item.params}"
          >
            <va-sidebar-item-content>
              <template v-if="item.badge">
                <va-badge overlap dot>
                  <va-icon :name="item.icon" v-if="item.icon" />
                </va-badge>
              </template>
              <template v-else>
                <va-icon :name="item.icon" v-if="item.icon" />
              </template>
              
              <va-sidebar-item-title>{{ item.title }}</va-sidebar-item-title>
              <va-icon v-if="item.children" :name="accordionValue[i] ? 'expand_less' : 'expand_more'" />
            </va-sidebar-item-content>
          </va-sidebar-item>
        </template>
        <template v-if="userHasAccess(item)">
          <va-sidebar-item
            v-for="(child, idx) in filteredChildren(item)" :key="idx"
            :active="isEntryActive(child)"
            :to="{name: child.route, params: child.params}"
            class="pl-4"
          >
            <va-sidebar-item-content>
              <va-icon :name="child.icon" v-if="child.icon" />
              <va-sidebar-item-title>{{ child.title }}</va-sidebar-item-title>
            </va-sidebar-item-content>
          </va-sidebar-item>
        </template>
      </va-collapse>
    </va-accordion>
</template>

<script>
  import {mapGetters} from 'vuex'

  export default {
    name: 'MenuAccordion',
    props: {
        routes: Array
    },
    data: () => {
        return {
            accordionValue: []
        }
    },
    computed: {
      ...mapGetters('auth', ['isLoggedIn', 'userinfo']),

      routeName() {
        return this.$route.name;
      },
      filteredRoutes () {
        return this.routes.filter(route => this.userHasAccess(route))
      }
    },
    methods: {
      userHasAnyPermission ( permissions ) {
        if (permissions.length === 0) {
          return true
        }

        if (!this.isLoggedIn) {
          return false
        }

        if (this.userinfo.permissions.indexOf('administrator') !== -1) {
          return true
        }

        return permissions.filter(rp => this.userinfo.permissions.indexOf(rp) !== -1).length > 0
      },
      userHasAccess ( menuEntry ) {
        let userHasAccessToChildren = false
        let userHasAccessToParent = false

        if ( menuEntry.children && menuEntry.children.length ) {
          userHasAccessToChildren = menuEntry.children.filter ( child => {
            return child.permissions ? this.userHasAnyPermission(child.permissions) : true
          }).length > 0
        }

        if ( menuEntry.permissions ) {
          userHasAccessToParent = this.userHasAnyPermission ( menuEntry.permissions )
        } else if (!menuEntry.children) {
          // This entry has no children and no permissions
          userHasAccessToParent = true
        }

        return (userHasAccessToParent || userHasAccessToChildren)
      },
      isEntryActive ( menuEntry ) {
        if ( !menuEntry.children || menuEntry.children < 1 ) {
          return ( this.$route.name === menuEntry.route );
        }

        return menuEntry.children.filter ( c => c.route === this.$route.name ).length > 0
      },
      filteredChildren (route) {
        if (!route) {
          return []
        }

        if (!route.children) {
          return []
        }

        return route.children.filter(child => this.userHasAccess(child))
      }
    },
    async mounted() {
      this.accordionValue = this.routes.map ( item => {
        return this.isEntryActive ( item )
      })
    }
  }
</script>

<style lang="scss">
.app_sidebar {
  height: 100%;
  position: relative;
}

.va-sidebar__item {
  cursor: pointer;
}
</style>