<template>
  <va-sidebar
    color="dark"
    gradient
    v-if="isLoggedIn"
    class="app__sidebar"
    :width="width"
    :minimized="minimized"
    :minimized-width="minimizedWidth"
    :animated="true"
  >
    <MenuMinimized v-if="minimized" :routes="routes" />
    <MenuAccordion v-else :routes="routes" />
  </va-sidebar>
</template>

<script>
  import MenuAccordion from './menu/MenuAccordion.vue'
  import MenuMinimized from './menu/MenuMinimized.vue'
  import NavigationRoutes from './NavigationRoutes.js'

  import {mapGetters} from 'vuex'

  export default {
    name: 'SidebarMenu',
    components: {
        MenuAccordion,
        MenuMinimized
    },
    props: {
      width: String,
      minimizedWidth: String,
      minimized: Boolean
    },
    data: () => {
        return {
            routes: NavigationRoutes.routes
        }
    },
    computed: {
      ...mapGetters('auth', ['isLoggedIn'])
    }
  }
</script>

<style lang="scss">
.va-sidebar__item {
  cursor: pointer;
}
  .va-sidebar {
    &__menu {
      padding: 0 0;
    }
    &-item {
      &__icon {
        width: 1.5rem;
        height: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
</style>