async function loadEmails ( options ) {
    return this.api({
        url: `/emails`,
        method: 'GET',
        params: {
            start: options.start,
            count: options.count,
            search: options.search
        }
    })
}
export default {
    pluginName: 'email',
    methods: {
        loadEmails
    }
}