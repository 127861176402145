<template>
    <div class="layout gutter--xs">
        <div class="row">
            <div class="flex xs12">
                <va-data-table
                    :items="lidars"
                    :columns="lidarColumns"
                    striped
                >
                    <template #cell(system)="{rowData}">
                        <va-button icon="visibility" size="small" class="mr-2" @click="viewLidarSystem(rowData)" />{{rowData.name}}
                    </template>
                    <template #cell(feasibility)="{rowData}">
                        <va-chip
                            square
                            :color="complianceColor(rowData.feasibility_compliance)"
                            :icon="userCanEdit(rowData, 'feasibility') ? 'edit' : 'visibility'"
                            size="small"
                            @click="editFeasibilityCompliance (rowData)"
                        >
                            {{complianceName(rowData.feasibility_compliance)}}
                        </va-chip>
                    </template>
                    <template #cell(feature)="{rowData}">
                        <va-chip
                            square
                            :color="complianceColor(rowData.feature_compliance)"
                            :icon="userCanEdit(rowData, 'feature') ? 'edit' : 'visibility'"
                            size="small"
                            @click="editFeatureCompliance (rowData)"
                        >
                            {{complianceName(rowData.feature_compliance)}}
                        </va-chip>
                    </template>
                    <template #cell(channel)="{rowData}">
                        <va-chip
                            square
                            :color="complianceColor(rowData.channel_compliance)"
                            :icon="userCanEdit(rowData, 'channel') ? 'edit' : 'visibility'"
                            size="small"
                            @click="editChannelCompliance (rowData)"
                        >
                            {{complianceName(rowData.channel_compliance)}}
                        </va-chip>
                    </template>
                    <template #cell(general)="{rowData}">
                        <va-chip
                            square
                            :color="complianceColor(rowData.general_compliance)"
                            :icon="userCanEdit(rowData, 'general') ? 'edit' : 'visibility'"
                            size="small" @click="editGeneralCompliance (rowData)"
                        >
                            {{complianceName(rowData.general_compliance)}}
                        </va-chip>
                    </template>
                    <template #cell(data)="{rowData}">
                        <va-chip
                            square
                            :color="complianceColor(rowData.data_compliance)"
                            :icon="userCanEdit(rowData, 'data') ? 'edit' : 'visibility'"
                            size="small"
                            @click="editDataCompliance (rowData)"
                        >
                            {{complianceName(rowData.data_compliance)}}
                        </va-chip>
                    </template>
                </va-data-table>
            </div>
        </div>
        <va-modal stateful ref="viewLidarSystemModal" hide-default-actions no-padding>
            <EditLidarSystemForm :data="selectedLidar" readonly @hide="$refs.viewLidarSystemModal.hide()" />
        </va-modal>
        <va-modal stateful ref="feasibilityComplianceModal" hide-default-actions>
            <FeasibilityComplianceForm v-model="selectedLidar" ref="feasibilityComplianceForm" :disabled="disabled" :reviewer="userCanEdit(selectedLidar, 'feasibility')" />

            <va-modal stateful ref="feasibilityConfirmModal" hide-default-actions>
                Are you sure you want to submit this feasibility compliance? <strong>This action is irreversible!</strong>
                <template #footer>
                    <va-button class="mr-2" @click="$refs.feasibilityConfirmModal.hide()" flat>Cancel</va-button>
                    <va-button class="mr-2" @click="onSubmitFeasibilityCompliance()">Confirm</va-button>
                </template>
            </va-modal>
            
            <template #footer v-if="reviewer">
                <va-button class="mr-2" @click="$refs.feasibilityComplianceModal.hide()" flat>Cancel</va-button>
                <va-button class="mr-2" :disabled="!userCanEdit(selectedLidar, 'feasibility')" @click="onSaveFeasibilityCompliance()">Save draft</va-button>
                <va-button class="mr-2" :disabled="!userCanEdit(selectedLidar, 'feasibility')" @click="confirmFeasibilityCompliance()" icon="check" :color="complianceColor(selectedLidar.feasibility_compliance)">Submit</va-button>
            </template>
            <template #footer v-else>
                <va-button @click="$refs.feasibilityComplianceModal.hide()">Close</va-button>
            </template>
        </va-modal>
        <va-modal stateful ref="featureComplianceModal" hide-default-actions >
            <FeatureComplianceForm v-model="selectedLidar" ref="featureComplianceForm" :disabled="disabled" :reviewer="userCanEdit(selectedLidar, 'feature')" />

            <va-modal stateful ref="featureConfirmModal" hide-default-actions>
                Are you sure you want to submit this feature compliance? <strong>This action is irreversible!</strong>
                <template #footer>
                    <va-button class="mr-2" @click="$refs.featureConfirmModal.hide()" flat>Cancel</va-button>
                    <va-button class="mr-2" @click="onSubmitFeatureCompliance()">Confirm</va-button>
                </template>
            </va-modal>

            <template #footer v-if="reviewer">
                <va-button class="mr-2" @click="$refs.featureComplianceModal.hide()" flat>Cancel</va-button>
                <va-button class="mr-2" :disabled="!userCanEdit(selectedLidar, 'feature')" @click="onSaveFeatureCompliance()">Save draft</va-button>
                <va-button class="mr-2" :disabled="!userCanEdit(selectedLidar, 'feature')" @click="confirmFeatureCompliance()" icon="check" :color="complianceColor(selectedLidar.feature_compliance)">Submit</va-button>
            </template>
            <template #footer v-else>
                <va-button @click="$refs.featureComplianceModal.hide()">Close</va-button>
            </template>
        </va-modal>
        <va-modal stateful ref="channelComplianceModal" hide-default-actions >
            <ChannelComplianceForm v-model="selectedLidar" ref="channelComplianceForm" :disabled="disabled" :reviewer="userCanEdit(selectedLidar, 'channel')" />

            <va-modal stateful ref="channelConfirmModal" hide-default-actions>
                Are you sure you want to submit this channel compliance? <strong>This action is irreversible!</strong>
                <template #footer>
                    <va-button class="mr-2" @click="$refs.channelConfirmModal.hide()" flat>Cancel</va-button>
                    <va-button class="mr-2" @click="onSubmitChannelCompliance()">Confirm</va-button>
                </template>
            </va-modal>
            
            <template #footer v-if="reviewer">
                <va-button class="mr-2" @click="$refs.channelComplianceModal.hide()" flat>Cancel</va-button>
                <va-button class="mr-2" :disabled="!userCanEdit(selectedLidar, 'channel')" @click="onSaveChannelCompliance()">Save draft</va-button>
                <va-button class="mr-2" :disabled="!userCanEdit(selectedLidar, 'channel')" @click="confirmChannelCompliance()" icon="check" :color="complianceColor(selectedLidar.channel_compliance)">Submit</va-button>
            </template>
            <template #footer v-else>
                <va-button @click="$refs.channelComplianceModal.hide()">Close</va-button>
            </template>
        </va-modal>
        <va-modal stateful ref="generalComplianceModal" hide-default-actions >
            <GeneralComplianceForm v-model="selectedLidar" ref="generalComplianceForm" :disabled="disabled" :reviewer="userCanEdit(selectedLidar, 'general')" />

            <va-modal stateful ref="generalConfirmModal" hide-default-actions>
                Are you sure you want to submit this general compliance? <strong>This action is irreversible!</strong>
                <template #footer>
                    <va-button class="mr-2" @click="$refs.generalConfirmModal.hide()" flat>Cancel</va-button>
                    <va-button class="mr-2" @click="onSubmitGeneralCompliance()">Confirm</va-button>
                </template>
            </va-modal>
            
            <template #footer v-if="reviewer">
                <va-button class="mr-2" @click="$refs.generalComplianceModal.hide()" flat>Cancel</va-button>
                <va-button class="mr-2" :disabled="!userCanEdit(selectedLidar, 'general')" @click="onSaveGeneralCompliance()">Save draft</va-button>
                <va-button class="mr-2" :disabled="!userCanEdit(selectedLidar, 'general')" @click="confirmGeneralCompliance()" icon="check" :color="complianceColor(selectedLidar.general_compliance)">Submit</va-button>
            </template>
            <template #footer v-else>
                <va-button @click="$refs.generalComplianceModal.hide()">Close</va-button>
            </template>
        </va-modal>
        <va-modal stateful ref="dataComplianceModal" hide-default-actions >
            <DataComplianceForm v-model="selectedLidar" :disabled="disabled" ref="dataComplianceForm" :reviewer="userCanEdit(selectedLidar, 'data')" />

            <va-modal stateful ref="dataConfirmModal" hide-default-actions>
                Are you sure you want to submit this data compliance? <strong>This action is irreversible!</strong>
                <template #footer>
                    <va-button class="mr-2" @click="$refs.dataConfirmModal.hide()" flat>Cancel</va-button>
                    <va-button class="mr-2" @click="onSubmitDataCompliance()">Confirm</va-button>
                </template>
            </va-modal>
            
            <template #footer v-if="dataReviewer">
                <va-button class="mr-2" @click="$refs.dataComplianceModal.hide()" flat>Cancel</va-button>
                <va-button class="mr-2" :disabled="!userCanEdit(selectedLidar, 'data')" @click="onSaveDataCompliance()">Save draft</va-button>
                <va-button class="mr-2" :disabled="!userCanEdit(selectedLidar, 'data')" @click="confirmDataCompliance()" icon="check" :color="complianceColor(selectedLidar.data_compliance)">Submit</va-button>
            </template>
            <template #footer v-else>
                <va-button @click="$refs.dataComplianceModal.hide()">Close</va-button>
            </template>
        </va-modal>
    </div>
</template>

<script>
import api from '@/lib/api'
import { mapGetters, mapActions } from 'vuex'

import EditLidarSystemForm from '@/components/lidar/EditLidarSystemForm'
import FeasibilityComplianceForm from '@/components/national_facility/cars/initial_acceptance/lidar/FeasibilityComplianceForm.vue'
import FeatureComplianceForm from '@/components/national_facility/cars/initial_acceptance/lidar/FeatureComplianceForm.vue'
import ChannelComplianceForm from '@/components/national_facility/cars/initial_acceptance/lidar/ChannelComplianceForm.vue'
import GeneralComplianceForm from '@/components/national_facility/cars/initial_acceptance/lidar/GeneralComplianceForm.vue'
import DataComplianceForm from '@/components/national_facility/cars/initial_acceptance/lidar/DataComplianceForm.vue'

export default {
    name: 'NFLidarsList',
    props: {
        id: {type: String, default: ''},
        disabled: {type: Boolean, default: false},
        reviewer: {type: Boolean, default: false},
        dataReviewer: {type: Boolean, default: false},
    },
    components: {
        EditLidarSystemForm,
        FeasibilityComplianceForm,
        FeatureComplianceForm,
        ChannelComplianceForm,
        GeneralComplianceForm,
        DataComplianceForm
    },
    data () {
        return {
            lidars: [],
            selectedLidar: undefined,
            lidarColumns: [
                { key: 'system', label: 'System' },
                { key: 'feasibility', label: 'Feasibility' },
                { key: 'feature', label: 'Feature' },
                { key: 'channel', label: 'Channel' },
                { key: 'general', label: 'Instrument' },
                { key: 'data', label: 'Data' }
            ],
            rules: {
                mandatory: (v) => !!v || "Required"
            },
            actionToConfirm: undefined
        }
    },
    computed: {
        ...mapGetters ( 'types', ['complianceTypes'] ),
    },
    methods: {
        ...mapActions ( 'types', ['loadComplianceTypes'] ),
        userCanEdit (lidar, fieldName) {
            let permission = false
            let submitted = false

            switch (fieldName) {
                case 'feasibility': {
                    permission = this.reviewer
                    submitted = !!lidar.feasibility_compliance_evaluation_date
                } break;
                case 'feature': {
                    permission = this.reviewer
                    submitted = !!lidar.feature_compliance_evaluation_date
                } break;
                case 'channel': {
                    permission = this.reviewer
                    submitted = !!lidar.channel_compliance_evaluation_date
                } break;
                case 'data': {
                    permission = this.dataReviewer
                    submitted = !!lidar.data_compliance_evaluation_date
                } break;
                case 'general': {
                    permission = this.reviewer
                    submitted = !!lidar.general_compliance_evaluation_date
                } break;
                default: break;
            }

            return permission && !submitted
        },
        confirm (callback) {
            this.actionToConfirm = callback
            this.$refs.confirmModal.show()
        },
        confirmAction () {
            if ( this.actionToConfirm ) {
                this.actionToConfirm()
            }

            this.actionToConfirm = undefined
        },
        complianceName (compliance) {
            try {
                return compliance.name
            }
            catch {
                return 'N/A'
            }
        },
        complianceColor (compliance) {
            if ( !compliance || !compliance.code ) {
                return 'gray'
            }

            switch ( compliance.code ) {
                case 'yes':
                    return 'success';
                case 'partially':
                    return 'warning';
                case 'no':
                default:
                    return 'danger';
            }
        },
        loadLidars () {
            if (!this.id) {
                return;
            }

            api.nf.loadLidars ( this.id ).then(response => { this.lidars = response.data.data.lidars; })
        },
        selectLidar (lidar) {
            this.selectedLidar = lidar
        },
        viewLidarSystem (lidar) {
            this.selectLidar (lidar)
            this.$refs.viewLidarSystemModal.show()
        },
        editFeasibilityCompliance ( lidar ) {this.selectLidar (lidar); this.$refs.feasibilityComplianceModal.show() },
        editFeatureCompliance ( lidar ) {this.selectLidar (lidar); this.$refs.featureComplianceModal.show() },
        editChannelCompliance ( lidar ) {this.selectLidar (lidar); this.$refs.channelComplianceModal.show() },
        editGeneralCompliance ( lidar ) {this.selectLidar (lidar); this.$refs.generalComplianceModal.show() },
        editDataCompliance ( lidar ) {this.selectLidar (lidar); this.$refs.dataComplianceModal.show() },
        confirmLidarCompliance () {
            const formValid = this.$refs.lidarGeneralComplianceForm.validate()
            if ( !formValid ) {
                return;
            }

            this.$refs.confirmLidarComplianceModal.show()
        },
        onSaveFeasibilityCompliance () {
            const compliance = {
                compliance: this.selectedLidar.feasibility_compliance,
                comments: this.selectedLidar.feasibility_compliance_comments,
                recommendations: this.selectedLidar.feasibility_compliance_recommendations
            }

            this.$refs.feasibilityComplianceModal.hide()
            api.nf.setLidarFeasibilityCompliance ( this.id, this.selectedLidar._id, compliance )
        },
        confirmFeasibilityCompliance () {
            const valid = this.$refs.feasibilityComplianceForm.validate()

            if (!valid) {
                return;
            }

            this.$refs.feasibilityConfirmModal.show()
        },
        async onSubmitFeasibilityCompliance () {
            const data = {
                feasibility_compliance: this.selectedLidar.feasibility_compliance,
                feasibility_compliance_comments: this.selectedLidar.feasibility_compliance_comments,
                feasibility_compliance_recommendations: this.selectedLidar.feasibility_compliance_recommendations
            }

            this.$refs.feasibilityComplianceModal.hide()
            await api.nf.submitLidarComplianceField ( this.id, this.selectedLidar._id, 'feasibility', data )
            this.loadLidars()
        },
        onSaveFeatureCompliance () {
            const compliance = {
                compliance: this.selectedLidar.feature_compliance,
                comments: this.selectedLidar.feature_compliance_comments,
                recommendations: this.selectedLidar.feature_compliance_recommendations
            }

            this.$refs.featureComplianceModal.hide()
            api.nf.setLidarFeatureCompliance ( this.id, this.selectedLidar._id, compliance )
        },
        confirmFeatureCompliance () {
            const valid = this.$refs.featureComplianceForm.validate()

            if (!valid) {
                return;
            }

            this.$refs.featureConfirmModal.show()
        },
        async onSubmitFeatureCompliance () {
            const data = {
                feature_compliance: this.selectedLidar.feature_compliance,
                feature_compliance_comments: this.selectedLidar.feature_compliance_comments,
                feature_compliance_recommendations: this.selectedLidar.feature_compliance_recommendations
            }

            this.$refs.featureComplianceModal.hide()
            await api.nf.submitLidarComplianceField ( this.id, this.selectedLidar._id, 'feature', data )
            this.loadLidars()
        },
        onSaveChannelCompliance () {
            const compliance = {
                compliance: this.selectedLidar.channel_compliance,
                comments: this.selectedLidar.channel_compliance_comments,
                recommendations: this.selectedLidar.channel_compliance_recommendations
            }

            this.$refs.channelComplianceModal.hide()
            api.nf.setLidarChannelCompliance ( this.id, this.selectedLidar._id, compliance )
        },
        confirmChannelCompliance () {
            const valid = this.$refs.channelComplianceForm.validate()

            if (!valid) {
                return;
            }

            this.$refs.channelConfirmModal.show()
        },
        async onSubmitChannelCompliance () {
            const data = {
                channel_compliance: this.selectedLidar.channel_compliance,
                channel_compliance_comments: this.selectedLidar.channel_compliance_comments,
                channel_compliance_recommendations: this.selectedLidar.channel_compliance_recommendations
            }

            this.$refs.channelComplianceModal.hide()
            await api.nf.submitLidarComplianceField ( this.id, this.selectedLidar._id, 'channel', data )
            this.loadLidars()
        },
        onSaveGeneralCompliance () {
            const compliance = {
                compliance: this.selectedLidar.general_compliance,
                comments: this.selectedLidar.general_compliance_comments,
                recommendations: this.selectedLidar.general_compliance_recommendations
            }

            this.$refs.generalComplianceModal.hide()
            api.nf.setLidarGeneralCompliance ( this.id, this.selectedLidar._id, compliance )
        },
        confirmGeneralCompliance () {
            const valid = this.$refs.generalComplianceForm.validate()

            if (!valid) {
                return;
            }

            this.$refs.generalConfirmModal.show()
        },
        async onSubmitGeneralCompliance () {
            const data = {
                general_compliance: this.selectedLidar.general_compliance,
                general_compliance_comments: this.selectedLidar.general_compliance_comments,
                general_compliance_recommendations: this.selectedLidar.general_compliance_recommendations
            }

            this.$refs.generalComplianceModal.hide()
            await api.nf.submitLidarComplianceField ( this.id, this.selectedLidar._id, 'general', data )
            this.loadLidars()
        },
        onSaveDataCompliance () {
            const compliance = {
                compliance: this.selectedLidar.data_compliance,
                comments: this.selectedLidar.datal_compliance_comments,
                recommendations: this.selectedLidar.data_compliance_recommendations
            }

            
            this.$refs.dataComplianceModal.hide()
            api.nf.setLidarDataCompliance ( this.id, this.selectedLidar._id, compliance )
        },
        confirmDataCompliance () {
            const valid = this.$refs.dataComplianceForm.validate()

            if (!valid) {
                return;
            }

            this.$refs.dataConfirmModal.show()
        },
        async onSubmitDataCompliance () {
            const data = {
                data_compliance: this.selectedLidar.data_compliance,
                data_compliance_comments: this.selectedLidar.data_compliance_comments,
                data_compliance_recommendations: this.selectedLidar.data_compliance_recommendations
            }

            this.$refs.dataComplianceModal.hide()
            await api.nf.submitLidarComplianceField ( this.id, this.selectedLidar._id, 'data', data )
            this.loadLidars()
        },
        submitLidarCompliance () {
        }
    },
    watch: {
        id () {
            this.loadLidars()
        }
    },
    async mounted () {
        if ( !this.complianceTypes.length ) {
            this.loadComplianceTypes()
        }

        this.loadLidars()
    }
}
</script>