<template>
    <div class="layout gutter--xs">
        <div class="row">
            <va-card-title>Instrument compliance</va-card-title>
        </div>
        <va-form ref="complianceForm" class="layout gutter--xl">
            <div class="row justify--center align--start">
                <div class="flex">
                    <va-select
                        label="Instrument Compliance"
                        :options="complianceTypes"
                        track-by="_id"
                        text-by="name"
                        v-model="lidar.general_compliance"
                        :rules="[rules.mandatory]"
                        :disabled="disabled"
                        :readonly="!reviewer"
                    >
                        <template #appendInner>
                            <va-popover prevent-overflow message="Criteria: each sub-criteria is at least partially fulfilled">
                                <va-icon name="info" />
                            </va-popover>
                        </template>
                    </va-select>
                </div>
            </div>
            <div class="row justify--center align--center">
                <div class="flex xs12 sm6">
                    <va-input
                        label="Instrument compliance comments"
                        type="textarea"
                        v-model="lidar.general_compliance_comments"
                        :rules="[rules.mandatory]"
                        style="width: 100%"
                        messages="Required field"
                        :disabled="disabled"
                        :readonly="!reviewer"
                    />
                </div>
                <div class="flex xs12 sm6">
                    <va-input
                        label="Instrument compliance recommendations"
                        type="textarea"
                        v-model="lidar.general_compliance_recommendations"
                        :rules="[rules.mandatory]"
                        style="width: 100%"
                        messages="Required field"
                        :disabled="disabled"
                        :readonly="!reviewer"
                    />
                </div>
            </div>
        </va-form>
        <va-modal
            ref="confirmComplianceModal"
            stateful
            title="Confirm Lidar instrument compliance change"
            @ok="submitCompliance"
        >
            Are you sure you want to change the lidar compliance to <strong>{{lidar.general_compliance.name}}</strong> for this system?
        </va-modal>
    </div>
</template>

<script>
import api from '@/lib/api'
import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'GeneralComplianceForm',
    props: {
        modelValue: {
            type: Object,
            default () {
                return {
                    general_compliance: {
                        id: '',
                        name: '',
                        code: ''
                    },
                    general_compliance_comments: '',
                    general_compliance_recommendations: ''
                }
            }
        },
        disabled: {type: Boolean, default: false},
        reviewer: {type: Boolean, default: false}
    },
    data () {
        return {
            types: {
                instrument: [],
                instrument_status: [],
                instrument_location: [],
                instrument_additional_function: [],
                instrument_feature: [],
                lidar_channel_categories: [],
                lidar_channel_availability: []
            },
            suggestedCompliance: undefined,
            complianceCodes: api.lidar.FEASIBILITY_COMPLIANCE,
            rules: {
                mandatory: (v) => !!v || "Required"
            }
        }
    },
    computed: {
        ...mapGetters ( 'types', ['complianceTypes'] ),
        lidar: {
            get () { return this.modelValue },
            set (value) {this.$emit('update:modelValue', value)}
        },
        lidarComplianceColor () {
            if ( !this.lidar.general_compliance ) {
                return 'primary'
            }

            switch ( this.lidar.general_compliance.code ) {
                case 'yes':
                    return 'success';
                case 'partially':
                    return 'warning';
                case 'no':
                default:
                    return 'danger';
            }
        }
    },
    watch: {
    },
    methods: {
        ...mapActions ( 'types', ['loadComplianceTypes'] ),
        validate () {
            return this.$refs.complianceForm.validate()
        },
        loadSuggestedCompliance () {
        },
        cardStripeColor(code) {
            if ( code === 'yes' ) {
                return 'success'
            }

            if ( code === 'partially' ) {
                return 'warning'
            }

            return 'danger'
        },
        complianceListItemClass(code) {
            if ( code === 'yes' ) {
                return 'compliance_list_item__green'
            }

            if ( code === 'partially' ) {
                return 'compliance_list_item__yellow'
            }

            return 'compliance_list_item__red'
        },
        confirmCompliance () {
            const formValid = this.$refs.complianceForm.validate()
            if ( !formValid ) {
                return;
            }

            this.$refs.confirmComplianceModal.show()
        },
        submitCompliance () {

        }
    },
    async mounted () {
        if ( !this.complianceTypes.length ) {
            this.loadComplianceTypes ()
        }

        this.loadSuggestedCompliance ()
    }
}
</script>