async function addUser ( options ) {
    return this.api({
        url: `/user/add`,
        data: {
            email: options.email,
            first_name: options.first_name,
            last_name: options.last_name,
            roles: options.roles
        },
        method: 'POST'
    })
}

async function editUser ( userId, options ) {
    return this.api({
        url: `/user/${userId}/edit`,
        data: {
            email: options.email,
            first_name: options.first_name,
            last_name: options.last_name,
            roles: options.roles
        },
        method: 'POST'
    })
}

async function loadUsersWithRoleName ( roleName ) {
    return this.api({
        url: `/user/with-role-name/${roleName}`,
        method: 'GET'
    })
}

async function loadUsersWithoutNf () {
    return this.api({
        url: `/user/without-nf`,
        method: 'GET'
    })
}

async function requestPasswordReset (userEmail) {
    return this.api({
        url: `/auth/reset-password`,
        data: {
            "email": userEmail
        },
        method: 'POST'
    })
}

async function changePassword (options) {
    return this.api({
        url: `/auth/change-password`,
        data: {
            password: options.password,
            confirm_password: options.confirm_password,
            password_reset_hash: options.password_reset_hash || undefined
        },
        method: 'POST'
    })
}
 
 export default {
     pluginName: 'user',
     methods: {
        addUser: addUser,
        editUser: editUser,
        loadUsersWithRoleName: loadUsersWithRoleName,
        loadUsersWithoutNf: loadUsersWithoutNf,
        requestPasswordReset: requestPasswordReset,
        changePassword: changePassword
     },
     apiMessages: {
         0: 'Operation successful!',
         1: 'Information loaded successfully!',
         2: 'Information saved successfully!',
         1000: 'Unknown error!',
         1001: 'Permission denied!',
         1002: 'Could not load information!',
         1003: 'Could not save information!',
         1004: 'Could not register National Facility!'
     }
 }