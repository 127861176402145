import axios from '@/plugins/axios'

const state = {
    access_token: localStorage.getItem('access_token') || '',
    refresh_token: localStorage.getItem('refresh_token') || '',
}

const getters = {
    usertoken(state) {
        return state.access_token
    },
    access_token(state, getters) {
        if (!getters.isLoggedIn) {
            return ''
        }
        
        return state.access_token
    },
    refresh_token(state, getters) {
        if (!getters.isLoggedIn) {
            return ''
        }
        
        return state.refresh_token
    },
    userinfo(state) {
        let b64DecodeUnicode = str =>
          decodeURIComponent(
            Array.prototype.map.call(atob(str), c =>
              '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
            ).join(''))

        let parseJwt = token => 
          JSON.parse(
            b64DecodeUnicode(
              token.split('.')[1].replace('-', '+').replace('_', '/')
            )
          )
        if (!state.access_token) {
            return {}
        }
        
        let token = parseJwt ( state.access_token )

        if (!token.profile) {
            return {}
        }

        return token.profile
    },
    isLoggedIn(state) {
        let b64DecodeUnicode = str =>
          decodeURIComponent(
            Array.prototype.map.call(atob(str), c =>
              '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
            ).join(''))

        let parseJwt = token => 
          JSON.parse(
            b64DecodeUnicode(
              token.split('.')[1].replace('-', '+').replace('_', '/')
            )
          )

        let access_expired = true

        if (state.access_token) {
            let access_token = parseJwt ( state.access_token )
            access_expired = (access_token.exp < (new Date().getTime() + 1) / 1000)
        }

        let refresh_expired = true

        if (state.refresh_token) {
            let refresh_token = parseJwt ( state.refresh_token )
            refresh_expired = (refresh_token.exp < (new Date().getTime() + 1) / 1000)
        }

        return !refresh_expired || !access_expired
    }
}

const actions = {
    async login({
        commit
    }, options) {
        let loginResponse = await axios({
            url:'/auth/login',
            method: 'POST',
            data: {
                password: options.password,
                email: options.email
            }
        })
        
        if (!loginResponse?.data?.access_token) {
            return loginResponse
        }

        localStorage.setItem('access_token', loginResponse.data.access_token)
        localStorage.setItem('refresh_token', loginResponse.data.refresh_token)
        axios.defaults.headers.Authorization = `Bearer ${loginResponse.data.access_token}`
        commit('LOGIN', {
            access_token: loginResponse.data.access_token,
            refresh_token: loginResponse.data.refresh_token
        })
        return loginResponse
    },
    logout({commit}) {
        localStorage.removeItem('access_token')
        localStorage.removeItem('refresh_token')
        commit('LOGOUT')
    },
    resetPassword({commit}, resetPassword) {
        commit('RESET_PASSWORD', resetPassword)
    },
    forgotPassword({commit}, forgotPassword) {
        commit('FORGOT_PASSWORD', forgotPassword)
    },
    hasPermission ({getters}, permission) {
        return getters.isLoggedIn && getters.userinfo?.permissions?.map (p => p.toLowerCase()).filter(p => p === permission.toLowerCase()).length === 1 || false
    },
    isAdmin({getters}) {
        return  getters.isLoggedIn && getters.userinfo?.permissions?.map (p => p.toLowerCase()).indexOf("administrator") !== -1 || false
    }
}

// mutations
const mutations = {
    LOGIN(state, payload) {
        state.access_token = payload.access_token
        state.refresh_token = payload.refresh_token
    },
    LOGOUT(state) {
        state.access_token = ''
        state.refresh_token = ''
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}