<template>
    <va-inner-loading :loading="!loaded">
        <va-form ref="nf_edit_info_form">
            <div class="row justify--end">
                <div class="flex">
                    
                </div>
            </div>
            <div class="row justify--center" v-if="!disabled && !readonly">
                <div class="flex xs12">
                    <va-collapse
                        header="Add new lidar system"
                        icon="add"
                        color="secondary"
                        solid
                    >
                        <EditLidarSystemForm
                            @save="(lidarData) => onAddNewLidar(lidarData)"
                        />
                    </va-collapse>
                </div>
            </div>
            <div class="row">
                <va-data-table
                    :items="lidars"
                    :columns="lidarTableColumns"
                    v-if="lidars.length"
                >
                    <template #cell(name)="{rowData}">
                        <va-button icon="edit" class="mr-3" @click="showEditLidarSystemModal(rowData)" v-if="!disabled && !readonly" />
                        <va-button icon="visibility" class="mr-3" @click="showEditLidarSystemModal(rowData)" v-else />
                        {{rowData.name}}
                    </template>
                    <template #cell(status)="{rowData}">
                        {{rowData.status.name}}
                    </template>
                    <template #cell(compliance)="{rowData}">
                        <template v-if="rowData.general_compliance">{{rowData.general_compliance.name}}</template>
                        <template v-else>
                        <span class="mr-1">Not evaluated</span>
                        <va-button icon-right="help" flat class="mr-3" @click="showLidarSystemCompliancyModal(rowData)" />
                        </template>
                    </template>
                </va-data-table>
                <div class="flex xs12 va-typography mt-4" v-else>
                    <p v-if="disabled || readonly">No lidar system registered</p>
                    <p v-else>You can register a lidar system from the dropdown above. To do so, click <strong>Add new lidar system</strong> and fill in the required fields.</p>
                </div>
            </div>
        </va-form>
        <va-modal
            stateful
            hide-default-actions
            ref="editLidarSystemModal"
            no-padding
        >
            <template #header>
                <h3 class="display-3">Edit Lidar System</h3>
            </template>
            <EditLidarSystemForm
                :disabled="disabled"
                :readonly="readonly"
                :data="lidarSystemToEdit"
                :lidarId="lidarSystemToEdit._id"
                @save="submitEditLidarSystem(lidarSystemToEdit._id)"
                @hide="$refs.editLidarSystemModal.hide()"
            />
        </va-modal>
        <va-modal
            stateful
            ok-text="Close"
            cancel-text=""
            ref="lidarSystemCompliancyModal"
        >
            <template #header>
                <div class="va-typography-block">
                    <span class="display-6" style="text-transform: uppercase">Lidar System Compliancy Hints</span>
                    <p class="mt-4">Your lidar system has not been evaluated by CARS yet! However, below you can check how your lidar system compares to the minimum requirements.</p>
                </div>
            </template>
            <LidarSystemCompliancy :lidarId="lidarIdSystemToShowCompliancy" />
        </va-modal>
    </va-inner-loading>
</template>

<script>
const lengthMax64Rule = (v) => v.length <= 64 || "Maximum 64 characters";
const lengthMax8Rule = (v) => v.length <= 8 || "Maximum 8 characters";
const alphanumericOnlyRule = (v) => /^[a-zA-Z0-9_]+$/.test(v) || "Alphanumeric values only";
const emailRule = (v) => /^[^\s@]+@[^\s@]+$/.test(v) || 'Invalid E-Mail address';

import api from '@/lib/api'

import EditLidarSystemForm from '@/components/lidar/EditLidarSystemForm.vue'
import LidarSystemCompliancy from '@/components/lidar/LidarSystemCompliancy.vue'

export default {
    name: 'NFEditLidar',
    props: {
        id: { type: String, default: '' },
        readonly: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false }
    },
    components: {
        EditLidarSystemForm,
        LidarSystemCompliancy
    },
    data: () => ({
        lidars: [],
        lidarSystemToEdit: undefined,
        lidarIdSystemToShowCompliancy: undefined,
        lidarTableColumns: [
            {key: 'name', label: 'Instrument Name'},
            {key: 'status', label: 'Operational Status'},
            {key: 'compliance', label: 'Compliance'}
        ],
        facilityNameRules: [lengthMax64Rule],
        facilityAcronymRules: [alphanumericOnlyRule, lengthMax8Rule],
        emailRules: [emailRule],
        loaded: false
    }),
    computed: {

    },
    watch: {
        id () {
            this.loadNFLidars()
        }
    },
    methods: {
        async submitEditLidarSystem (id) {
            await api.lidar.editLidarSystem (id, this.id, this.lidarSystemToEdit)
            this.loadNFLidars()

            this.$refs.editLidarSystemModal.hide()
        },
        showEditLidarSystemModal (lidarData) {
            this.lidarSystemToEdit = lidarData
            api.lidar.loadSuggestedFeasibilityCompliance (lidarData._id)
            api.lidar.loadSuggestedFeatureCompliance (lidarData._id)
            api.lidar.loadSuggestedChannelCompliance (lidarData._id)

            this.$refs.editLidarSystemModal.show()
        },
        async onAddNewLidar ( lidarData ) {
            console.log (lidarData)
            await api.nf.addLidarSystem ( this.id, lidarData )
            console.log ("I am here!")
            this.loadNFLidars()
        },
        async loadNFLidars () {
            this.loaded = false

            if (!this.id) {
                return;
            }

            const response = await api.nf.loadLidars(this.id)
            const data = response.data.data;

            this.lidars = data.lidars

            this.loaded = true
        },
        showLidarSystemCompliancyModal (lidarData) {
            this.lidarIdSystemToShowCompliancy = lidarData._id
            this.$refs.lidarSystemCompliancyModal.show()
        }
    },
    async mounted () {
        this.loadNFLidars()
    }
}
</script>