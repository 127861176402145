<template>
    <div class="layout gutter--xs">
        <div class="row">
            <div class="flex xs12">
                <va-data-table
                    :items="photometers"
                    :columns="photometerColumns"
                    striped
                >
                    <template #cell(system)="{rowData}">
                        <va-button icon="visibility" size="small" class="mr-2" @click="viewPhotometer(rowData)" />{{rowData.name}}
                    </template>
                    <template #cell(general)="{rowData}">
                        <va-chip
                            square
                            :icon="userCanEdit(rowData, 'general') ? 'edit' : 'visibility'"
                            size="small"
                            @click="editGeneralCompliance (rowData)"
                            :color="complianceColor(rowData.general_compliance)"
                        >
                            {{complianceName(rowData.general_compliance)}}
                        </va-chip>
                    </template>
                    <template #cell(data)="{rowData}">
                        <va-chip
                            square
                            :icon="userCanEdit(rowData, 'data') ? 'edit' : 'visibility'"
                            size="small"
                            @click="editDataCompliance (rowData)"
                            :color="complianceColor(rowData.data_compliance)"
                        >
                            {{complianceName(rowData.data_compliance)}}
                        </va-chip>
                    </template>
                </va-data-table>
            </div>
        </div>
        <va-modal stateful ref="viewPhotometerModal" hide-default-actions no-padding>
            <EditPhotometerSystemForm :data="selectedPhotometer" readonly @hide="$refs.viewPhotometerModal.hide()" />
        </va-modal>
        <va-modal stateful ref="generalComplianceModal" hide-default-actions >
            <GeneralComplianceForm v-model="selectedPhotometer" ref="generalComplianceForm" :disabled="disabled" :reviewer="userCanEdit(selectedPhotometer, 'general')" />

            <va-modal stateful ref="generalConfirmModal" hide-default-actions>
                Are you sure you want to submit this general compliance? <strong>This action is irreversible!</strong>
                <template #footer>
                    <va-button class="mr-2" @click="$refs.generalConfirmModal.hide()" flat>Cancel</va-button>
                    <va-button class="mr-2" @click="onSubmitGeneralCompliance()">Confirm</va-button>
                </template>
            </va-modal>
            
            <template #footer v-if="reviewer">
                <va-button class="mr-2" @click="$refs.generalComplianceModal.hide()" flat>Cancel</va-button>
                <va-button class="mr-2" :disabled="!userCanEdit(selectedPhotometer, 'general')" @click="onSaveGeneralCompliance()">Save draft</va-button>
                <va-button class="mr-2" :disabled="!userCanEdit(selectedPhotometer, 'general')" @click="confirmGeneralCompliance()" icon="check" :color="complianceColor(selectedPhotometer.general_compliance)">Submit</va-button>
            </template>
            <template #footer v-else>
                <va-button @click="$refs.generalComplianceModal.hide()">Close</va-button>
            </template>
        </va-modal>
        <va-modal stateful ref="dataComplianceModal" hide-default-actions >
            <DataComplianceForm v-model="selectedPhotometer" :disabled="disabled" ref="dataComplianceForm" :reviewer="userCanEdit(selectedPhotometer, 'data')" />

            <va-modal stateful ref="dataConfirmModal" hide-default-actions>
                Are you sure you want to submit this data compliance? <strong>This action is irreversible!</strong>
                <template #footer>
                    <va-button class="mr-2" @click="$refs.dataConfirmModal.hide()" flat>Cancel</va-button>
                    <va-button class="mr-2" @click="onSubmitDataCompliance()">Confirm</va-button>
                </template>
            </va-modal>
            
            <template #footer v-if="dataReviewer">
                <va-button class="mr-2" @click="$refs.dataComplianceModal.hide()" flat>Cancel</va-button>
                <va-button class="mr-2" :disabled="!userCanEdit(selectedPhotometer, 'data')" @click="onSaveDataCompliance()">Save draft</va-button>
                <va-button class="mr-2" :disabled="!userCanEdit(selectedPhotometer, 'data')" @click="confirmDataCompliance()" icon="check" :color="complianceColor(selectedPhotometer.data_compliance)">Submit</va-button>
            </template>
            <template #footer v-else>
                <va-button @click="$refs.dataComplianceModal.hide()">Close</va-button>
            </template>
        </va-modal>
    </div>
</template>

<script>
import api from '@/lib/api'
import { mapGetters, mapActions } from 'vuex'

import EditPhotometerSystemForm from '@/components/photometer/EditPhotometerSystemForm'

import GeneralComplianceForm from '@/components/national_facility/cars/initial_acceptance/photometer/GeneralComplianceForm.vue'
import DataComplianceForm from '@/components/national_facility/cars/initial_acceptance/photometer/DataComplianceForm.vue'

export default {
    name: 'NFPhotometersList',
    props: {
        id: {type: String, default: ''},
        disabled: {type: Boolean, default: false},
        reviewer: {type: Boolean, default: false},
        dataReviewer: {type: Boolean, default: false}
    },
    components: {
        EditPhotometerSystemForm,
        GeneralComplianceForm,
        DataComplianceForm
    },
    data () {
        return {
            photometers: [],
            selectedPhotometer: undefined,
            photometerCompliance: {
                compliance: undefined,
                comments: '',
                recommendations: ''
            },
            photometerColumns: [
                { key: 'system', label: 'System' },
                { key: 'general', label: 'Instrument' },
                { key: 'data', label: 'Data' }
            ],
            rules: {
                mandatory: (v) => !!v || "Required"
            }
        }
    },
    computed: {
        ...mapGetters ( 'types', ['complianceTypes'] ),
        photometerComplianceColor () {
            if ( !this.photometerCompliance.compliance ) {
                return 'primary'
            }

            switch ( this.photometerCompliance.compliance.code ) {
                case 'yes':
                    return 'success';
                case 'partially':
                    return 'warning';
                case 'no':
                default:
                    return 'danger';
            }
        }
    },
    methods: {
        ...mapActions ( 'types', ['loadComplianceTypes'] ),
        userCanEdit (photometer, fieldName) {
            let permission = false
            let submitted = false

            switch (fieldName) {
                case 'data': {
                    permission = this.dataReviewer
                    submitted = !!photometer.data_compliance_evaluation_date
                } break;
                case 'general': {
                    permission = this.reviewer
                    submitted = !!photometer.general_compliance_evaluation_date
                } break;
                default: break;
            }

            return permission && !submitted
        },
        complianceName (compliance) {
            try {
                return compliance.name
            }
            catch {
                return 'N/A'
            }
        },
        complianceColor (compliance) {
            if ( !compliance || !compliance.code ) {
                return 'gray'
            }

            switch ( compliance.code ) {
                case 'yes':
                    return 'success';
                case 'partially':
                    return 'warning';
                case 'no':
                default:
                    return 'danger';
            }
        },
        loadPhotometers () {
            if (!this.id) {
                return;
            }

            api.nf.loadPhotometers ( this.id ).then(response => { this.photometers = response.data.data.photometers })
        },
        selectPhotometer (photometer) {
            this.selectedPhotometer = photometer
        },
        viewPhotometer (photometer) {
            this.selectPhotometer(photometer)
            this.$refs.viewPhotometerModal.show()
        },
        editGeneralCompliance ( photometer ) {this.selectPhotometer (photometer); this.$refs.generalComplianceModal.show() },
        editDataCompliance ( photometer ) {this.selectPhotometer (photometer); this.$refs.dataComplianceModal.show() },
        confirmPhotometerCompliance () {
            const formValid = this.$refs.photometerGeneralComplianceForm.validate()
            if ( !formValid ) {
                return;
            }

            this.$refs.confirmPhotometerComplianceModal.show()
        },
        onSaveGeneralCompliance () {
            const compliance = {
                compliance: this.selectedPhotometer.general_compliance,
                comments: this.selectedPhotometer.general_compliance_comments,
                recommendations: this.selectedPhotometer.general_compliance_recommendations
            }

            api.nf.setPhotometerGeneralCompliance ( this.id, this.selectedPhotometer._id, compliance )

            this.$refs.generalComplianceModal.hide()
        },
        confirmGeneralCompliance () {
            const valid = this.$refs.generalComplianceForm.validate()

            if (!valid) {
                return;
            }

            this.$refs.generalConfirmModal.show()
        },
        async onSubmitGeneralCompliance () {
            const data = {
                general_compliance: this.selectedPhotometer.general_compliance,
                general_compliance_comments: this.selectedPhotometer.general_compliance_comments,
                general_compliance_recommendations: this.selectedPhotometer.general_compliance_recommendations
            }

            this.$refs.generalComplianceModal.hide()
            await api.nf.submitPhotometerComplianceField ( this.id, this.selectedPhotometer._id, 'general', data )
            this.loadPhotometers()
        },
        onSaveDataCompliance () {
            const compliance = {
                compliance: this.selectedPhotometer.data_compliance,
                comments: this.selectedPhotometer.data_compliance_comments,
                recommendations: this.selectedPhotometer.data_compliance_recommendations
            }

            api.nf.setPhotometerDataCompliance ( this.id, this.selectedPhotometer._id, compliance )
            this.$refs.dataComplianceModal.hide()
        },
        confirmDataCompliance () {
            const valid = this.$refs.dataComplianceForm.validate()

            if (!valid) {
                return;
            }

            this.$refs.dataConfirmModal.show()
        },
        async onSubmitDataCompliance () {
            const data = {
                data_compliance: this.selectedPhotometer.data_compliance,
                data_compliance_comments: this.selectedPhotometer.data_compliance_comments,
                data_compliance_recommendations: this.selectedPhotometer.data_compliance_recommendations
            }

            this.$refs.dataComplianceModal.hide()
            await api.nf.submitPhotometerComplianceField ( this.id, this.selectedPhotometer._id, 'data', data )
            this.loadPhotometers()
        }
    },
    watch: {
        id () {
            this.loadPhotometers()
        }
    },
    async mounted () {
        if ( !this.complianceTypes.length ) {
            this.loadComplianceTypes()
        }

        this.loadPhotometers()
    }
}
</script>