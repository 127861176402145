<template>
    <div class="layout gutter--xl" v-if="isAdministrator">
        <div class="row justify--center mb-4">
            <h1 class="display-1">Edit Users</h1>
        </div>
        <div class="row justify--center">
            <va-button-dropdown label="Add new user" :close-on-content-click="false" placement="bottom">
                <va-form ref="add_user_form" class="layout fluid gutter--xs" @submit.prevent>
                    <div class="">
                        <div class="flex xs12">
                            <va-input type="email" v-model="newUserEmail" label="E-Mail" :rules="emailRules"/>
                        </div>
                        <div class="flex xs12">
                            <va-input type="text" v-model="newUserFirstName" label="First name" :rules="nameRules"/>
                        </div>
                        <div class="flex xs12">
                            <va-input type="text" v-model="newUserLastName" label="Last name" :rules="nameRules"/>
                        </div>
                        <div class="flex xs12">
                            <va-select
                                multiple
                                :options=fullRolesList
                                v-model="newUserRoles"
                                text-by="name"
                                track-by="_id"
                                label="User roles"
                            />
                        </div>
                        <div class="text--center">
                            <va-button type="submit" @click="submitNewUser()" icon="check">Save</va-button>
                        </div>
                    </div>
                </va-form>
            </va-button-dropdown>
        </div>
        <div class="row">
            <div class="flex xs12">
                <AdminUserTable
                    :users="userList"
                    :loading="userTableLoading"
                    :fullRolesList="fullRolesList"
                    @userEdit="updateUserTable()"
                />
            </div>
        </div>
    </div>
</template>

<script>
  import AdminUserTable from '@/components/adminusertable/AdminUserTable.vue'

  import {mapActions, mapGetters} from 'vuex'

  import api from '@/lib/api'

  export default {
    name: 'AdminEditUsers',
    components: {
        AdminUserTable
    },
    data: () => {
        return {
            isAdministrator: false,
            userList: [],
            userTableLoading: true,
            newUserEmail: '',
            newUserFirstName: '',
            newUserLastName: '',
            newUserRoles: [],
            emailRules: [ v => /^[^\s@]+@[^\s@]+$/.test(v) || 'Invalid E-Mail address' ],
            nameRules: [ v => v != "" || "Please enter a name" ]
        }
    },
    computed: {
        ...mapGetters('user', ['fullRolesList', 'fullUserList']),
    },
    methods: {
        ...mapActions('user', ['getFullRolesList', 'getFullUserList']),
        ...mapActions('auth', ['isAdmin']),
        
        async updateUserTable () {
            this.userTableLoading = true

            await this.getFullUserList();

            this.userList = this.fullUserList

            this.userTableLoading = false
        },
        async submitNewUser () {
            let formValid = this.$refs.add_user_form.validate()

            if (!formValid) {
                return
            }

            api.user.addUser({
                email: this.newUserEmail,
                first_name: this.newUserFirstName,
                last_name: this.newUserLastName,
                roles: this.newUserRoles
            }).then( () => {
                this.updateUserTable()
            })
        }
    },
    async mounted() {
        this.isAdministrator = await this.isAdmin()

        if (!this.isAdministrator) {
            this.$router.push({name: '404'})
        }

        await this.getFullRolesList()
        await this.updateUserTable()
    },
    beforeRouteLeave (to, from, next) {
        this.$vaToast.closeAll()
        next()
    }
  }
</script>