<script lang="ts">

import { defineComponent } from 'vue'
import { VaFileUpload } from 'vuestic-ui'
import { useModal } from 'vuestic-ui'

export default defineComponent ({
    ...VaFileUpload,

    // Override name:
    name: 'CPFileUpload',

    // Extend component:
    extends: VaFileUpload,

    // Add custom props:
    props: {
        requireConfirmation: { type: Boolean, default: false },
        confirmationTitle: { type: String, default: "Delete file?" },
        confirmationMessage: { type: String, default: 'Are you sure you want to delete this file? This operation is irreversible!' },
        okText: { type: String, default: 'Ok' },
        cancelText: { type: String, default: 'Cancel' },
        noDismiss: { type: Boolean, default: true },
        maxFiles: { type: [String, Number], default: 0 }
    },

    // Override setup so that it includes permission logic
    setup (props, ctx) {
        const base = VaFileUpload.setup({...props }, ctx)
        const { init } = useModal()

        const removeFile = ( index ) => {
            if ( props.requireConfirmation ) {
                init ({
                    title: props.confirmationTitle,
                    message: props.confirmationMessage,
                    onOk: () => {
                        base.removeFile ( index )
                    },
                    onCancel: () => {},
                    okText: props.okText,
                    cancelText: props.cancelText,
                    noDismiss: props.noDismiss,
                    noOutsideDismiss: props.noDismiss,
                    noEscDismiss: props.noDismiss
                })
            } else {
                base.removeFile ( index )
            }
        }

        const changeFieldValue = ( e ) => {
            const maxFiles = Number(props.maxFiles)
            const fileCount = (e.target?.files?.length || e.dataTransfer?.files?.length) || 0
            const allowed = maxFiles < 1 || props.modelValue.length + fileCount <= maxFiles

            if (allowed) {
                base.changeFieldValue (e)
            } else {
                init ({
                    title: 'Too many files',
                    message: `You can upload a maximum of ${maxFiles} files.`,
                    hideDefaultActions: true
                })
            }
        }

        return {
            ...base,
            changeFieldValue,
            removeFile
        }
    }
})
</script>