<template>
    <div class="layout gutter--xs">
        <div class="row">
            <va-card-title>Instrument compliance</va-card-title>
        </div>
        <va-form ref="complianceForm" class="layout gutter--xl">
            <div class="row justify--center align--start">
                <div class="flex">
                    <va-select
                        label="Instrument Compliance"
                        :options="complianceTypes"
                        track-by="_id"
                        text-by="name"
                        v-model="photometer.general_compliance"
                        :rules="[rules.mandatory]"
                        :disabled="disabled"
                        :readonly="!reviewer"
                    />
                </div>
            </div>
            <div class="row justify--center align--center">
                <div class="flex xs12 sm6">
                    <va-input
                        label="Instrument compliance comments"
                        type="textarea"
                        v-model="photometer.general_compliance_comments"
                        :rules="[rules.mandatory]"
                        style="width: 100%"
                        messages="Required field"
                        :disabled="disabled"
                        :readonly="!reviewer"
                    />
                </div>
                <div class="flex xs12 sm6">
                    <va-input
                        label="Instrument compliance recommendations"
                        type="textarea"
                        v-model="photometer.general_compliance_recommendations"
                        :rules="[rules.mandatory]"
                        style="width: 100%"
                        messages="Required field"
                        :disabled="disabled"
                        :readonly="!reviewer"
                    />
                </div>
            </div>
            <div class="row justify--center align--center">
            </div>
        </va-form>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'GeneralComplianceForm',
    props: {
        modelValue: {
            type: Object,
            default () {
                return {
                    general_compliance: {
                        id: '',
                        name: '',
                        code: ''
                    },
                    general_compliance_comments: '',
                    general_compliance_recommendations: ''
                }
            }
        },
        disabled: {type: Boolean, default: false},
        reviewer: {type: Boolean, default: false}
    },
    data () {
        return {
            rules: {
                mandatory: (v) => !!v || "Required"
            }
        }
    },
    computed: {
        ...mapGetters ( 'types', ['complianceTypes'] ),
        photometer: {
            get () { return this.modelValue },
            set (value) {this.$emit('update:modelValue', value)}
        },
        photometerComplianceColor () {
            if ( !this.photometer.general_compliance ) {
                return 'primary'
            }

            switch ( this.photometer.general_compliance.code ) {
                case 'yes':
                    return 'success';
                case 'partially':
                    return 'warning';
                case 'no':
                default:
                    return 'danger';
            }
        }
    },
    watch: {
    },
    methods: {
        ...mapActions ( 'types', ['loadComplianceTypes'] ),
        validate () {
            return this.$refs.complianceForm.validate()
        },
        loadTypes () {
            if ( !this.complianceTypes.length ) {
                this.loadComplianceTypes()
            }
        }
    },
    async mounted () {
        this.loadTypes ()
    }
}
</script>