
import {createRouter, createWebHistory} from 'vue-router';

import IndexView from '@/views/IndexView.vue'
import LoginView from '@/views/LoginView.vue'
import LogoutView from '@/views/LogoutView.vue'
import AdminEmails from '@/views/admin/AdminEmails'
import AdminUploadFiles from '@/views/admin/AdminUploadFiles'
import AdminEditUsers from '@/views/admin/AdminEditUsers'
import AdminEditNFs from '@/views/admin/AdminEditNFs'
import RegisterNF from '@/views/national_facility/RegisterNF.vue'
import EditNF from '@/views/national_facility/EditNF.vue'
import SelectPersonalNf from '@/views/national_facility/SelectPersonalNf.vue'
import CarsEditNFInitialAcceptance from '@/views/cars/CarsEditNFInitialAcceptance.vue'
import UnderConstruction from '@/views/generic/UnderConstruction.vue'
// import NFEditInfo from '@/components/national_facility/NFEditInfo.vue'
import ResetPassword from '@/views/user/ResetPassword.vue'
import ChangePassword from '@/views/user/ChangePassword.vue'
import FourOhFour from '@/views/generic/FourOhFour.vue'
import ContactForm from '@/views/ContactForm.vue'
import NFViewReports from '@/views/national_facility/NFViewReports.vue'
import CarsViewNFHistory from '@/views/cars/CarsViewNFHistory.vue'
import ViewLidar from '@/components/lidar/ViewLidar.vue'
import ViewPhotometer from '@/components/photometer/ViewPhotometer.vue'

// Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'IndexView',
        component: IndexView,
        meta: {
            auth: false
        }
    }, {
        path: '/login',
        name: 'Login',
        component: LoginView,
        meta: {
            auth: false
        }
    }, {
        path: '/logout',
        name: 'Logout',
        component: LogoutView,
        meta: {
            auth: false
        }
    }, {
        path: '/reset-password',
        name: 'ResetPassword',
        component: ResetPassword,
        meta: {
            auth: false
        }
    }, {
        path: '/change-password/:hash?',
        name: 'ChangePassword',
        component: ChangePassword,
        props: true,
        meta: {
            auth: false
        }
    }, {
        path: '/admin/edit-users',
        name: 'AdminEditUsers',
        component: AdminEditUsers,
        meta: {
            auth: true
        }
    }, {
        path: '/admin/edit-nfs',
        name: 'AdminEditNFs',
        component: AdminEditNFs,
        meta: {
            auth: true
        }
    }, {
        path: '/admin/email',
        name: 'AdminEmails',
        component: AdminEmails,
        meta: {
            auth: true
        }
    }, {
        path: '/admin/files',
        name: 'AdminUploadFiles',
        component: AdminUploadFiles,
        meta: {
            auth: true
        }
    }, {
        path: '/cars/register-nf',
        name: 'RegisterNF',
        component: RegisterNF,
        meta: {
            auth: true
        }
    }, 
    // {
    //     path: '/edit-my-nf/',
    //     name: 'EditMyNF',
    //     component: EditNF,
    //     props: { personalNf: true },
    //     meta: {
    //         auth: true
    //     }
    // }, {
    {
        path: '/edit-my-nf/',
        name: 'SelectPersonalNf',
        component: SelectPersonalNf,
        meta: {
            auth: true
        }
    }, {
        path: '/view-reports/',
        name: 'NFViewReports',
        component: NFViewReports,
        metha: {
            auth: true
        }
    }, {
        path: '/edit-nf/:id?',
        name: 'EditNF',
        component: EditNF,
        props: true,
        meta: {
            auth: true
        }
    }, {
        path: '/contact',
        name: 'Contact',
        component: ContactForm
    }, {
        path: '/under-construction',
        name: 'UnderConstruction',
        component: UnderConstruction
    }, {
        path: '/404',
        name: '404',
        component: FourOhFour
    }, {
        path: '/cars/evaluate-nf/:id?',
        name: 'CarsEditNFInitialAcceptance',
        component: CarsEditNFInitialAcceptance,
        props: true,
        meta: {
            auth: true
        }
    }, {
        path: '/cars/view-nf-history/',
        name: 'CarsViewNFHistory',
        component: CarsViewNFHistory,
        meta: {
            auth: true
        }
    }, {
        path: '/lidar/:id',
        name: 'ViewLidar',
        component: ViewLidar,
        props: true
    }, {
        path: '/photometer/:id',
        name: 'ViewPhotometer',
        component: ViewPhotometer,
        props: true
    }

];

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes
})

export default router
