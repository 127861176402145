<template>
    <div class="under_construction__wrapper row align--center text--center justify--center">
        <div class="va-typography-block flex">
            <h1 class="under_construction__title">Under construction</h1>
            <p class="under_construction__description">This feature is not available yet.</p>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'UnderConstruction'
  }
</script>

<style lang="scss">

  $mobileBreakPointPX: 640px;
  $tabletBreakPointPX: 768px;

.under_construction__wrapper {
    height: 100%;
}

.va-typography-block h1.under_construction__title {
    text-transform: uppercase;
    font-size: 6rem;
    line-height: 6.5rem;
    font-weight: 800;

    @media screen and (max-width: $mobileBreakPointPX) {
        font-size: 2.5rem;
        line-height: 3rem;
    }
}

.va-typography-block p.under_construction__description {
    font-size: 1.5rem;

    @media screen and (max-width: $mobileBreakPointPX) {
        font-size: 1.15rem;
    }
}
</style>