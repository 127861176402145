<template>
    <va-dropdown v-model="isShown" class="profile-dropdown" stick-to-edges keep-anchor-width placement="bottom">
        <template #anchor>
            <span class="profile-dropdown__anchor">
                <slot />
                <va-icon class="px-2" :name="isShown ? 'expand_less' : 'expand_more'" color="white" />
            </span>
        </template>
        <va-dropdown-content class="profile-dropdown__content">
            <va-list-item v-for="option in options" :key="option.name" class="pa-2">
                <router-link :to="{name: option.route}" class="profile-dropdown__item">
                    {{ option.name }}
                </router-link>
            </va-list-item>
        </va-dropdown-content>
    </va-dropdown>
</template>

<script>
  import {mapGetters} from 'vuex'

  export default {
    name: 'ProfileDropdown',
    data: () => ({
        isShown: false
    }),
    props: {
        options: Array
    },
    computed: {
      ...mapGetters('auth', ['isLoggedIn', 'userinfo'])
    },
    methods: {
    },
    async mounted() {
    }
  }
</script>

<style lang="scss" scoped>
  .profile-dropdown {
    cursor: pointer;

    &__anchor {
      display: inline-block;
    }

    &__item {
      display: block;
      color: var(--va-gray);
      &:hover,
      &:active {
        color: var(--va-primary);
      }
    }

    &__content {
        z-index: 1000;
    }
  }
</style>