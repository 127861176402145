async function register (options) {
    return this.api({
        url: `/national-facility/register`,
        data: {
            "acronym": options.acronym,
            "owner": options.owner,
            "type": options.type
        },
        method: 'POST'
    })
}

async function loadGeneralInfo (nationalFacilityId) {
   return this.api({
        url: `/national-facility/${nationalFacilityId}/general-info`,
        method: 'GET'
    })
}

async function loadGeneralInfoForUser () {
    return this.api({
        url: `/my/national-facility/general-info`,
        method: 'GET'
    })
}

async function loadNfIdForUser () {
    return this.api({
        url: `/my/national-facility`,
        method: 'GET'
    })
}

async function loadUserNfs () {
    return this.api({
        url: `/my/national-facilities`,
        method: 'GET'
    })
}

async function getCertifiedNFs() {
    return this.api({
        url: `/national-facility/certified`,
        method: 'GET'
    })
}

async function getDisplayedStatus ( nationalFacilityId ) {
    return this.api({
        url: `/national-facility/${nationalFacilityId}/displayed-status`,
        method: 'GET'
    })
}

async function editGeneralInfo ( nationalFacilityId, options ) {
    return this.api({
        url: `/national-facility/${nationalFacilityId}/edit/general-info`,
        method: 'POST',
        data: {
            name: options.name,
            acronym: options.acronym,
            hosting_institute: options.hostingInstitute,
            pi_name: options.piName,
            pi_contact: options.piContact,
            lidar_pi_name: options.lidarPiName,
            lidar_pi_contact: options.lidarPiContact,
            photometer_pi_name: options.photometerPiName,
            photometer_pi_contact: options.photometerPiContact,
            ceilometer_pi_name: options.ceilometerPiName,
            ceilometer_pi_contact: options.ceilometerPiContact,
            picture: options.picture,
            pictures: options.pictures,
            displayed_status: options.displayed_status
        }
    })
}

async function editPicture ( nationalFacilityId, options ) {
    return this.api({
        url: `/national-facility/${nationalFacilityId}/edit/picture`,
        method: 'POST',
        data: {
            file: options.file
        }
    })
}

async function loadLidars (nationalFacilityId) {
    return this.api({
        url: `/national-facility/${nationalFacilityId}/lidars`,
        method: 'GET'
    }).then(response => {
        response.data.data.lidars = response.data.data.lidars.map(lidar => {
            let channels = {}
            Object.keys(lidar.channels).forEach(channelName => {
                channels[ channelName ] = lidar.channels[ channelName ]
                if ( channels[ channelName ].operation_start_date ) {
                    channels[ channelName ].operation_start_date = new Date(channels[ channelName ].operation_start_date)
                } else {
                    channels[ channelName ].operation_start_date = new Date()
                }
            })

            lidar.channels = channels
            return lidar
        })

        return response
        
    })
}

async function loadLidarsForUser () {
    return this.api({
        url: `/my/national-facility/lidars`,
        method: 'GET'
    }).then(response => {
        response.data.data.lidars = response.data.data.lidars.map(lidar => {
            let channels = {}
            Object.keys(lidar.channels).forEach(channelName => {
                channels[ channelName ] = lidar.channels[ channelName ]
                if ( channels[ channelName ].operation_start_date ) {
                    channels[ channelName ].operation_start_date = new Date(channels[ channelName ].operation_start_date)
                } else {
                    channels[ channelName ].operation_start_date = new Date()
                }
            })

            lidar.channels = channels
            return lidar
        })

        return response
        
    })
}

async function addLidarSystem ( nationalFacilityId, options ) {
    let lidarChannels = {}

    Object.keys(options.channels).forEach(channelName => {
        lidarChannels[ channelName ] = {
            ... options.channels[ channelName ],
            operation_start_date: Math.floor(new Date(options.channels[ channelName ].operation_start_date).getTime() / 1000) // API requires UNIX  timestamp
        }
    })


    const data = {
        name: options.name,
        
        latitude: options.latitude,
        longitude: options.longitude,
        altitude: options.altitude,
        status: options.status,
        planned_operation_date: Math.floor(new Date(options.planned_operation_date).getTime() / 1000), // API requires UNIX  timestamp
        type: options.type,
        manufacturer: options.manufacturer,
        location: options.location,
        additional_info: options.additional_info,

        pictures: options.pictures,

        laser_alignment: options.laser_alignment,
        polarisation_calibration: options.polarisation_calibration,
        telecover_measurement: options.telecover_measurement,
        dark_signal_measurement: options.dark_signal_measurement,

        photodetector_eye_piece: options.photodetector_eye_piece,
        pretrigger_20us: options.pretrigger_20us,
        t_h_control: options.t_h_control,
        lidar_status_control: options.lidar_status_control,
        eye_safety: options.eye_safety,
        unattended_operation: options.unattended_operation,
        continuous_operation: options.continuous_operation,

        additional_features: options.additional_features,

        channels: lidarChannels
    }

    return this.api({
        url: `/national-facility/${nationalFacilityId}/edit/add-lidar-system`,
        method: 'POST',
        data: data
    })
}

async function addPhotometerSystem ( nationalFacilityId, options ) {
    const data = {
        ...options,
        planned_operation_date: Math.floor(new Date(options.planned_operation_date).getTime() / 1000),

        status: this.objId(options.status),
        type: this.objId(options.type),

        data_compliance: this.objId(options.data_compliance),
        data_compliance_reviewer: this.objId(options.data_compliance_reviewer),

        general_compliance: this.objId(options.general_compliance),
        general_compliance_reviewer: this.objId(options.general_compliance_reviewer)
    }

    return this.api({
        url: `/national-facility/${nationalFacilityId}/edit/add-photometer-system`,
        method: 'POST',
        data: data
    })
}

async function loadPhotometers (nationalFacilityId) {
    return this.api({
        url: `/national-facility/${nationalFacilityId}/photometers`,
        method: 'GET'
    })
}

async function loadPhotometersForUser () {
    return this.api({
        url: `/my/national-facility/photometers`,
        method: 'GET'
    })
}

async function loadInitialAcceptanceStatus (nfId) {
    return this.api({
        url: `/national-facility/${nfId}/initial-acceptance-status`,
        method: 'GET'
    })
}

async function submitInitialAcceptanceApplication (nfId) {
    return this.api({
        url: `/national-facility/${nfId}/submit-initial-acceptance-application`,
        method: 'POST'
    })
}

async function loadSubmittedInitialAcceptanceApplications () {
    return this.api({
        url: `/national-facility/submitted-initial-acceptance-applications`,
        method: 'GET'
    })
}

async function loadInitialAcceptanceApplication (nationalFacilityId) {
    return this.api({
        url: `/national-facility/${nationalFacilityId}/initial-acceptance-application`,
        method: 'GET'
    })
}

async function getInitialAcceptanceReviewers (nationalFacilityId) {
    return this.api({
        url: `/national-facility/${nationalFacilityId}/initial-acceptance-reviewers`,
        method: 'GET'
    })
}

async function setInitialAcceptanceReviewers (nationalFacilityId, options) {
    return this.api({
        url: `/national-facility/${nationalFacilityId}/set-initial-acceptance-reviewers`,
        method: 'POST',
        data: {
            "lidar": options.lidar,
            "lidar_data": options.lidar_data,
            "photometer": options.photometer,
            "photometer_data": options.photometer_data
        }
    })
}

async function loadSynergyCompliance ( nationalFacilityId ) {
    return this.api({
        url: `/national-facility/${nationalFacilityId}/synergy-compliance`,
        method: 'GET'
    })
}

async function setSynergyCompliance (nationalFacilityId, synergyCompliance) {
    return this.api({
        url: `/national-facility/${nationalFacilityId}/edit/synergy-compliance`,
        data: {
            "synergy_compliance": synergyCompliance.compliance,
            "synergy_compliance_comments": synergyCompliance.comments,
            "synergy_compliance_recommendations": synergyCompliance.recommendations
        },
        method: 'POST'
    })
}

async function loadSynergyMatrix ( nationalFacilityId ) {
    return this.api({
        url: `/national-facility/${nationalFacilityId}/synergy-matrix`,
        method: 'GET'
    })
}

async function loadSuggestedSynergyCompliance ( nationalFacilityId ) {
    return this.api({
        url: `/national-facility/${nationalFacilityId}/suggested-synergy-compliance`,
        method: 'GET'
    })
}

async function submitSynergyCompliance (nationalFacilityId, compliance) {
    return this.api({
        url: `/national-facility/${nationalFacilityId}/submit/synergy-compliance`,
        data: {
            "synergy_compliance": compliance.compliance,
            "synergy_compliance_comments": compliance.comments,
            "synergy_compliance_recommendations": compliance.recommendations
        },
        method: 'POST'
    })
}

async function loadLidarCompliance ( nationalFacilityId ) {
    return this.api({
        url: `/national-facility/${nationalFacilityId}/lidar-compliance`,
        method: 'GET'
    })
}

async function setLidarCompliance (nationalFacilityId, compliance) {
    return this.api({
        url: `/national-facility/${nationalFacilityId}/edit/lidar-compliance`,
        data: {
            "lidar_compliance": compliance.compliance,
            "lidar_compliance_comments": compliance.comments,
            "lidar_compliance_recommendations": compliance.recommendations
        },
        method: 'POST'
    })
}

async function submitLidarCompliance (nationalFacilityId, compliance) {
    return this.api({
        url: `/national-facility/${nationalFacilityId}/submit/lidar-compliance`,
        data: {
            "lidar_compliance": compliance.compliance,
            "lidar_compliance_comments": compliance.comments,
            "lidar_compliance_recommendations": compliance.recommendations
        },
        method: 'POST'
    })
}

async function loadPhotometerCompliance ( nationalFacilityId ) {
    return this.api({
        url: `/national-facility/${nationalFacilityId}/photometer-compliance`,
        method: 'GET'
    })
}

async function setPhotometerCompliance (nationalFacilityId, compliance) {
    return this.api({
        url: `/national-facility/${nationalFacilityId}/edit/photometer-compliance`,
        data: {
            "photometer_compliance": compliance.compliance,
            "photometer_compliance_comments": compliance.comments,
            "photometer_compliance_recommendations": compliance.recommendations
        },
        method: 'POST'
    })
}

async function submitPhotometerCompliance (nationalFacilityId, compliance) {
    return this.api({
        url: `/national-facility/${nationalFacilityId}/submit/photometer-compliance`,
        data: {
            "photometer_compliance": compliance.compliance,
            "photometer_compliance_comments": compliance.comments,
            "photometer_compliance_recommendations": compliance.recommendations
        },
        method: 'POST'
    })
}

/*
async function setInitialAcceptance (nationalFacilityId, acceptance) {
    return this.api({
        url: `/national-facility/${nationalFacilityId}/edit/initial-acceptance`,
        data: {
            "acceptance": acceptance,
        },
        method: 'POST'
    })
}
*/

async function editInitialAcceptance (nationalFacilityId, options) {
    return this.api({
        url: `/national-facility/${nationalFacilityId}/edit/initial-acceptance`,
        data: {
            "preliminary_acceptance": options.preliminary_acceptance,
            "acceptance": options.acceptance,
            "user_file": options.user_file
        },
        method: 'POST'
    })
}

async function setLidarFeasibilityCompliance ( nationalFacilityId, lidarId, compliance ) {
    return  this.api({
        url: `/national-facility/${nationalFacilityId}/edit/initial-acceptance/lidar-feasibility-compliance/${lidarId}`,
        data: {
            "feasibility_compliance": compliance.compliance,
            "feasibility_compliance_comments": compliance.comments,
            "feasibility_compliance_recommendations": compliance.recommendations
        },
        method: 'POST'
    })
}

async function setLidarFeatureCompliance ( nationalFacilityId, lidarId, compliance ) {
    return  this.api({
        url: `/national-facility/${nationalFacilityId}/edit/initial-acceptance/lidar-feature-compliance/${lidarId}`,
        data: {
            "feature_compliance": compliance.compliance,
            "feature_compliance_comments": compliance.comments,
            "feature_compliance_recommendations": compliance.recommendations
        },
        method: 'POST'
    })
}

async function setLidarChannelCompliance ( nationalFacilityId, lidarId, compliance ) {
    return  this.api({
        url: `/national-facility/${nationalFacilityId}/edit/initial-acceptance/lidar-channel-compliance/${lidarId}`,
        data: {
            "channel_compliance": compliance.compliance,
            "channel_compliance_comments": compliance.comments,
            "channel_compliance_recommendations": compliance.recommendations
        },
        method: 'POST'
    })
}

async function setLidarGeneralCompliance ( nationalFacilityId, lidarId, compliance ) {
    return  this.api({
        url: `/national-facility/${nationalFacilityId}/edit/initial-acceptance/lidar-general-compliance/${lidarId}`,
        data: {
            "general_compliance": compliance.compliance,
            "general_compliance_comments": compliance.comments,
            "general_compliance_recommendations": compliance.recommendations
        },
        method: 'POST'
    })
}

async function setLidarDataCompliance ( nationalFacilityId, lidarId, compliance ) {
    return  this.api({
        url: `/national-facility/${nationalFacilityId}/edit/initial-acceptance/lidar-data-compliance/${lidarId}`,
        data: {
            "data_compliance": compliance.compliance,
            "data_compliance_comments": compliance.comments,
            "data_compliance_recommendations": compliance.recommendations
        },
        method: 'POST'
    })
}

async function submitLidarComplianceField ( nationalFacilityId, lidarId, field, options ) {
    const acceptedFields = ['feasibility', 'feature', 'channel', 'data', 'general']

    if ( !acceptedFields.includes(field) ) {
        return new Promise ();
    }

    return this.api({
        url: `/national-facility/${nationalFacilityId}/submit/initial-acceptance/lidar-${field}-compliance/${lidarId}`,
        data: options,
        method: 'POST'
    })
}

async function setPhotometerGeneralCompliance ( nationalFacilityId, photometerId, compliance ) {
    return  this.api({
        url: `/national-facility/${nationalFacilityId}/edit/initial-acceptance/photometer-general-compliance/${photometerId}`,
        data: {
            "general_compliance": compliance.compliance,
            "general_compliance_comments": compliance.comments,
            "general_compliance_recommendations": compliance.recommendations
        },
        method: 'POST'
    })
}

async function setPhotometerDataCompliance ( nationalFacilityId, photometerId, compliance ) {
    return  this.api({
        url: `/national-facility/${nationalFacilityId}/edit/initial-acceptance/photometer-data-compliance/${photometerId}`,
        data: {
            "data_compliance": compliance.compliance,
            "data_compliance_comments": compliance.comments,
            "data_compliance_recommendations": compliance.recommendations
        },
        method: 'POST'
    })
}

async function submitPhotometerComplianceField ( nationalFacilityId, photometerId, field, options ) {
    const acceptedFields = ['data', 'general']

    if ( !acceptedFields.includes(field) ) {
        return new Promise ();
    }

    return this.api({
        url: `/national-facility/${nationalFacilityId}/submit/initial-acceptance/photometer-${field}-compliance/${photometerId}`,
        data: options,
        method: 'POST'
    })
}

async function downloadInitialAcceptanceSubmissionPdf ( nationalFacilityId ) {
    return this.api({
        url: `national-facility/${nationalFacilityId}/initial-acceptance-submission-pdf`,
        method: 'GET',
        responseType: 'blob'
    })
}
async function downloadInitialAcceptanceEvaluationPdf ( nationalFacilityId ) {
    return this.api({
        url: `national-facility/${nationalFacilityId}/initial-acceptance-evaluation-pdf`,
        method: 'GET',
        responseType: 'blob'
    })
}

async function loadAllNFs () {
    return this.api({
        url: `national-facility/all`,
        method: 'GET'
    })
}

async function sendInvitation (nationalFacilityId) {
    return this.api({
        url: `national-facility/${nationalFacilityId}/send-invitation`,
        method: 'POST'
    })
}

export default {
    pluginName: 'nf',
    methods: {
        register: register,
        loadGeneralInfo: loadGeneralInfo,
        loadGeneralInfoForUser: loadGeneralInfoForUser,
        loadNfIdForUser: loadNfIdForUser,
        loadUserNfs: loadUserNfs,
        getCertifiedNFs: getCertifiedNFs,
        getDisplayedStatus: getDisplayedStatus,
        editGeneralInfo: editGeneralInfo,
        editPicture: editPicture,
        loadLidars: loadLidars,
        loadLidarsForUser: loadLidarsForUser,
        addLidarSystem: addLidarSystem,
        loadPhotometers: loadPhotometers,
        loadPhotometersForUser: loadPhotometersForUser,
        addPhotometerSystem: addPhotometerSystem,
        loadInitialAcceptanceStatus: loadInitialAcceptanceStatus,
        submitInitialAcceptanceApplication: submitInitialAcceptanceApplication,
        loadSubmittedInitialAcceptanceApplications: loadSubmittedInitialAcceptanceApplications,
        loadInitialAcceptanceApplication: loadInitialAcceptanceApplication,
        getInitialAcceptanceReviewers: getInitialAcceptanceReviewers,
        setInitialAcceptanceReviewers: setInitialAcceptanceReviewers,
        // setInitialAcceptance: setInitialAcceptance,
        editInitialAcceptance: editInitialAcceptance,
        loadSynergyCompliance: loadSynergyCompliance,
        setSynergyCompliance: setSynergyCompliance,
        loadSynergyMatrix: loadSynergyMatrix,
        loadSuggestedSynergyCompliance: loadSuggestedSynergyCompliance,
        submitSynergyCompliance: submitSynergyCompliance,
        loadLidarCompliance: loadLidarCompliance,
        setLidarCompliance: setLidarCompliance,
        submitLidarCompliance: submitLidarCompliance,
        loadPhotometerCompliance: loadPhotometerCompliance,
        setPhotometerCompliance: setPhotometerCompliance,
        submitPhotometerCompliance: submitPhotometerCompliance,
        setLidarFeasibilityCompliance: setLidarFeasibilityCompliance,
        setLidarFeatureCompliance: setLidarFeatureCompliance,
        setLidarChannelCompliance: setLidarChannelCompliance,
        setLidarGeneralCompliance: setLidarGeneralCompliance,
        setLidarDataCompliance: setLidarDataCompliance,
        submitLidarComplianceField: submitLidarComplianceField,
        setPhotometerGeneralCompliance: setPhotometerGeneralCompliance,
        setPhotometerDataCompliance: setPhotometerDataCompliance,
        submitPhotometerComplianceField: submitPhotometerComplianceField,
        downloadInitialAcceptanceSubmissionPdf: downloadInitialAcceptanceSubmissionPdf,
        downloadInitialAcceptanceEvaluationPdf: downloadInitialAcceptanceEvaluationPdf,
        loadAllNFs: loadAllNFs,
        sendInvitation: sendInvitation
    },
    apiMessages: {
        0: 'Operation successful!',
        1: 'Information loaded successfully!',
        2: 'Information saved successfully!',
        3: 'Application sent successfully!',
        1000: 'Unknown error!',
        1001: 'Permission denied!',
        1002: 'Could not load information!',
        1003: 'Could not save information!',
        1004: 'Could not register National Facility!',
        1005: 'National Facility cannot be edited because it is currently being evaluated or has already been evaluated.'
    }
}