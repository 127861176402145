<template>
    <div class="layout">
        <template v-if="nfExists">
            <div class="row mb-4 justify--center text--center">
                <div class="flex">
                    <span class="mr-2 display-5">National Facility Reports</span>
                </div>
            </div>
            <NFReportList :nfIds = "[nfId]" />
        </template>
        <template v-else>
            <div class="no_nf__wrapper row align--center text--center justify--center">
                <div class="va-typography-block flex">
                    <h1 class="no_nf__title">Not found</h1>
                    <p class="no_nf__description">Seems like there is no National Facility registered under your account.<br />No reports to display.</p>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import api from '@/lib/api'

import NFReportList from '@/components/national_facility/NFReportList.vue'

export default {
    name: 'NFViewReports',
    components: {
        NFReportList
    },
    data: () => {
        return {
            nfExists: false,
            nfId: undefined
        }
    },
    methods: {
        async getNfId () {
            const response = await api.nf.loadNfIdForUser()

            if (!response.data.data.exists) {
                this.nfExists = false
                return
            }

            this.nfId = response.data.data.id
            this.nfExists = true
        }
    },
    mounted () {
        this.getNfId()
    }
}
</script>