<template>
  <div></div>
</template>


<script>
  import {
    mapActions
  } from 'vuex'
  export default {
    pageTitle: 'Logout',
    components: {},
    data() {
      return {}
    },
    methods: {
      ...mapActions('auth', [
        'logout'
      ]),
    },
    async mounted() {
      await this.logout()
    
      this.$router.push({
        'path': '/'
      })
    }
  }
</script>