<template>
    <div class="layout gutter--xl" v-if="isAdministrator">
        <div class="row justify--center mb-4">
            <h1 class="display-1">Upload files</h1>
        </div>
        <div class="row justify--left mb-4">
            <va-button @click="uploadFiles()" :disabled="!files?.length">Upload'em!</va-button>
        </div>
        <div class="row">
            <CPFileUpload v-model="files" dropzone uploadButtonText = "Select files from your computer" class="flex xs12" />
        </div>
    </div>
</template>

<script>
import api from '@/lib/api'
import {mapActions} from 'vuex'

import CPFileUpload from '@/components/ui/file_upload/CPFileUpload.vue'

export default {
    name: 'AdminUploadFiles',
    components: {
        CPFileUpload
    },
    data: () => {
        return {
            isAdministrator: false,
            files: []
        }
    },
    methods: {
        ...mapActions('auth', ['isAdmin']),

        async uploadFiles () {
            if (!this.files.length) {
                return
            }

            await Promise.all(
                this.files.map ( file => {
                    return api.file.uploadFile (file)
                })
            )

            this.files = []
        }
    },
    async mounted() {
        this.isAdministrator = await this.isAdmin()

        console.log(this.isAdministrator)

        if (!this.isAdministrator) {
            this.$router.push({name: '404'})
        }
    }
}
</script>