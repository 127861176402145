import axios from 'axios'
import store from '@/store'

let schema = process.env.VUE_APP_API_SCHEMA || 'https://';
let host = process.env.VUE_APP_API_HOST || 'carport.inoe.ro/api';

const apiAxiosInstance = axios.create({
    baseURL: `${schema}${host}/`,
    withCredentials: true
})

const refreshAxiosInstance = axios.create({
  baseURL: `${schema}${host}/`,
  withCredentials: true
})

const refreshAccessToken = async () => {  
    let refreshResponse = await axios({
      url: `${schema}${host}/auth/refresh`,
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + store.getters['auth/refresh_token']
      }
    })

    if (!refreshResponse && !refreshResponse.data.access_token) {
        return false
    }

    store.state.auth.access_token = refreshResponse.data.access_token
}

// Add a request interceptor
apiAxiosInstance.interceptors.request.use(
  config => {
      const token = store.getters['auth/access_token'];
      if (token) {
          config.headers['Authorization'] = 'Bearer ' + token;
      }
      // config.headers['Content-Type'] = 'application/json';
      return config;
  },
  error => {
      return Promise.reject(error)
  });

// Add a request interceptor
refreshAxiosInstance.interceptors.request.use(
  config => {
      const token = store.getters['auth/access_token'];
      if (token) {
          config.headers['Authorization'] = 'Bearer ' + token;
      }
      
      return config;
  },
  error => {
      return Promise.reject(error)
  });

// Response interceptor for API calls
apiAxiosInstance.interceptors.response.use((response) => {
    return response
  }, async function (error) {
    const originalRequest = error.config;

    if ((error.response.status === 403 || error.response.status === 401) && !originalRequest._retry && originalRequest.headers.Authorization) {
      originalRequest._retry = true;
      await refreshAccessToken();
      return refreshAxiosInstance(originalRequest)
    }
    return Promise.reject(error);
  });

export default apiAxiosInstance
