<template>
    <div class="layout gutter--xs">
        <div class="row">
            <va-card-title>Feature compliance</va-card-title>
        </div>
        <div class="layout gutter--xl">
            <div class="row" v-if="suggestedCompliance">
                <div class="flex xs12">
                    <va-collapse stateful header="Feature compliance suggestions" :color="cardStripeColor(suggestedCompliance.compliance.code)">
                        <va-card outlined class="pb-1 va-typography-block">
                            <va-card-content>
                                <p v-if="suggestedCompliance.compliance.code === 'yes'">Fully compliant!</p>
                                <p v-else-if="suggestedCompliance.compliance.code === 'partially'"> Partially compliant!</p>
                                <p v-else>Required criteria not met!</p>

                                <ul class="va-unordered" v-if="suggestedCompliance.code > 0">
                                    <li :class="complianceListItemClass(suggestedCompliance.compliance.code)" v-if="suggestedCompliance.code & complianceCodes.FEATURE_NOT_OPERATIONAL">One or more required features are not operational</li>
                                    <li :class="complianceListItemClass(suggestedCompliance.compliance.code)" v-if="suggestedCompliance.code & complianceCodes.FEATURE_NOT_PLANNED">One or more required features will not be operational in the next year</li>
                                </ul>
                            </va-card-content>
                        </va-card>
                    </va-collapse>
                </div>
            </div>
            <div class="row" v-if="lidar">
                <div class="flex xs12">
                    <va-collapse stateful header="Feature information" color="primary">
                        <va-card outlined>
                            <va-card-content>
                                <div class="row justify--center">
                                    <div class="flex xs12 sm4">
                                        <va-select
                                            :options="instrumentAdditionalFunctionTypes"
                                            label="Laser Alignment + Camera"
                                            v-model="lidar.laser_alignment"
                                            track-by="_id"
                                            text-by="name"
                                            :disabled="disabled"
                                            :readonly="true"
                                        />
                                    </div>
                                    <div class="flex xs12 sm4">
                                        <va-select
                                            :options="instrumentAdditionalFunctionTypes"
                                            label="Polarisation Calibration"
                                            v-model="lidar.polarisation_calibration"
                                            track-by="_id"
                                            text-by="name"
                                            :disabled="disabled"
                                            :readonly="true"
                                        />
                                    </div>
                                    <div class="flex xs12 sm4">
                                        <va-select
                                            :options="instrumentAdditionalFunctionTypes"
                                            label="Telecover Measurement"
                                            v-model="lidar.telecover_measurement"
                                            track-by="_id"
                                            text-by="name"
                                            :disabled="disabled"
                                            :readonly="true"
                                        />
                                    </div>
                                    <div class="flex xs12 sm4">
                                        <va-select
                                            :options="instrumentAdditionalFunctionTypes"
                                            label="Dark Signal Measurement"
                                            v-model="lidar.dark_signal_measurement"
                                            track-by="_id"
                                            text-by="name"
                                            :disabled="disabled"
                                            :readonly="true"
                                        />
                                    </div>
                                    <div class="flex xs12 sm4">
                                        <va-select
                                            :options="instrumentFeatureTypes"
                                            label="Photodetector Eye Piece"
                                            v-model="lidar.photodetector_eye_piece"
                                            track-by="_id"
                                            text-by="name"
                                            :disabled="disabled"
                                            :readonly="true"
                                        />
                                    </div>
                                    <div class="flex xs12 sm4">
                                        <va-select
                                            :options="instrumentFeatureTypes"
                                            label="Pretrigger ≥20 µs"
                                            v-model="lidar.pretrigger_20us"
                                            track-by="_id"
                                            text-by="name"
                                            :disabled="disabled"
                                            :readonly="true"
                                        />
                                    </div>
                                </div>
                            </va-card-content>
                        </va-card>
                    </va-collapse>
                </div>
            </div>
        </div>
        <va-form ref="complianceForm" class="layout gutter--xl">
            <div class="row justify--center align--start">
                <div class="flex">
                    <va-select
                        label="Feature Compliance"
                        :options="complianceTypes"
                        track-by="_id"
                        text-by="name"
                        v-model="lidar.feature_compliance"
                        :rules="[rules.mandatory]"
                        :disabled="disabled"
                        :readonly="!reviewer"
                    >
                        <template #appendInner>
                            <va-popover prevent-overflow message="Criteria: first 6 mandatory features">
                                <va-icon name="info" />
                            </va-popover>
                        </template>
                    </va-select>
                </div>
            </div>
            <div class="row justify--center align--center">
                <div class="flex xs12 sm6">
                    <va-input
                        label="Feature compliance comments"
                        type="textarea"
                        v-model="lidar.feature_compliance_comments"
                        :rules="[rules.mandatory]"
                        style="width: 100%"
                        messages="Required field"
                        :disabled="disabled"
                        :readonly="!reviewer"
                    />
                </div>
                <div class="flex xs12 sm6">
                    <va-input
                        label="Feature compliance recommendations"
                        type="textarea"
                        v-model="lidar.feature_compliance_recommendations"
                        :rules="[rules.mandatory]"
                        style="width: 100%"
                        messages="Required field"
                        :disabled="disabled"
                        :readonly="!reviewer"
                    />
                </div>
            </div>
        </va-form>
    </div>
</template>

<script>
import api from '@/lib/api'
import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'FeatureomplianceForm',
    props: {
        modelValue: {
            type: Object,
            default () {
                return {
                    feature_compliance: {
                        id: '',
                        name: '',
                        code: ''
                    },
                    feature_compliance_comments: '',
                    feature_compliance_recommendations: ''
                }
            }
        },
        disabled: {type: Boolean, default: false},
        reviewer: {type: Boolean, default: false}
    },
    data () {
        return {
            suggestedCompliance: undefined,
            complianceCodes: api.lidar.FEATURE_COMPLIANCE,
            rules: {
                mandatory: (v) => !!v || "Required"
            }
        }
    },
    computed: {
        ...mapGetters ( 'types', [
            'complianceTypes',
            'instrumentAdditionalFunctionTypes',
            'instrumentFeatureTypes'
        ]),
        lidar: {
            get () { return this.modelValue },
            set (value) {this.$emit('update:modelValue', value)}
        },
        lidarComplianceColor () {
            if ( !this.lidar.feature_compliance ) {
                return 'primary'
            }

            switch ( this.lidar.feature_compliance.code ) {
                case 'yes':
                    return 'success';
                case 'partially':
                    return 'warning';
                case 'no':
                default:
                    return 'danger';
            }
        }
    },
    watch: {
    },
    methods: {
        ...mapActions ( 'types', [
            'loadComplianceTypes',
            'loadInstrumentAdditionalFunctionTypes',
            'loadInstrumentFeatureTypes'
        ]),
        validate () {
            return this.$refs.complianceForm.validate()
        },
        loadTypes () {
            if ( !this.complianceTypes.length ) {
                this.loadComplianceTypes()
            }

            if ( !this.instrumentAdditionalFunctionTypes.length ) {
                this.loadInstrumentAdditionalFunctionTypes()
            }

            if ( !this.instrumentFeatureTypes.length ) {
                this.loadInstrumentFeatureTypes()
            }
        },
        loadSuggestedCompliance () {
            api.lidar.loadSuggestedFeatureCompliance(this.lidar._id).then(response => {
                this.suggestedCompliance = response.data.data
            })
        },
        cardStripeColor(code) {
            if ( code === 'yes' ) {
                return 'success'
            }

            if ( code === 'partially' ) {
                return 'warning'
            }

            return 'danger'
        },
        complianceListItemClass(code) {
            if ( code === 'yes' ) {
                return 'compliance_list_item__green'
            }

            if ( code === 'partially' ) {
                return 'compliance_list_item__yellow'
            }

            return 'compliance_list_item__red'
        }
    },
    async mounted () {
        this.loadTypes ()
        this.loadSuggestedCompliance ()
    }
}
</script>