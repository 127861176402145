<template>
    <div class="layout gutter--xl">
        <div class="row justify--center mb-4">
            <h1 class="display-1">Login form</h1>
        </div>
        <va-form ref="login_form" @validation="formValid = $event" @submit.prevent="submitForm">
            <div class="row justify--center">
                <div class="flex xs12 sm6 md4">
                    <va-input
                        label="E-Mail"
                        v-model="emailInput"
                        :rules="emailRules"
                        type="email"
                        style="width: 100%"
                        @blur="$refs.login_form.validate()"
                        @keyup="$refs.login_form.validate()"
                        ref="emailInputField"
                    />
                </div>
                <div class="flex xs12 sm6 md4">
                    <va-input
                        label="Password"
                        v-model="passwordInput"
                        :rules="passwordRules"
                        type="password"
                        style="width: 100%"
                        @keyup="$refs.login_form.validate()"
                        @blur="$refs.login_form.validate()"
                    />
                </div>
                <div class="flex text--center">
                    <va-button type="submit" color="primary" :disabled="!formValid" @click="submitForm">Login</va-button>
                </div>
            </div>
            <div class="row va-typography justify--center">
                <div class="flex">
                    <p>Forgot your password? Click <router-link :to="{name: 'ResetPassword'}">here</router-link> to request a password reset</p>
                </div>
            </div>
        </va-form>
        <div class="row justify--center" v-if="error">
            <div class="flex xs12 sm6">
                <va-alert color="danger" title="Could not log in!">{{error}}</va-alert>
            </div>
        </div>
    </div>
</template>

<script>
  import {mapActions} from 'vuex'

  export default {
    name: 'LoginView',
    data: () => {
        return {
            formValid: false,
            loginFailed: false,
            error: undefined,
            emailInput: '',
            passwordInput: '',
            emailRules: [ v => /^[^\s@]+@[^\s@]+$/.test(v) || 'Invalid E-Mail address' ],
            passwordRules: [ v => v != "" || "Please enter your password" ]
        }
    },
    computed: {
    },
    methods: {
        ...mapActions('auth', ['login']),

        async submitForm () {
            this.loginFailed = false;

            try {
                await this.login({email: this.emailInput, password: this.passwordInput})
                
                if (this.$route.query.from !== undefined) {
                    this.$router.push({'path': this.$route.query.from})
                } else {
                    this.$router.push({'path': '/'})
                }
            // show error notification
            } catch (err) {
                try {
                    this.error = err.response.data.msg
                } catch {
                    this.error = "Unknown error!"
                }
            }
        }
    },
    async mounted() {
    },
    beforeRouteLeave (to, from, next) {
        this.$vaToast.closeAll()
        next()
    }
  }
</script>