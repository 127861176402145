<template>
    <div class="app__navbar">
      <va-navbar color="primary" shape>
        <template #left>
          <va-navbar-item class="left" v-if="isLoggedIn">
            <va-icon
              name="menu_open"
              :class="{'x-flip': isSidebarMinimized}"
              color="white"
              class="left"
              @click="setSidebarMinimized(!isSidebarMinimized)"
            />
          </va-navbar-item>
          <va-navbar-item>
            <span class="display-6" style="color: var(--va-white);">
              CARPORT
            </span>
          </va-navbar-item>
        </template>
        <template #right>
          <va-navbar-item>
            <ProfileDropdown v-if="isLoggedIn" :options="profileNavigationOptions">
                {{this.userinfo.first_name}} {{this.userinfo.last_name}}
            </ProfileDropdown>
            <va-button v-else flat :to="{name: 'Login'}" color="white">Login
            </va-button>
          </va-navbar-item>
        </template>
      </va-navbar>
    </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'
  import ProfileDropdown from './components/ProfileDropdown.vue'

  export default {
    name: 'NavigationBar',
    components: {
      ProfileDropdown
    },
    data: () => ({
      profileNavigationOptions: [
        { name: 'Change Password', route: 'ChangePassword' },
        { name: 'Logout', route: 'Logout' }
      ]
    }),
    computed: {
      ...mapGetters('auth', ['isLoggedIn', 'userinfo']),
      ...mapGetters('ui', ['isSidebarMinimized', 'isMobile'])
    },
    methods: {
      ...mapActions('ui', ['setSidebarMinimized'])
    },
    async mounted() {
    }
  }
</script>

<style lang="scss">
  .x-flip {
    transform: scaleX(-100%);
  }

  .va-navbar {
    z-index: 1000;

    &__left {
      justify-content: start !important;
    }
  }
</style>