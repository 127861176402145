<template>
    <div class="layout gutter--xl">
        <div class="row justify--center">
            <div class="flex">
                <NFPicker v-model="nfId" step="initial-acceptance" type="submitted"/>
            </div>
        </div>
        <div class="row justify--center align--center">
            <div class="row">
                <div class="flex xs12 justify--center">
                    <va-alert icon="info" color="white" class="va-typography-block">
                        <template #title>
                            Instructions
                        </template>
                        <p v-if="!nfId">
                        Select the National Facility you want to evaluate from the dropdown above. Only National Facilities that have submitted their applications will
                        be shown in the list.
                        </p>
                        <template v-else>
                            <p>
                            Navigate between the various components of the National Facility using the tabs below.
                            <strong>Your evaluation will be saved only upon clicking the Save button!</strong>
                            You can also download the National Facility information in PDF format:
                            </p>
                            <div class="row justify--center">
                                <va-button class="flex" v-if="submitted" icon="description" flat @click="downloadPdf()">Download Application (PDF)</va-button>
                                <va-button class="flex" v-if="initialAcceptance.user_file" icon="description"  flat @click="downloadUpgradePlan">Download upgrade plan (PDF)</va-button>
                                <va-button class="flex" v-if="evaluated" icon="description" flat @click="downloadReport">Download evaluation report (PDF)</va-button>
                            </div>
                        </template>
                    </va-alert>
                </div>
            </div>
        </div>
        <template v-if="nfId">
            <div class="row justify--center" v-if="userIsGeneralReviewer && nfId">
                <div class="flex xs12">
                    <div class="row justify--center">
                    </div>
                    <va-form ref="preliminaryReportForm" class="row justify--center" v-if="!!initialAcceptance.can_issue_preliminary_report">
                        <div class="flex">
                            <va-select
                                label="Preliminary Acceptance"
                                :options="acceptanceTypes"
                                track-by="_id"
                                text-by="name"
                                v-model="initialAcceptance.preliminary_acceptance"
                                :rules="[rules.mandatory]"
                                messages="Required field"
                                :disabled="!initialAcceptance.can_issue_preliminary_report"
                            />
                        </div>
                        <div class="flex">
                            <va-button :disabled="!initialAcceptance.can_issue_preliminary_report" @click="preliminaryReportCompliance" :color="overallComplianceColor">Submit</va-button>
                        </div>
                        <div class="flex align-content-center" v-if="!initialAcceptance.can_issue_preliminary_report">
                            <p class="flex">Cannot submit preliminary report at this time.</p>
                        </div>
                    </va-form>
                    <va-form ref="finalReportForm" class="row justify--center">
                        <div class="flex">
                            <va-select
                                label="Final Acceptance"
                                :options="acceptanceTypes"
                                track-by="_id"
                                text-by="name"
                                v-model="initialAcceptance.acceptance"
                                :rules="[rules.mandatory]"
                                messages="Required field"
                                :readonly="!initialAcceptance.can_issue_final_report"
                            />
                        </div>
                        <div class="flex">
                            <va-button :disabled="!initialAcceptance.can_issue_final_report" @click="finalReportCompliance" :color="overallComplianceColor">Submit</va-button>
                        </div>
                        <div class="flex align-content-center" v-if="!initialAcceptance.can_issue_final_report">
                            <p class="flex" v-if="!evaluated">Cannot submit final report at this time.</p>
                        </div>
                    </va-form>
                </div>
            </div>
            <div class="row" v-if="tabs.length">
                <div class="flex xs12 pb-0 pl-0 pr-0">
                    <va-tabs v-model="selectedTab" grow class="colored_tabs">
                        <template #tabs>
                        <va-tab
                            v-for="tab in tabs"
                            :key="tab.id"
                            :name="tab.id"
                        >
                            {{tab.title}}
                        </va-tab>
                        </template>
                    </va-tabs>
                </div>
            </div>
            <div class="row justify--center" v-if="selectedTab">
                <va-card outlined square class="flex xs12">
                    <va-card-content>
                        <component :is="activeTab.component" v-bind="activeTabComponentProps"></component>
                    </va-card-content>
                </va-card>
            </div>
        </template>
        <va-modal
            ref="preliminaryReportComplianceModal"
            stateful
            title="Confirm overall compliance change"
            @ok="submitPreliminaryReport"
        >
            
            <div class="va-typography-block">
                <p>
                Are you sure you want to change the overall compliance to <strong>{{initialAcceptance.preliminary_acceptance.name}}</strong> for this National Facility?
                </p>
                <p><strong>Once you set an overall compliance you will not be able to re-evaluate this National Facility until an administrator resets the evaluation process.</strong></p>
            </div>
        </va-modal>
        <va-modal
            ref="finalReportComplianceModal"
            stateful
            title="Confirm overall compliance change"
            @ok="submitFinalReport"
        >
            
            <div class="va-typography-block">
                <p>
                Are you sure you want to change the overall compliance to <strong>{{initialAcceptance.acceptance.name}}</strong> for this National Facility?
                </p>
                <p><strong>Once you set an overall compliance you will not be able to re-evaluate this National Facility until an administrator resets the evaluation process.</strong></p>
            </div>
        </va-modal>
    </div>
</template>

<script>
import {shallowRef} from 'vue'

import NFPicker from '@/components/national_facility/cars/NFPicker.vue'
import NFGeneralInfo from '@/components/national_facility/cars/initial_acceptance/NFGeneralInfo.vue'
import NFLidars from '@/components/national_facility/cars/initial_acceptance/NFLidars.vue'
import NFPhotometers from '@/components/national_facility/cars/initial_acceptance/NFPhotometers.vue'

import api from '@/lib/api'

import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'CarsEditNFInitialAcceptance',
    props: {
        id: { type: String, default () {''} }
    },
    components: {
        NFPicker
    },
    data () {
        return {
            nfId: this.id,
            loading: true,
            tabs: [],
            selectedTab: undefined,
            overallCompliance: undefined,
            initialAcceptance: {
                id: '',
                name: '',
                code: ''
            },
            rules: {
                mandatory: (v) => !!v || "Required"
            }
        }
    },
    computed: {
        ...mapGetters('auth', ['userinfo']),
        ...mapGetters('types', ['acceptanceTypes']),
        activeTab () {
            return this.tabs.find(({id}) => id == this.selectedTab) || undefined;
        },
        activeTabComponentProps () {
            switch (this.selectedTab) {
                case 'synergy': return { id: this.nfId, reviewer: this.userIsSynergyReviewer }
                case 'lidar': return { id: this.nfId, reviewer: this.userIsReviewing('lidar'), dataReviewer: this.userIsReviewing('lidar_data') }
                case 'photometer': return { id: this.nfId, reviewer: this.userIsReviewing('photometer'), dataReviewer: this.userIsReviewing('photometer_data') }
                default: return {}
            }
        },
        overallComplianceColor () {
            if ( !this.overallCompliance ) {
                return 'primary'
            }

            switch ( this.overallCompliance.code ) {
                case 'yes':
                    return 'success';
                case 'conditionally':
                    return 'warning';
                case 'no':
                default:
                    return 'danger';
            }
        },
        isAdmin () {
            return this.userinfo.permissions.filter(r => r === 'administrator').length > 0
        },
        userIsGeneralReviewer () {
            try {
                return this.isAdmin
            } catch {
                return false
            }
        },
        userIsSynergyReviewer () {
            return this.userIsGeneralReviewer
        },
        evaluated () {
            try {
                return !!this.initialAcceptance.evaluation_date
            } catch {
                return false
            }
        },
        submitted () {
            try {
                switch ( this.initialAcceptance.status.code ) {
                    case 'submitted':
                    case 'preliminary_report_issued':
                    case 'waiting_final_report':
                    case 'evaluated': {
                        return true
                    }
                    default: {
                        return false
                    }
                    
                }
            } catch {
                return false
            }
        },
        canIssueInitialReport () {
            try {
                return this.initialAcceptance.can_issue_preliminary_report
            } catch {
                return false
            }
        },
        canIssueFinalReport () {
            try {
                return this.initialAcceptance.status.code == 'waiting_final_report'
            } catch {
                return false
            }
        }
    },
    methods: {
        ...mapActions('types', ['loadAcceptanceTypes']),
        preliminaryReportCompliance () {
            const formValid = this.$refs.preliminaryReportForm.validate()
            if ( !formValid ) {
                return;
            }

            this.$refs.preliminaryReportComplianceModal.show()
        },
        finalReportCompliance () {
            const formValid = this.$refs.finalReportForm.validate()
            if ( !formValid ) {
                return;
            }

            this.$refs.finalReportComplianceModal.show()
        },
        loadOverallCompliance () {
            api.nf.loadOverallCompliance ( this.nfId ).then ( response => {
                this.overallCompliance = response.data.data
            })
        },
        async submitPreliminaryReport () {
            if (!this.id) {
                return
            }

            await api.nf.editInitialAcceptance ( this.id, {preliminary_acceptance: this.initialAcceptance.preliminary_acceptance } )

            this.$router.go()
        },
        async submitFinalReport () {
            if (!this.id) {
                return
            }

            await api.nf.editInitialAcceptance ( this.id, {acceptance: this.initialAcceptance.acceptance } )
            
            this.$router.go()
        },
        async loadInitialAcceptance () {
            if ( this.nfId ) {
                const response = await api.nf.loadInitialAcceptanceApplication ( this.nfId )
                
                this.initialAcceptance = response.data.data
            }
        },
        userIsReviewing ( component ) {
            try {
                return this.initialAcceptance.component_reviewers[ component ]._id === this.userinfo.id || this.isAdmin
            } catch {
                return this.isAdmin
            }
        },
        mountTabs () {
            this.tabs = []

            if ( this.userIsGeneralReviewer ) {
                this.tabs = this.tabs.concat ({ id: 'synergy', title: 'Synergy', component: shallowRef(NFGeneralInfo) })
            }

            if ( this.isAdmin || this.userIsGeneralReviewer || this.userIsReviewing('lidar') || this.userIsReviewing('lidar_data') ) {
                this.tabs = this.tabs.concat ({ id: 'lidar', title: 'Lidars', component: shallowRef(NFLidars) })
            }

            if ( this.isAdmin || this.userIsGeneralReviewer || this.userIsReviewing('photometer') || this.userIsReviewing('photometer_data') ) {
                this.tabs = this.tabs.concat ({ id: 'photometer', title: 'Photometers', component: shallowRef(NFPhotometers) })
            }

            if ( this.tabs.length && !this.activeTab ) {
                this.selectedTab = this.tabs[0].id
            }
        },
        downloadPdf () {
            api.nf.downloadInitialAcceptanceSubmissionPdf ( this.nfId ).then(response => {
                const blob = new Blob([response.data], { type: response.headers['content-type'] })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = response.headers['content-disposition'].split('filename=')[1].split(';')[0];
                link.click()
                URL.revokeObjectURL(link.href)
            }).catch(console.error)
        },
        async downloadUpgradePlan () {
            await api.file.downloadFile ( this.initialAcceptance.user_file )
        },
        downloadReport () {
            api.nf.downloadInitialAcceptanceEvaluationPdf ( this.nfId ).then(response => {
                const blob = new Blob([response.data], { type: response.headers['content-type'] })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = response.headers['content-disposition'].split('filename=')[1].split(';')[0];
                link.click()
                URL.revokeObjectURL(link.href)
            }).catch(console.error)
        }
    },
    watch: {
        async nfId () {
            this.$router.push({name: 'CarsEditNFInitialAcceptance', params: { id: this.nfId }})
            await this.loadInitialAcceptance ()
            this.mountTabs()
        }
    },
    async mounted() {
        if (!this.acceptanceTypes.length) {
            this.loadAcceptanceTypes()
        }

        await this.loadInitialAcceptance ()
        this.mountTabs()
    }
}
</script>

<style lang="scss">
.va-tabs.colored_tabs {
    background-color: #ddd;

    & .va-tabs__container {
        height: 64px;

        & .va-tabs__tabs {
            height: 64px;
        }
    }

    & .va-tab {
        padding-top: 2rem;
        padding-bottom: 2rem;
        height: 24px;

        & {
            .va-tab__content {
                height: 64px;
            }
        }
    }
}
</style>