// import Vue from 'vue'
import { createStore } from 'vuex'
import auth from '@/store/modules/auth'
import user from '@/store/modules/user'
import ui from '@/store/modules/ui'
import types from '@/store/modules/types'

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    user,
    ui,
    types
  }
})