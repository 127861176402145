<template>
    <div class="gutter--xl" style="padding-left: 50px; padding-right: 50px;">
        <div class="row justify--center mb-4">
            <h1 class="display-1">Edit National Facilities</h1>
        </div>
        <div class="row justify--center">
        </div>
        <div class="row">
            <va-input v-model="tableFilter" placeholder="Search..." class="flex xs12 md3" label="Search table" />
            <va-button-dropdown label="Add new NF" :close-on-content-click="false" placement="bottom" class="flex">
                    <va-form ref="register_nf_form" class="layout gutter--xl" @submit.prevent>
                        <div class="row justify--center">
                            <div class="flex">
                                <va-input type="text" v-model="newNfInfo.acronym" label="Acronym" :rules="NFAcronymRules"/>
                            </div>
                        </div>
                        <div class="row justify--center">
                            <div class="flex">
                                <va-select
                                :options="nfLeaders"
                                :text-by="nameFromUserObject"
                                v-model="newNfInfo.owner"
                                :rules="[rules.mandatory]"
                                track-by="_id"
                                label="NF Leader"
                                searchable
                                no-options-text="No suitable users found"
                                />
                            </div>
                        </div>
                        <div class="row justify--center">
                            <div class="flex">
                                <va-select
                                :options="nfTypes"
                                text-by="name"
                                v-model="newNfInfo.type"
                                :rules="[rules.mandatory]"
                                track-by="_id"
                                label="NF Type"
                                searchable
                                no-options-text="No types available"
                                />
                            </div>
                        </div>
                        <div class="row justify--center">
                            <div class="flex">
                                <va-button type="submit" @click="submitRegisterNF()">Register new NF</va-button>
                            </div>
                        </div>
                        </va-form>
            </va-button-dropdown>
        </div>
        <div class="row">
            <div class="flex xs12">
                <va-data-table
                    :items="nfList"
                    :loading="tableLoading"
                    :columns="tableColumns"
                    :filter="tableFilter"
                    striped
                >
                    <template #cell(actions)="{rowData}">
                        <va-button icon="edit" :to="{name: 'EditNF', params: {id: rowData._id}}" size="small">Edit</va-button>
                        <va-button
                            v-if="rowData.submissions['initial_acceptance'].status?.code == 'registered'"
                            @click="confirmInviteNf(rowData._id)"
                            icon="mail"
                            size="small"
                        >Invite</va-button>
                        <va-button
                            v-else-if="rowData.submissions['initial_acceptance'].status?.code == 'invited'"
                            @click="confirmInviteNf(rowData._id)"
                            icon="mail"
                            size="small"
                            flat
                        >Re-invite</va-button>
                    </template>
                    <template #cell(acronym)="{rowData}">
                        <strong>{{ rowData.acronym }}</strong>
                        <template v-if="rowData.submissions['initial_acceptance']">
                            <br />
                            {{rowData.submissions["initial_acceptance"].status?.name}}
                        </template>
                    </template>
                    <template #cell(type)="{rowData}">
                        <span v-if="rowData.type?.name">{{ rowData.type.name }}</span>
                        <span v-else>N/A</span>
                    </template>
                    <template #cell(pi)="{rowData}">
                        <a :href='`mailto:${ rowData.pi_contact }`'>{{ rowData.pi_name }}</a>
                    </template>
                    <template #cell(reviewers)="{rowData}">
                        <template v-if="rowData.submissions?.initial_acceptance?.date">
                            <ReviewersPicker
                                :id="rowData._id"
                                :label='rowData.submissions["initial_acceptance"].reviewers_set ? "Change reviewers" : "Set reviewers"'
                                size="small"
                                :availableLidarReviewers="lidarReviewers"
                                :availableLidarDataReviewers="lidarDataReviewers"
                                :availablePhotometerReviewers="photometerReviewers"
                                :availablePhotometerDataReviewers="photometerDataReviewers"
                                :color="reviewersPickerColor(rowData.submissions['initial_acceptance'].reviewers_set)"
                                style="margin-bottom: 10px; display: block;"
                            />
                        </template>
                        <span v-if="!rowData.submissions?.initial_acceptance?.reviewers_set">N/A</span>
                        <template v-else>
                            <p>
                                Lidar:
                                <span v-if="rowData.submissions?.initial_acceptance?.component_reviewers?.lidar">
                                    <strong>{{rowData.submissions?.initial_acceptance?.component_reviewers?.lidar?.first_name}} {{rowData.submissions?.initial_acceptance?.component_reviewers?.lidar?.last_name}}</strong>
                                </span>
                            </p>
                            <p>
                                Lidar data:
                                <span v-if="rowData.submissions?.initial_acceptance?.component_reviewers?.lidar_data">
                                    <strong>{{rowData.submissions?.initial_acceptance?.component_reviewers?.lidar_data?.first_name}} {{rowData.submissions?.initial_acceptance?.component_reviewers?.lidar_data?.last_name}}</strong>
                                </span>
                            </p>
                            <p>
                                Photometer:
                                <span v-if="rowData.submissions?.initial_acceptance?.component_reviewers?.photometer">
                                    <strong>{{rowData.submissions?.initial_acceptance?.component_reviewers?.photometer?.first_name}} {{rowData.submissions?.initial_acceptance?.component_reviewers?.photometer?.last_name}}</strong>
                                </span>
                            </p>
                            <p>
                                Photometer data:
                                <span v-if="rowData.submissions?.initial_acceptance?.component_reviewers?.photometer_data">
                                    <strong>{{rowData.submissions?.initial_acceptance?.component_reviewers?.photometer_data?.first_name}} {{rowData.submissions?.initial_acceptance?.component_reviewers?.photometer_data?.last_name}}</strong>
                                </span>
                            </p>
                        </template>
                    </template>
                    <template #cell(description)="{rowData}">
                        <span v-if="rowData.submissions?.initial_acceptance?.date">
                            {{ new Date(rowData.submissions?.initial_acceptance?.date).toISOString().slice(0, 10) }}
                        </span>
                        <span v-else>N/A</span>
                    </template>
                    <template #cell(preliminary_report)="{rowData}">
                        <va-button
                            v-if="rowData.submissions?.initial_acceptance?.preliminary_report"
                            @click="downloadFile(rowData.submissions['initial_acceptance'].preliminary_report)"
                            size="small"
                        >
                            Preliminary report
                        </va-button>
                        <span v-else>N/A</span>
                    </template>
                    <template #cell(upgrade_plan)="{rowData}">
                        <va-button
                            v-if="rowData.submissions?.initial_acceptance?.user_file"
                            @click="downloadFile(rowData.submissions['initial_acceptance'].user_file)"
                            size="small"
                        >
                            Upgrade plan
                        </va-button>
                        <span v-else>N/A</span>
                    </template>
                    <template #cell(final_report)="{rowData}">
                        <va-button
                            v-if="rowData.submissions?.initial_acceptance?.final_report"
                            @click="downloadFile(rowData.submissions['initial_acceptance'].final_report)"
                            size="small"
                        >
                            Final report
                        </va-button>
                        <span v-else>N/A</span>
                    </template>
                    <template #cell(initial_acceptance)="{rowData}">
                        <span v-if="rowData.submissions['initial_acceptance']" class="mr-2">{{rowData.submissions["initial_acceptance"].status?.name}}</span>
                        <va-button
                            v-if="!!rowData.submissions['initial_acceptance'].user_file"
                            @click="downloadFile(rowData.submissions['initial_acceptance'].user_file)"
                            icon="description"
                            size="small"
                        >Upgrade plan</va-button>
                    </template>
                </va-data-table>
            </div>
            <va-modal
                stateful
                ref="confirmInviteModal"
                @ok="inviteNf()"
            >
                Are you sure you want to send the invitation email?
            </va-modal>
        </div>
    </div>
</template>

<script>
import {mapActions} from 'vuex'

import ReviewersPicker from '@/components/national_facility/cars/initial_acceptance/ReviewersPicker.vue'

import api from '@/lib/api'

const ROLE_LIDAR_REVIEWER = "Lidar Reviewer"
const ROLE_LIDAR_DATA_REVIEWER = "Lidar Data Reviewer"
const ROLE_PHOTOMETER_REVIEWER = "Photometer Reviewer"
const ROLE_PHOTOMETER_DATA_REVIEWER = "Photometer Data Reviewer"

  export default {
    name: 'AdminEditNFs',
    components: {
        ReviewersPicker
    },
    data: () => {
        return {
            isAdministrator: false,
            newNfInfo: { acronym: '', owner: undefined, type: undefined },
            nfToInvite: undefined,
            nfLeaders: [],
            nfList: [],
            nfTypes: [],
            tableLoading: true,
            tableFilter: '',
            tableColumns: [
                {
                    key: 'actions',
                    label: '',
                }, {
                    key: 'acronym',
                    label: 'Acronym'
                }, {
                    key: 'type',
                    label: 'Type'
                }, {
                    key: 'pi',
                    label: 'PI'
                }, {
                    key: 'reviewers',
                    label: 'Reviewers'
                }, {
                    key: 'description',
                    label: 'Description'
                }, {
                    key: 'preliminary_report',
                    label: 'Preliminary Report'
                }, {
                    key: 'upgrade_plan',
                    label: 'Upgrade plan'
                }, {
                    key: 'final_report',
                    label: 'Final report'
                }
                /*
                , {
                    key: 'hosting_institute',
                    label: 'Hosting Institute'
                }, {
                    key: 'initial_acceptance',
                    label: 'Initial Acceptance'
                }
                */
            ],
            NFAcronymRules: [ v => /^[a-zA-Z0-9-_]+$/.test(v) || "Acronym must be alphanumeric", v => v.length <= 24 || "Acronym must be maximum 24 characters long" ],
            rules: {
                mandatory: v => !!v
            },
            lidarReviewers: [],
            lidarDataReviewers: [],
            photometerReviewers: [],
            photometerDataReviewers: [],
        }
    },
    methods: {
        ...mapActions('auth', ['isAdmin']),
        
        reviewersPickerColor (reviewers_set) {
            if (reviewers_set) {
                return 'success';
            }

            return 'danger'
        },
        async updateNfTable () {
            this.tableLoading = true

            const response = await api.nf.loadAllNFs ()
            this.nfList = response.data.data

            this.tableLoading = false
        },
        confirmInviteNf (id) {
            this.nfToInvite = id;
            this.$refs.confirmInviteModal.show()
        },
        async inviteNf () {
            await api.nf.sendInvitation (this.nfToInvite)
            this.nfToInvite = undefined;
            this.updateNfTable()
            this.$refs.confirmInviteModal.hide()
        },
        nameFromUserObject (user) {
          try {
            return `${user.first_name} ${user.last_name}`
          } catch {
            return "Unknown user"
          }
        },
        loadNfLeaders () {
            api.user.loadUsersWithoutNf().then(response => {
                this.nfLeaders = response.data.data
            })
        },
        async submitRegisterNF () {
            let formValid = this.$refs.register_nf_form.validate()

            if (!formValid) {
                return
            }

            await api.nf.register ( this.newNfInfo )
            this.updateNfTable()
        },
        async loadAvailableReviewers () {
            api.user.loadUsersWithRoleName (ROLE_LIDAR_REVIEWER).then(response => {
                this.lidarReviewers = response.data.data
            })

            api.user.loadUsersWithRoleName (ROLE_LIDAR_DATA_REVIEWER).then(response => {
                this.lidarDataReviewers = response.data.data
            })
            api.user.loadUsersWithRoleName (ROLE_PHOTOMETER_REVIEWER).then(response => {
                this.photometerReviewers = response.data.data
            })
            api.user.loadUsersWithRoleName (ROLE_PHOTOMETER_DATA_REVIEWER).then(response => {
                this.photometerDataReviewers = response.data.data
            })
        },
        async loadNfTypes () {
            api.types.loadNfTypes ().then(response => {
                this.nfTypes = response.data.data
            })
        },
        async downloadFile ( fileId ) {
            await api.file.downloadFile ( fileId )
        }
    },
    async mounted() {
        this.isAdministrator = await this.isAdmin()

        if (!this.isAdministrator) {
            this.$router.push({name: '404'})
        }

        await this.updateNfTable()
        this.loadNfLeaders()
        this.loadAvailableReviewers()
        this.loadNfTypes()
    }
  }
</script>