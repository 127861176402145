<template>
    <div class="nf_picker">
        <va-select
            searchable
            v-model="nationalFacility"
            :options="nationalFacilities"
            text-by="pretty_name"
            value-by="id"
            track-by="id"
            placeholder="Select National Facility"
        >
        </va-select>
    </div>
</template>

<script>
import api from '@/lib/api'
export default {
    name: 'NFPicker',
    props: {
        modelValue: { type: String, default () {undefined} },
        step: { type: String, default: 'initial-acceptance' },
        type: { type: String, default: 'submitted' }
    },
    emits: ['update:modelValue'],
    data () {
        return {
            nationalFacilities: []
        }
    },
    computed: {
        nationalFacility: {
            get () { return this.modelValue },
            set (value) {this.$emit('update:modelValue', value)}
        }
    },
    methods: {
    },
    async mounted () {
        // Load National Facilities:

        const apiCall = {
            'initial-acceptance': {
                'submitted': api.nf.loadSubmittedInitialAcceptanceApplications
            }
        }

        apiCall [ this.step ][ this.type ]().then((response) => {
            this.nationalFacilities = response.data.data
        })
    }
}
</script>

<style lang="scss">
.nf_picker {
    --nf_picker-text-font-size: 1.8rem;
    --nf_picker-text-line-height: 32px;
    --nf_picker-label-line-height: 14px;
    $tabletBreakPointPX: 768px;

    & .va-input-wrapper__text {
        color: var(--nf_picker-va-primary);
        font-weight: bold;

        & .va-select-anchor__placeholder {
            color: var(--nf_picker-va-primary);
            text-transform: uppercase;
        }
    }

    @media screen and (min-width: $tabletBreakPointPX) {
        & .va-input-wrapper__container {
            line-height: var(--nf_picker-text-line-height);
            min-width: 500px;
        }

        & .va-input-wrapper--nf_picker-labeled .va-input-wrapper__label {
            font-size: .8rem;
            height: var(--nf_picker-label-line-height);
            font-weight: normal;
            text-transform: none;
            text-align: center;
        }

        & .va-input-wrapper__container .va-input-wrapper__text {
            padding-top: var(--nf_picker-label-line-height);
            padding-bottom: var(--nf_picker-label-line-height);
            min-height: var(--nf_picker-text-line-height);
            font-size: var(--nf_picker-text-font-size);
            justify-content: center;
        }
    }
}
</style>