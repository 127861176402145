<template>
    <div class="layout gutter--xs">
        <div class="row">
            <va-card-title>Feasibility compliance</va-card-title>
        </div>
        <div class="layout gutter--xl">
            <div class="row" v-if="suggestedCompliance">
                <div class="flex xs12">
                    <va-collapse stateful header="Feasibility compliance suggestions" :color="cardStripeColor(suggestedCompliance.compliance.code)">
                        <va-card outlined class="pb-1 va-typography-block">
                            <va-card-content>
                                <p v-if="suggestedCompliance.compliance.code === 'yes'">Fully compliant!</p>
                                <p v-else-if="suggestedCompliance.compliance.code === 'partially'"> Partially compliant!</p>
                                <p v-else>Required criteria not met!</p>
                                <ul class="va-unordered" v-if="suggestedCompliance.code > 0">
                                    <li :class="complianceListItemClass(suggestedCompliance.compliance.code)" v-if="suggestedCompliance.code & complianceCodes.NOT_OPEN_DESIGN">Lidar system might not have an open design. Please contact the PI for details.</li>
                                    <li :class="complianceListItemClass(suggestedCompliance.compliance.code)" v-if="suggestedCompliance.code & complianceCodes.NOT_PROPERLY_ENCLOSED">Lidar system might not have a properly enclosed design. Please contact the PI for details.</li>
                                    <li :class="complianceListItemClass(suggestedCompliance.compliance.code)" v-if="suggestedCompliance.code & complianceCodes.NOT_OPERATIONAL_IN_1_YEAR">Lidar system will not be operational in the next year</li>
                                </ul>
                            </va-card-content>
                        </va-card>
                    </va-collapse>
                </div>
            </div>
            <div class="row">
                <div class="flex xs12">
                    <va-collapse stateful header="Feasibility information" color="primary">
                        <va-card outlined>
                            <va-card-content>
                                <div class="row justify--center">
                                    <div class="flex xs12">
                                        <va-input
                                            type="text"
                                            label="Instrument Manufacturer"
                                            v-model="lidar.manufacturer"
                                            style="width: 100%"
                                            :disabled="disabled"
                                            :readonly="true"
                                            :ref="registerForceUpdateElement"
                                        />
                                    </div>
                                    <div class="flex xs12 sm4">
                                        <va-select
                                            :options="instrumentTypes"
                                            label="Instrument Type"
                                            v-model="lidar.type"
                                            track-by="_id"
                                            text-by="name"
                                            :disabled="disabled"
                                            :readonly="true"
                                            style="width: 100%"
                                        />
                                    </div>
                                    <div class="flex xs12 sm4">
                                        <va-select
                                            :options="instrumentLocationTypes"
                                            label="Instrument Location"
                                            v-model="lidar.location"
                                            track-by="_id"
                                            text-by="name"
                                            :disabled="disabled"
                                            :readonly="true"
                                            style="width: 100%"
                                        />
                                    </div>
                                    <div class="flex xs12 sm4">
                                        <va-date-input
                                            v-model="lidar.planned_operation_date"
                                            label="Planned operation date"
                                            manual-input
                                            :disabled="disabled"
                                            :readonly="true"
                                            style="width: 100%"
                                        />
                                    </div>
                                </div>
                            </va-card-content>
                        </va-card>
                    </va-collapse>
                </div>
            </div>
        </div>
        <va-form ref="complianceForm" class="layout gutter--xl">
            <div class="row justify--center align--start">
                <div class="flex">
                    <va-select
                        label="Feasibility Compliance"
                        :options="complianceTypes"
                        track-by="_id"
                        text-by="name"
                        v-model="lidar.feasibility_compliance"
                        :rules="[rules.mandatory]"
                        :disabled="disabled"
                        :readonly="!reviewer"
                    >
                        <template #appendInner>
                            <va-popover prevent-overflow message="Criteria: operational in max. 1 year, open design, properly enclosed">
                                <va-icon name="info" />
                            </va-popover>
                        </template>
                    </va-select>
                </div>
            </div>
            <div class="row justify--center align--center">
                <div class="flex xs12 sm6">
                    <va-input
                        label="Feasibility compliance comments"
                        type="textarea"
                        v-model="lidar.feasibility_compliance_comments"
                        :rules="[rules.mandatory]"
                        style="width: 100%"
                        messages="Required field"
                        :disabled="disabled"
                        :readonly="!reviewer"
                    />
                </div>
                <div class="flex xs12 sm6">
                    <va-input
                        label="Feasibility compliance recommendations"
                        type="textarea"
                        v-model="lidar.feasibility_compliance_recommendations"
                        :rules="[rules.mandatory]"
                        style="width: 100%"
                        messages="Required field"
                        :disabled="disabled"
                        :readonly="!reviewer"
                    />
                </div>
            </div>
        </va-form>
    </div>
</template>

<script>
import api from '@/lib/api'
import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'FeasibilityComplianceForm',
    props: {
        modelValue: {
            type: Object,
            default () {
                return {
                    feasibility_compliance: {
                        id: '',
                        name: '',
                        code: ''
                    },
                    feasibility_compliance_comments: '',
                    feasibility_compliance_recommendations: '',
                    manufacturer: ''
                }
            }
        },
        disabled: {type: Boolean, default: false},
        reviewer: {type: Boolean, default: false}
    },
    data: () => {
        return {
            suggestedCompliance: undefined,
            complianceCodes: api.lidar.FEASIBILITY_COMPLIANCE,
            rules: {
                mandatory: (v) => !!v || "Required"
            },
            forceUpdateElements: []
        }
    },
    computed: {
        ...mapGetters ( 'types', [ 'complianceTypes', 'instrumentTypes', 'instrumentLocationTypes' ]),
        lidar: {
            get () { return this.modelValue },
            set (value) {this.$emit('update:modelValue', value)}
        },
        lidarComplianceColor () {
            if ( !this.lidar.feasibility_compliance ) {
                return 'primary'
            }

            switch ( this.lidar.feasibility_compliance.code ) {
                case 'yes':
                    return 'success';
                case 'partially':
                    return 'warning';
                case 'no':
                default:
                    return 'danger';
            }
        }
    },
    watch: {
        lidar() {
            this.$forceUpdate();
        }
    },
    methods: {
        ...mapActions ( 'types', [ 'loadComplianceTypes', 'loadInstrumentTypes', 'loadInstrumentLocationTypes' ]),
        validate () {
            return this.$refs.complianceForm.validate()
        },
        loadTypes () {
            if ( !this.complianceTypes.length ) {
                this.loadComplianceTypes()
            }

            if ( !this.instrumentTypes.length ) {
                this.loadInstrumentTypes()
            }

            if ( !this.instrumentLocationTypes.length ) {
                this.loadInstrumentLocationTypes()
            }
        },
        loadSuggestedCompliance () {
            api.lidar.loadSuggestedFeasibilityCompliance(this.lidar._id).then(response => {
                this.suggestedCompliance = response.data.data
            })
        },
        cardStripeColor(code) {
            if ( code === 'yes' ) {
                return 'success'
            }

            if ( code === 'partially' ) {
                return 'warning'
            }

            return 'danger'
        },
        complianceListItemClass(code) {
            if ( code === 'yes' ) {
                return 'compliance_list_item__green'
            }

            if ( code === 'partially' ) {
                return 'compliance_list_item__yellow'
            }

            return 'compliance_list_item__red'
        },
        registerForceUpdateElement (el) {
            this.forceUpdateElements.push(el)
        }
    },
    async mounted () {
        this.loadTypes ()
        this.loadSuggestedCompliance ()
    },
    beforeUpdate () {
        // Force refresh of text input fields
        this.forceUpdateElements.forEach(ref => {
            ref.$forceUpdate()
        })
    }
}
</script>